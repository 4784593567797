import React, { useEffect, useState } from "react";
import BloodTest from "../components/afterBuy/BloodTest";
import HealthyWay from "../components/afterBuy/HealthyWay";
import { useLocation, useNavigate } from "react-router-dom";
import { LogEvent } from "../LogEvent";
import SweetAlert from "react-bootstrap-sweetalert";

const AfterBuy = ({
  product,
  handleClearInterval,
  loading,
  setLoading,
  pin,
  setPinStatus,
  setProductCode,
}) => {
  const [suggesProduct, setSuggesProduct] = useState(null);
  const [toggle, setToggle] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let count = 0;
    if (product) {
      product?.map((item) => {
        if (item.productId === location.state?.data?.productId) {
          setSuggesProduct(item);
          count = 1;
        }
      });
      if (count === 0) {
        product?.map((item) => {
          if (item.productName == "Healthyway Essential") {
            setSuggesProduct(item);
          }
        });
      }
    } else {
      // setLoading(
      //   "Sorry recommended product are not available at your Pincode. Our team is working hard to bring services to your location "
      // );
      navigate("/");
    }
  }, [product]);
  useEffect(() => {
    if (suggesProduct) {
      setToggle(true);
    }
  }, [suggesProduct]);

  const buyNow = (item) => {
    setProductCode(item.productId);
    if (!pin) {
      setPinStatus(true);
    } else {
      LogEvent("Quiz-Buy-Now", "quiz-buy-now-btn");
      handleClearInterval();
      navigate("/login", { state: { product: item } });
    }
  };

  return (
    <div className="healthwayPageWrapper">
      {/* <!-- HEALTHWAY SECTION STARTS --> */}
      <HealthyWay product={suggesProduct} buyNow={buyNow} />

      {/* <!-- BLOOD-TEST SECTION STARTS --> */}
      {location?.state?.data?.why && location?.state?.data?.why?.length && (
        <BloodTest data={location.state.data} />
      )}
      {toggle && (
        <SweetAlert
          success
          customButtons={
            <div className="text-center final-pay-btns">
              <a rel="nofollow noopener noreferrer">
                <button
                  onClick={() => setToggle(false)}
                  type="button"
                  className="red-btn"
                >
                  Ok
                </button>
              </a>
            </div>
          }
        >
          <span>
            <small style={{ fontSize: "16px", fontWeight: "normal" }}>
              Thanks for your inputs , We have a recommendation(s) for you
            </small>
          </span>
        </SweetAlert>
      )}
    </div>
  );
};

export default AfterBuy;
