import { toast } from "react-toastify";

const host = process.env.REACT_APP_SERVER_URL;
const getCallBackHost = process.env.REACT_APP_SERVER_URL_FOR_GET_CALL_BACK;
// const hostInvoice = process.env.REACT_APP_SERVER_URL_FOR_INVOICE;
const hostPayment = process.env.REACT_APP_SERVER_URL_FOR_PAYMENT;

const claimUrl = process.env.REACT_APP_CLAIM_URL;

const reseller_client_id =
  process.env[`REACT_APP_RESELLER_CLIENT_ID_${process.env.REACT_APP_MODE}`];
const reseller_client_secret =
  process.env[`REACT_APP_RESELLER_CLIENT_SECRET_${process.env.REACT_APP_MODE}`];

const clientSecretKey =
  process.env[`REACT_APP_CLIENT_SECRET_KEY_${process.env.REACT_APP_MODE}`];
const clientSecretId =
  process.env[`REACT_APP_CLIENT_SECRET_ID_${process.env.REACT_APP_MODE}`];

// geocode api url
const geoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?new_forward_geocoder=true`;
// geocode api key
const geoCodeKey = process.env.REACT_APP_GEOCODE_KEY;

const Fetch = async (url, data) => {
  const response = await fetch(url, data);
  if (response.status == 401) {
    toast.error("session expired");
    localStorage.removeItem("authToken");
    localStorage.removeItem("buyNowSession");
    window.location.replace("/");
  } else {
    return response;
  }
};

export const headers = () => {
  const auth_token = localStorage.getItem("authToken");
  let header = {
    "Content-Type": "application/json",
    clientSecretKey: clientSecretKey,
    clientSecretId: clientSecretId,
  };
  if (auth_token) {
    header["Auth-Token"] = auth_token;
  }
  return {
    headers: header,
  };
};

export const resellerHeader = () => {
  const auth_token = localStorage.getItem("authToken");
  let header = {
    "Content-Type": "application/json",
    "reseller-client-id": reseller_client_id,
    "reseller-client-secret": reseller_client_secret,
  };
  if (auth_token) {
    header["Auth-Token"] = auth_token;
  }
  return {
    headers: header,
  };
};

export const getOTP = async (data) => {
  const response = await Fetch(`${host}/sms/sendOtp`, {
    method: "POST",
    ...resellerHeader(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  if (json.status.localeCompare("OK") === 0) {
    return json;
  } else {
    return false;
  }
};

export const verifyOTP = async (mobileNumber, otp) => {
  const response = await Fetch(`${host}/sms/verifyOtp`, {
    method: "POST",
    ...resellerHeader(),
    body: JSON.stringify({ mobileNumber: mobileNumber, otp: otp }),
  });
  const json = await response.json();
  if (json?.responseObject) {
    localStorage.setItem("authToken", json?.responseObject);
  }
  if (json.status.localeCompare("OK") === 0) return true;
  return false;
};

export const checkout = async (data) => {
  try {
    const response = await Fetch(`${host}/open/checkOut`, {
      method: "POST",
      ...headers(),
      body: JSON.stringify(data),
    });
    const json = await response.json();
    if (
      json.status.localeCompare("OK") === 0 &&
      json.message.localeCompare("Success") === 0 &&
      json.code === 200
    )
      return { success: true, response: json.responseObject };
    else {
      toast.dismiss();
      toast.error(json.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
      return { success: false, response: {} };
    }
  } catch (err) {
    toast.dismiss();
    toast.error("Internal server error", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
    });
    return err;
  }
};
// coupon code validation api
export const validateCoupon = async (productId, mobileNo, couponCode) => {
  const response = await Fetch(`${host}/open/validateCouponCode`, {
    method: "POST",
    ...headers(),
    body: JSON.stringify({
      productId: productId,
      mobileNo: mobileNo,
      couponCode: couponCode,
    }),
  }).catch((err) => {
    console.log(err, "rrrrr");
  });
  const json = await response.json();
  if (json.code === 200) {
    return { success: true, response: json.responseObject };
  } else {
    toast.dismiss();
    toast.error(json.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
    });
    return { success: false, response: {} };
  }
};

export const validateReferral = async (productId, mobileNo, referralCode) => {
  const response = await Fetch(`${host}/open/ValidateReferralCode`, {
    method: "POST",
    ...headers(),
    body: JSON.stringify({
      productId: productId,
      mobileNo: mobileNo,
      referralCode: referralCode,
    }),
  });
  const json = await response.json();
  if (
    json.status.localeCompare("OK") === 0 &&
    json.message.localeCompare("Success") === 0 &&
    json.code === 200
  ) {
    return { success: true, response: json.responseObject };
  } else {
    toast.dismiss();
    toast.error(json.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
    });
    return { success: false, response: {} };
  }
};

export const getProductList = async (pincode) => {
  if (pincode == null) {
    pincode = "";
  }
  const response = await Fetch(
    `${host}/open/getProductByReseller?pincode=${pincode}`,
    {
      method: "GET",
      ...headers(),
    }
  );
  const json = await response.json();
  if (
    json.status.localeCompare("OK") === 0 &&
    json.message.localeCompare("Success") === 0 &&
    json.code === 200
  )
    return { success: true, products: json.responseObject };
  else return { success: false, products: [], json: json };
};

export const getPaymentConfirmation = async (applicationId) => {
  const response = await Fetch(
    `${hostPayment}/payment/check/${applicationId}`,
    {
      method: "GET",
      ...headers(),
    }
  );
  const json = await response.json();

  if (json.data.status) {
    return { success: true, json: json };
  } else {
    return { success: false, json: json };
  }
};

export const getCallBack = async (name, mobile, email, type, pincode) => {
  const response = await Fetch(getCallBackHost, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, email, mobile, type, pincode }),
  });
  const json = await response.json();

  if (json.status === true && json.message.localeCompare("success") === 0) {
    return {
      success: true,
      message: "Data submitted successfully",
    };
  } else {
    return {
      success: false,
      message: "Data could not be submitted, Please try again later",
    };
  }
};

export const getPin = async (pin) => {
  const response = await Fetch(`${hostPayment}/check-pin/${pin}`, {
    method: "GET",
    ...headers(),
  });
  const json = await response.json();
  if (json.code === 200) return json;
  else return { success: false };
};

export const checkPinValidation = async (pin) => {
  const response = await Fetch(`${host}/open/validatePinCode/${pin}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  if (json.code === 200) {
    return json;
  } else {
    // toast.error(
    //   "Our Services are not available at this Pincode. Our team is working hard to bring services to your location "
    // );
    return { success: false };
  }
};

// black out days

export const getBlackOutDays = async () => {
  const response = await Fetch(`${host}/NonServiceableDay`, {
    method: "GET",
    ...headers(),
  });
  const json = await response.json();
  if (json.code === 200) return json;
};
export const getLatLong = async (address) => {
  const response = await Fetch(
    `${geoCodeUrl}&address=${address}&key=${geoCodeKey}`,
    {
      method: "GET",
    }
  );
  const json = await response.json();
  if (json.status === "OK") return json?.results[0];
  else return { success: false };
};

export const quizService = async (data) => {
  const response = await Fetch(`${claimUrl}/quiz`, {
    method: "POST",
    ...headers(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  if (
    json.status.localeCompare("OK") === 0 &&
    json.message.localeCompare("Success") === 0 &&
    json.code === 200
  ) {
    return { success: true, response: json.data };
  } else {
    toast.dismiss();
    toast.error(json.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
    });
    return { success: false, response: {} };
  }
};

export const basicDetailsMilestone = async (data) => {
  const response = await Fetch(`${host}/open/milestone/basic_details`, {
    method: "PUT",
    ...headers(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  if (
    json.status.localeCompare("OK") === 0 &&
    json.message.localeCompare("Success") === 0 &&
    json.code === 200
  ) {
    return { success: true, response: json.data };
  } else {
    return { success: false, response: {} };
  }
};

export const collectionFormMilestone = async (data) => {
  const response = await Fetch(`${host}/open/milestone/collection_form`, {
    method: "PUT",
    ...headers(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  if (
    json.status.localeCompare("OK") === 0 &&
    json.message.localeCompare("Success") === 0 &&
    json.code === 200
  ) {
    return { success: true, response: json.data };
  } else {
    return { success: false, response: {} };
  }
};

export const checkoutFormMilestone = async (data) => {
  const response = await Fetch(`${host}/open/milestone/checkout_form`, {
    method: "PUT",
    ...headers(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  if (
    json.status.localeCompare("OK") === 0 &&
    json.message.localeCompare("Success") === 0 &&
    json.code === 200
  ) {
    return { success: true, response: json.data };
  } else {
    return { success: false, response: {} };
  }
};

export const payMilestone = async (data) => {
  const response = await Fetch(
    `${host}/open/milestone/payment_update/${data}`,
    {
      method: "GET",
      ...headers(),
    }
  );
  const json = await response.json();
  if (
    json.status.localeCompare("OK") === 0 &&
    json.message.localeCompare("Success") === 0 &&
    json.code === 200
  ) {
    return { success: true, response: json.data };
  } else {
    return { success: false, response: {} };
  }
};

export const captureVistorLead = async (data) => {
  const response = await Fetch(`${host}/open/capture_visitor_lead`, {
    method: "POST",
    ...headers(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  if (
    json.status.localeCompare("OK") === 0 &&
    json.message.localeCompare("Success") === 0 &&
    json.code === 200
  ) {
    return { success: true, response: json.data };
  } else {
    return { success: false, response: {} };
  }
};
