import React from 'react'

const WhyChooseSection = () => {
    return (
        <section id="whyChooseSection">
            <div className="whyChooseSectionWrapper">
                <div className="container">
                    <div className="topHeading">
                        <h3>Why choose <span className="fw-7">Healthyway</span> </h3>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="whyChooseCard marginCard">
                                <img src={require("../../assets/images/why-choose1.png")} alt="" />
                                <h4>Fast and accurate tests reports</h4>
                                <p>We work with the finest labs to deliver highest quality report</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="whyChooseCard">
                                <img src={require("../../assets/images/why-choose2.png")} alt="" />
                                <h4>Curated plans with chosen parameters</h4>
                                <p>Our tests are specially parameters based on in-depth research</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="whyChooseCard marginCard">
                                <img src={require("../../assets/images/why-choose3.png")} alt="" />
                                <h4>Expert team of doctors and nutritionists</h4>
                                <p>Our experts have been trained at India's best hospitals</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="whyChooseCard">
                                <img src={require("../../assets/images/why-choose4.png")} alt="" />
                                <h4>Personalized lifestyle and health coaching</h4>
                                <p> We help you with a path forward based on your test results</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseSection