import React from "react";
import { useForm, Controller } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";
import { captureVistorLead } from "../functions";
import InputMask from "react-input-mask";
import useMobileDetect from "../useMobileDetect";

const LeadCaptureProductNotAvailable = ({ title, setLeadForm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });

  const onSubmit = async (data) => {
    const pin = localStorage.getItem("pincode");
    const coupon = localStorage.getItem("coupon_code");
    const newData = {
      ...data,
      name: "as per shailendra no need of name",
      couponCode: coupon,
      pincode: pin,
    };
    localStorage.setItem("leadCaptured", true);
    captureVistorLead(newData).then((res) => onCancel());
  };

  const onCancel = () => {
    setLeadForm(false);
  };

  const { isMobile } = useMobileDetect();

  return (
    <SweetAlert
      style={{
        backgroundColor: "#FFDEDD",
        width: isMobile() ? "80%" : "30%",
      }}
      showConfirm={false}
    >
      <form className="row" action="" style={{ justifyContent: "center" }}>
        <span
          style={{
            width: "100%",
            textAlign: "end",
            fontSize: 16,
            fontWeight: 400,
            color: "#db2228",
            marginBottom: 10,
          }}
        >
          <span onClick={onCancel} style={{ cursor: "pointer" }}>
            Close X
          </span>
        </span>
        <br />
        <label style={{ fontSize: 16 }}>
          {title == "get a call back" ? (
            <div className="callBackHeader">
              <h2>
                Get a <span className="fw-7"> call back </span>
              </h2>
              <p>Feel free to get in touch with us</p>
            </div>
          ) : title == "want to know more about our services ?" ? (
            <p style={{ fontSize: 22, marginBottom: 30 }}>
              Want to know more about our services ?
            </p>
          ) : (
            <div style={{ marginBottom: 30 }}>
              <p style={{ fontSize: 22 }}>
                Our services are not available at this pincode
              </p>
              <span>
                Kindly share the details below. Our team will get in touch with
                you.
              </span>
            </div>
          )}
        </label>
        <br />
        <br />
        {/* <div
          style={{ marginBottom: "16px", textAlign: "start" }}
          className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
        >
          <label htmlFor="inputName" className="form-label">
            Name <span className="text-red">*</span>
          </label>
          <input
            {...register("name", {
              required: "please enter your name",
            })}
            value="as per shailendra no need of name"
            type="text"
            className="form-control"
            id="inputName"
            placeholder="Enter your name"
            required
          />
        </div> */}

        <div
          style={{ marginBottom: "16px", textAlign: "center" }}
          className="col-md-8 form-field"
        >
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <>
                {/* <label htmlFor="mobile" className="form-label">
                  Mobile Number <span className="text-red">*</span>
                </label> */}
                <InputMask
                  {...register("phoneNumber", {
                    required: "Please Enter mobile number",
                    pattern: {
                      value: /^(([^0-5])[0-9]{9})$/,
                      message: "please enter valid mobile number",
                    },
                    minLength: {
                      value: 10,
                      message: "please enter valid mobile number",
                    },
                  })}
                  onChange={(e) => {
                    const value = e.target.value;
                    var numberRegex = /^\d+$/;
                    if (
                      (numberRegex.test(value) && value.length <= 10) ||
                      value.length === 0
                    ) {
                      setValue("phoneNumber", value);
                    }
                  }}
                  onPaste={(e) => {
                    e.clipboardData.items[0].getAsString((value) => {
                      var numberRegex = /^\d+$/;
                      if (
                        (numberRegex.test(value) && value.length <= 10) ||
                        value.length === 0
                      ) {
                        setValue("phoneNumber", value);
                      } else if (value.length > 10) {
                        setValue(
                          "phoneNumber",
                          value.replace(/[^0-9]+/g, "").slice(-10)
                        );
                      }
                    });
                  }}
                  value={getValues("phoneNumber")}
                  className="form-control"
                  maskChar="9999999999"
                  placeholder="Enter your mobile number"
                />
              </>
            )}
          />
          {errors.phoneNumber && (
            <div style={{ textAlign: "center" }} className="text-red fs-12 m-1">
              {errors.phoneNumber.message}
            </div>
          )}
        </div>

        {/* <div
          style={{ marginBottom: "16px", textAlign: "start" }}
          className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 "
        >
          <label htmlFor="inputemail" className="form-label">
            Email <span className="text-red">*</span>
          </label>
          <input
            {...register("email")}
            type="email"
            className="form-control"
            id="inputemail"
            placeholder="Enter your email"
          />
         
        </div> */}

        {/* <div
          style={{ marginBottom: "16px", textAlign: "start" }}
          className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
        >
          <label htmlFor="inputCode" className="form-label">
            Remark
          </label>
          <input
            {...register("remarks")}
            type="text"
            className="form-control"
            id="inputCode"
            placeholder="Enter remarks"
          />
        </div> */}
        <br />
        <div className="text-center final-pay-btns mt-4">
          <a rel="nofollow noopener noreferrer">
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="red-btn"
            >
              Submit
            </button>
          </a>
        </div>
      </form>
    </SweetAlert>
  );
};

export default LeadCaptureProductNotAvailable;
