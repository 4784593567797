import React from "react";

const DiscountedAmmount = ({ price, discountedPrice }) => {
  return (
    <p>
      <del style={{ marginRight: 5 }} className="fs-18 fw-5">{`₹${
        price ? price : 9999
      } `}</del>
      <span style={{ color: "red", fontSize: 18 }}>65% off</span>
      <p
        style={{ fontWeight: 700, fontSize: "34px" }}
      >{`₹${discountedPrice}`}</p>
    </p>
    // <div className="discountedAmountComponent">
    //   <div className="discount-upto col-4">
    //     <span className="fs-10 ">UPTO</span>

    //     <p className="fs-12 fw-6 ">65% OFF</p>
    //   </div>
    //   <div className="discounted-price col-5">
    //     <span className="fs-10">EXCLUSIVE OFFER</span>

    //     <p style={{ marginTop: -5, color: "white" }}>
    //       <del style={{ marginRight: 10 }} className="fs-12 fw-5">{`₹${
    //         price ? price : 9999
    //       } `}</del>

    //       <span className="fs-16 fw-5 " style={{ color: "white" }}>
    //         {`₹${discountedPrice}`}
    //       </span>
    //     </p>
    //   </div>
    // </div>
  );
};
export default DiscountedAmmount;
