import React, { useEffect, useRef, useState } from "react";

const WorkSection = () => {
  const [step, setStep] = useState(1);

  const firstMount = useRef(true);

  const addScrollBehavior = () => {
    //3384 - 3500
    const workId = document.getElementById("workSection");
    window.addEventListener("scroll", function() {
      if (window.scrollY < 3970) {
        setStep(1);
      } else if (window.scrollY >= 3970 && window.scrollY < 4650) {
        setStep(2);
      } else if (window.scrollY >= 4650 && window.scrollY < 5470) {
        setStep(3);
      } else {
        setStep(4);
      }
    });
    function checkMediaQuery() {
      // If the inner width of the window is greater then 768px
      if (window.innerWidth < 991) {
        // Then log this message to the console
        if (window.scrollY < 3150) {
          setStep(1);
        } else if (window.scrollY >= 3150 && window.scrollY < 3720) {
          setStep(2);
        } else if (window.scrollY >= 3720 && window.scrollY < 4340) {
          setStep(3);
        } else {
          setStep(4);
        }
      } else if (window.innerWidth >= 992 && window.innerWidth <= 1199) {
        // Then log this message to the console
        if (window.scrollY < 3630) {
          setStep(1);
        } else if (window.scrollY >= 3630 && window.scrollY < 4330) {
          setStep(2);
        } else if (window.scrollY >= 3745 && window.scrollY < 5010) {
          setStep(3);
        } else {
          setStep(4);
        }
      }
    }
    // Add a listener for when the window resizes
    window.addEventListener("scroll", checkMediaQuery);
  };

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      addScrollBehavior();
    }
  }, []);

  return (
    <>
      <section id="workSection" className="d-none d-md-block">
        <div className="workSectionWrapper ">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="leftContent">
                  <div className="row align-items-center">
                    <div className="workHeader">
                      <h2>
                        How <span className="fw-7"> Healthyway works </span>
                      </h2>
                      <p>
                        The best tests are customized based on your health
                        goals. Booking <br /> health tests in just a few clicks.
                      </p>
                    </div>
                    <div className="col-md-9 col-lg-8 col-xl-6 col-xxl-6">
                      <div className="stepWrapper">
                        <ul>
                          <li className={step === 1 ? "active" : ""}>
                            Step 1 {"  "} Choose a plan
                          </li>
                          <li
                            className={step === 2 ? "active" : ""}
                            // onClick={() => setStep(2)}
                          >
                            Step 2 Give sample at home
                          </li>
                          <li
                            className={step === 3 ? "active" : ""}
                            // onClick={() => setStep(3)}
                          >
                            Step 3 Check reports on app
                          </li>
                          <li
                            className={step === 4 ? "active" : ""}
                            // onClick={() => setStep(4)}
                          >
                            Step 4 Speak to our coaches
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-4 col-xl-6 col-xxl-6">
                      <div className="para fade-in-image">
                        {step === 1 && (
                          <p>
                            Choose your goal <br /> Get plan recommendation
                            <br /> Buy plan - discount auto applied
                          </p>
                        )}
                        {step === 2 && (
                          <p>Trained lab agent comes to your home</p>
                        )}
                        {step === 3 && <p>Reports on the aiqahealth app</p>}
                        {step === 4 && (
                          <p>
                            Post-test report, speak to our doctor and
                            nutritionist
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="rightContent step1Image fade-in-image">
                  <img
                    src={require("../../assets/images/work-step1.png")}
                    alt=""
                  />
                  <img
                    src={require("../../assets/images/work-step2.png")}
                    alt=""
                  />
                  <img
                    src={require("../../assets/images/work-step3.png")}
                    alt=""
                  />
                  <img
                    src={require("../../assets/images/work-step4.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {step === 2 && <div className="workSectionWrapper">
                    <div className="container">
                        <div className="workHeader">
                            <h2>How <span className="fw-7"> Healthyway works </span></h2>
                            <p>The best tests are customized based on your health goals. Booking <br /> health tests in just a few clicks.</p>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <div className="leftContent">
                                    <div className="row align-items-center">
                                        <div className="col-md-9 col-lg-8 col-xl-6 col-xxl-6">
                                            <div className="stepWrapper">
                                                <ul>
                                                    <li onClick={() => setStep(1)}>Step 1 Choose a plan</li>
                                                    <li className="active">Step 2 Give sample at home</li>
                                                    <li onClick={() => setStep(3)}>Step 3 Check reports on app</li>
                                                    <li onClick={() => setStep(4)}>Step 4 Speak to our doctor</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-4 col-xl-6 col-xxl-6">
                                            <div className="para fade-in-image">
                                                <p>Trained lab agent comes to your home</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="rightContent fade-in-image">
                                    <img src={require("../../assets/images/work-step2.png")} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {step === 3 && <div className="workSectionWrapper">
                    <div className="container">
                        <div className="workHeader">
                            <h2>How <span className="fw-7"> Healthyway works </span></h2>
                            <p>The best tests are customized based on your health goals. Booking <br /> health tests in just a few clicks.</p>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <div className="leftContent">
                                    <div className="row align-items-center">
                                        <div className="col-md-9 col-lg-8 col-xl-6 col-xxl-6">
                                            <div className="stepWrapper">
                                                <ul>
                                                    <li onClick={() => setStep(1)}>Step 1 Choose a plan</li>
                                                    <li onClick={() => setStep(2)}>Step 2 Give sample at home</li>
                                                    <li className="active">Step 3 Check reports on app</li>
                                                    <li onClick={() => setStep(4)}>Step 4 Speak to our doctor</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-4 col-xl-6 col-xxl-6">
                                            <div className="para fade-in-image">
                                                <p>Reports on the aiqahealth app</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="rightContent fade-in-image">
                                    <img src={require("../../assets/images/work-step3.png")} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {step === 4 && <div className="workSectionWrapper">
                    <div className="container">
                        <div className="workHeader">
                            <h2>How <span className="fw-7"> Healthyway works </span></h2>
                            <p>The best tests are customized based on your health goals. Booking <br /> health tests in just a few clicks.</p>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <div className="leftContent">
                                    <div className="row align-items-center">
                                        <div className="col-md-9 col-lg-8 col-xl-6 col-xxl-6">
                                            <div className="stepWrapper">
                                                <ul>
                                                    <li onClick={() => setStep(1)}>Step 1 Choose a plan</li>
                                                    <li onClick={() => setStep(2)}>Step 2 Give sample at home</li>
                                                    <li onClick={() => setStep(3)}>Step 3 Check reports on app</li>
                                                    <li className="active">Step 4 Speak to our doctor</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-4 col-xl-6 col-xxl-6">
                                            <div className="para fade-in-image">
                                                <p>Post test call, general call, doctor on phone</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="rightContent fade-in-image">
                                    <img src={require("../../assets/images/work-step4.png")} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} */}
      </section>

      {/* <!-- WORK SECTION FOR MOBILE STARTS --> */}
      <section id="workSectionMobile" className=" d-block d-md-none">
        <div className="workSectionWrapperMobile">
          <div className="container">
            <div className="workHeader">
              <h2>
                How <span className="fw-7"> Healthyway works </span>
              </h2>
              <p>
                The best tests are customized based on your health goals.
                Booking <br /> health tests in just a few clicks.
              </p>
            </div>
            <div className="workContent">
              <div className="workContentHeading">
                <h2>
                  Step 1{" "}
                  <span className="fw-7">
                    <br />
                    Choose a plan
                  </span>
                </h2>
                <p>
                  {" "}
                  Choose your goal <br /> Get plan recommendation
                  <br /> Buy Plan - discount auto applied
                </p>
              </div>
              <div className="imageWrapper">
                <img
                  src={require("../../assets/images/work-step1.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="workContent">
              <div className="workContentHeading">
                <h2>
                  Step 2{" "}
                  <span className="fw-7">
                    <br />
                    Give sample at home
                  </span>
                </h2>
                <p>Trained lab agent comes to your home</p>
              </div>
              <div className="imageWrapper">
                <img
                  src={require("../../assets/images/work-step2.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="workContent">
              <div className="workContentHeading">
                <h2>
                  Step 3{" "}
                  <span className="fw-7">
                    <br /> Check reports on app
                  </span>
                </h2>
                <p>Reports on the aiqahealth app</p>
              </div>
              <div className="imageWrapper">
                <img
                  src={require("../../assets/images/work-step3.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="workContent">
              <div className="workContentHeading">
                <h2>
                  Step 4{" "}
                  <span className="fw-7">
                    <br />
                    Speak to our coaches
                  </span>
                </h2>
                <p>Post-test report, speak to our doctor and nutritionist</p>
              </div>
              <div className="imageWrapper">
                <img
                  src={require("../../assets/images/work-step4.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkSection;
