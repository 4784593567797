import React from "react";

const QualitySection = () => {
  return (
    <section id="qualitySection">
      <div className="qualitySectionWrapper">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="leftContent">
                <h3>
                  Delivering highest <br />{" "}
                  <span className="fw-7">quality care</span>
                </h3>
                <div className="boxWrapper">
                  {/* <div className="box">
                    <h4>2.5Cr</h4>
                    <p>Doctor consults</p>
                  </div> */}

                  <div className="box">
                    <h4>500+ </h4>
                    <p>Doctors</p>
                  </div>

                  <div className="box">
                    <h4>40 Lakh+</h4>
                    <p>customers</p>
                  </div>
                  <div className="box">
                    <h4>4.83/5 </h4>
                    <p>customer satisfaction </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rightContent">
                <img
                  src={require("../../assets/images/quality-care-right-img.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QualitySection;
