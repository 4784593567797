import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import InputMask from "react-input-mask";
import "../assets/css/main.css";

const ProductByPinCode = ({ onConfirm, onCancel }) => {
  const [pin, setPin] = useState(null);
  const [error, setError] = useState("");

  const onPinChange = (e) => {
    let data = e.target.value;
    if (data.length == 0 || /^\d+$/.test(data)) {
      if (data.length <= 6) {
        setPin(e.target.value);
        setError("");
      }
    } else {
      setError("please enter valid pincode");
    }
  };

  const onClickHandler = () => {
    if (pin.length === 6) {
      onConfirm(pin);
    } else {
      setError("pincode is required");
    }
  };

  return (
    <SweetAlert
      customButtons
      onCancel={() => onCancel()}
      dependencies={[pin]}
      style={{ backgroundColor: "#FFDEDD" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <span
          style={{
            width: "100%",
            textAlign: "end",

            fontWeight: 400,
          }}
        >
          <span
            onClick={onCancel}
            style={{ cursor: "pointer", color: "#db2228" }}
          >
            close X
          </span>
        </span>
        <br />
        <h3>Check product availability at your pin code</h3>
        <br />
        <div
          className="pincodeInput"
          onKeyDown={(e) => {
            if (e.key == "Enter" && pin > 5) onClickHandler();
          }}
        >
          <img src={require("../assets/images/location-icon.png")} alt="" />
          <InputMask
            mask="999999"
            maskChar=""
            placeholder="Enter your area pin code"
            onChange={onPinChange}
          />

          <button onClick={onClickHandler}>
            <img src={require("../assets/images/arrow-icon.png")} alt="" />
          </button>
        </div>
        {error && (
          <span style={{ color: "#db2228", fontSize: 14 }}>{error}</span>
        )}
      </div>
    </SweetAlert>
  );
};

export default ProductByPinCode;
