import React from "react";
import { useNavigate } from "react-router-dom";
import { advanceBenefits, basicBenefits, essentialBenefits } from "../utils";

const Card = (props) => {
  // const color = props.color;

  const product = props.product;
  const purpose = props.purpose;
  const setModalProduct = props.setModalProduct;
  const color = product.index % 2 === 0 ? 0 : 1;
  let benefits = product.productBenefitDtoList || [];
  benefits = benefits.slice(0, Math.min(5, benefits.length));
  // console.log(benefits);
  const navigate = useNavigate();
  const loginPage = () => {
    navigate("/login", { state: { product: product } });
  };
  const asignModalProduct = () => {
    setModalProduct(product);
  };

  return (
    <div className={color === 0 ? "plan-box" : "plan-box plan-box-2"}>
      <h2 className={color === 0 ? "" : "text-white"}>{product.productName}</h2>
      {/* <p className={color === 0 ? "" : "text-white text-center"}>
					<span className="fw-6">₹ {product.basePrice} </span><span>Yearly*</span>
				</p> */}
      <hr
        style={{ margin: "1.5rem 0" }}
        className={color === 0 ? "text-red" : "text-white"}
      />

      <ul>
        {/* <li>
            <img
              src={require(color === 0
                ? "../assets/images/check-circle.png"
                : "../assets/images/check-circle-white.png")}
              alt=""
            />
            16 Specialities Covered
            {`Cover Type: ${product.coverType}`}
          </li> */}
        {product.productName === "Healthyway Basic"
          ? basicBenefits.map((item, index) => {
              return (
                <li key={index}>
                  <img
                    src={require(color === 0
                      ? "../assets/images/check-circle.png"
                      : "../assets/images/check-circle-white.png")}
                    alt=""
                  />
                  {item}
                </li>
              );
            })
          : product.productName === "Healthyway Essential"
          ? essentialBenefits.map((item, index) => {
              return (
                <li key={index}>
                  <img
                    src={require(color === 0
                      ? "../assets/images/check-circle.png"
                      : "../assets/images/check-circle-white.png")}
                    alt=""
                  />
                  {item}
                </li>
              );
            })
          : advanceBenefits.map((item, index) => {
              return (
                <li key={index}>
                  <img
                    src={require(color === 0
                      ? "../assets/images/check-circle.png"
                      : "../assets/images/check-circle-white.png")}
                    alt=""
                  />
                  {item}
                </li>
              );
            })}
        {/* {benefits.map((benefit, index) => {
            return (
              <li key={index}>
                <img
                  src={require(color === 0
                    ? "../assets/images/check-circle.png"
                    : "../assets/images/check-circle-white.png")}
                  alt=""
                />
                <span className="text-start">{benefit.descriptionText}</span>
              </li>
            );
          })} */}
      </ul>

      {/* {benefits.length === 0 && (
        <ul>
          <li>
            <img
              src={require(color === 0
                ? "../assets/images/check-circle.png"
                : "../assets/images/check-circle-white.png")}
              alt=""
            />
            {`Cover Type: ${product.coverType}`}
          </li>
        </ul>
      )} */}
      <div className="text-center">
        <div className="" data-bs-toggle="modal" data-bs-target="#plan-modal">
          {/* <p onClick={asignModalProduct} className={color === 0 ? "" : "text-white"} style={{ textDecoration: 'underLine', cursor: 'pointer' }} >{color === 0 ? "View all features" : (props.boxType === 3 && props.makePaymentBtn === null) ? "change plan" : "View all features"}</p> */}
        </div>
        {purpose && (
          <button
            onClick={loginPage}
            className={
              color === 0
                ? "red-btn plan-box-btn"
                : props.boxType === 3
                ? "d-none"
                : "white-btn plan-box-btn"
            }
          >
            Buy Now
          </button>
        )}
      </div>
    </div>
  );
};

export default Card;
