import React from "react";

const HeroSection = ({ onCallBackHandle }) => {
  return (
    <section id="heroSection">
      <div className="heroSectionWrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-block d-md-none d-lg-none d-xl-none d-xxl-none">
              <div className="rightContent h-100">
                {/* <img
                  src={require("../../assets/images/hero-img-mobile.png")}
                  alt=""
                /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="leftContent">
                <h1>Health plans for the healthier you</h1>
                <p>
                  At home lab tests with doctor and nutritionist coaching for a
                  comprehensive health assessment
                </p>
                <div className="position-relative">
                  <button className="white-btn" onClick={onCallBackHandle}>
                    Get a call back
                  </button>
                  <div className="bannerArrow">
                    <img
                      src={require("../../assets/images/banner-arrow.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
              <div className="rightContent h-100">
                <img
                  src={require("../../assets/images/homeBannerMan.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
