import React from "react";
import { useNavigate } from "react-router-dom";

const HealthyWaySection = () => {
  const navigate = useNavigate();
  return (
    <section id="healthwaySection">
      <div className="healthwaySectionWrapper">
        <div className="row m-0">
          <div className="col-lg-6 p-0">
            <div className="leftContent">
              <div className="contentWrapper">
                <div className="imgContentWrapper">
                  <img src={require("../../assets/images/headache-boy.png")} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h4>Other</h4>
                  <div className="contentCard">
                    <p>
                      <span>
                        100 <br />
                        Parameters <br />
                        Lab test <br />
                        (₹4000/-)
                      </span>
                      <span className="plus">+</span>
                      <span>
                        Nutrition
                        <br /> Consult <br />
                        (₹8000/-)
                      </span>
                      <span className="plus">+</span>
                      <span>
                        Doctor Consult
                        <br />
                        (₹3000/-)
                      </span>
                    </p>
                  </div>
                  <button style={{ cursor: "default" }} className="red-btn">
                    ₹15000/year
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="rightContent">
              <div className="contentWrapper">
                <h4>aiqahealth</h4>
                <div>
                  <p>
                    <span>
                      94 <br /> Parameters <br />
                      Lab test
                    </span>
                    <span className="plus">+</span>
                    <span>
                      4 Nutrition <br /> Consult{" "}
                    </span>
                    <span className="plus">+</span>
                    <span>
                      Unlimited Doctor <br /> Consult
                    </span>
                  </p>
                </div>
                <br />
                <a
                  href="/#planSection"
                  style={{ cursor: "default" }}
                  className="red-btn"
                >
                  ₹3499/year
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HealthyWaySection;
