import React from 'react'

const NewsSection = () => {
    
    return (
        <section id="newsSection">
            <div className="newsSectionWrapper">
                <div className="container">
                    <div className="newsHeader">
                        <h2>aiqahealth <span className="fw-7"> in News </span></h2>
                        <p>Our mission of making healthcare accessible and affordable across the <br /> country gets noticed by prominent voices.</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-6 col-md-3">
                            <div className="newsLogo">
                                <img src={require("../../assets/images/news-1n.png")} alt="" />
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="newsLogo">
                                <img src={require("../../assets/images/news-2.png")} alt="" />
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="newsLogo">
                                <img src={require("../../assets/images/news-3.png")} alt="" />
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="newsLogo">
                                <img src={require("../../assets/images/news-4.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsSection