const getMobileDetect = (userAgent) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => {
    Boolean(userAgent.match(/iPhone/i));
  };
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));
  const isMobile = () => {
    if (window.innerWidth > 1000) {
      return false;
    } else {
      return true;
    }
    // Boolean(isAndroid() || isIos() || isOpera() || isWindows())
  };
  const isTab = () => {
    if (window.innerWidth < 1000 && window.innerWidth > 600) {
      return true;
    } else {
      return false;
    }
  };
  const isDesktop = () => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
    isTab
  };
};
const useMobileDetect = () => {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return getMobileDetect(userAgent);
};
export default useMobileDetect;
