import React, { useCallback } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";

const mapApiKey = process.env.REACT_APP_GEOCODE_KEY;

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      pinCode: "",
    };

    this.zoom = 10;
    this.googleMapRef = React.createRef();
    this.zoomHandler = this.zoomHandler.bind(this);
  }

  // clear input field error

  inputErrorHandler = () => {
    this.props.clearErrors([
      "pincode",
      "city",
      "state",
      "customerAddress2",
      "customerAddress",
      "landmark",
    ]);
  };

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.setApiKey(mapApiKey);
    Geocode.enableDebug();
    const lat = this.props.latLong && this.props.latLong[0],
      lng = this.props.latLong && this.props.latLong[1];
    this.props.callBack([lat, lng]);
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      (nextProps.latLong && nextProps.latLong[0]) !==
        (this.props?.latLong && this.props?.latLong[0]) ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "locality" === addressArray[i].types[0] &&
        (addressArray[i].types[1] && "political" === addressArray[i].types[1])
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the local area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getSubArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_2" === addressArray[i].types[j] ||
            "landmark" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
 
   */
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getLandmark = (addressArray) => {
    let land = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
          land = addressArray[i].long_name;
          return land;
        }
      }
    }
  };

  getPinCode = (addressArray) => {
    let pin = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          pin = addressArray[i].long_name;
          return pin;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};
  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = async (place) => {
    const address = place.formatted_address;
    this.props.setFormDetails({
      ...this.props.formDetails,
      geoFormattedAddress: address,
    });
    const addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      subArea = this.getSubArea(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng(),
      pinCode = this.getPinCode(addressArray),
      landMark = this.getLandmark(addressArray);

    if (this.props.getValue("pincode") != pinCode) {
      this.props.loading(true);
    } else {
      this.props.callBack([latValue, lngValue]);
      this.props.setValue("pincode", pinCode ? pinCode : "");
      this.props.setValue("city", city ? city : "");
      this.props.setValue("state", state ? state : "");
      this.props.setValue("customerAddress2", area ? area : "");
      this.inputErrorHandler();
    }
  };
  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.props.setFormDetails({
          ...this.props.formDetails,
          geoFormattedAddress: address,
        });
        const addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          pinCode = this.getPinCode(addressArray);

        if (this.props.getValue("pincode") != pinCode) {
          this.props.loading(true);
        } else {
          this.props.callBack([newLat, newLng]);
          this.props.setValue("pincode", pinCode ? pinCode : "");
          this.props.setValue("city", city ? city : "");
          this.props.setValue("state", state ? state : "");
          this.props.setValue("customerAddress2", area);
          this.inputErrorHandler();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  zoomHandler = () => {
    const value = this.googleMapRef.current.getZoom();
    this.zoom = value;
  };

  render() {
    return (
      <div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-12 d-flex">
          <Autocomplete
            ref={this.props.mapAutoRef}
            apiKey={mapApiKey}
            style={{
              position: "relative",
              // width: "90%",
              marginBottom: "5px",
            }}
            placeholder="Search your location"
            key={"asdafadfaf"}
            onPlaceSelected={this.onPlaceSelected}
            options={{
              fields: ["ALL"],
              types: ["establishment"],
              componentRestrictions: { country: "in" },
              strictBounds: true,
              bounds: this.props.bounds,
            }}
            types={["(regions)"]}
            className="form-control "
          />
        </div>
        {this.props?.latLong?.[0] && (
          <AsyncMap
            key={"sfa"}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: "300px" }} />}
            mapElement={<div style={{ height: `100%` }} />}
            google={this.props.google}
            zoom={this.zoom}
            lat={this.props.latLong?.[0]}
            lng={this.props.latLong?.[1]}
            onMarkerDragEnd={this.onMarkerDragEnd}
            googleMapRef={this.googleMapRef}
            zoomHandler={this.zoomHandler}
            bound={this.props.bounds}
          />
        )}
      </div>
    );
  }
}
const AsyncMap = withScriptjs(
  withGoogleMap(
    ({
      google,
      zoom,
      lat,
      lng,
      onMarkerDragEnd,
      googleMapRef,
      zoomHandler,
      bound,
    }) => (
      <GoogleMap
        google={google}
        defaultZoom={zoom}
        center={{
          lat,
          lng,
        }}
        onClick={onMarkerDragEnd}
        ref={googleMapRef}
        onZoomChanged={zoomHandler}
        defaultOptions={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        options={{
          restriction: {
            latLngBounds: bound,
            strictBounds: false,
          },
          disableDefaultUI: true,
          zoomControl: true,
          clickableIcons: false,
        }}
      >
        <Marker
          google={google}
          name={"location"}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={{
            lat,
            lng,
          }}
        />
      </GoogleMap>
    )
  )
);

export default Map;
