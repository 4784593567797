import React, { useState } from "react";
import { toast } from "react-toastify";
import { getCallBack } from "../../functions";
import { LogEvent } from "../../LogEvent";
import { isValidEmail } from "../../utils";

const CallBackSection = ({ forwardeCallBackRef }) => {
  const [user, setUser] = useState({
    name: "",
    // pincode: "",
    // email: "",
    mobileNumber: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    LogEvent("Call-Back", "call-back-btn");
    if (!user.name) {
      return;
    }
    if (user.mobileNumber.length < 10) {
      return;
    }
    // if (!isValidEmail(user.email)) {
    //   toast.dismiss();
    //   toast.error("Please enter a valid email", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     autoClose: 5000,
    //   });
    //   return;
    // }
    const response = await getCallBack(
      user.name,
      // user.email,
      user.mobileNumber
      // "individual"
      // user.pincode
    );
    toast.dismiss();
    if (response.success) {
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
      setUser({
        name: "",
        // pincode: "",
        // email: "",
        mobileNumber: "",
      });
    } else
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
  };

  const onUserChnage = (e) => {
    if (e.target.name.localeCompare("name") === 0) {
      setUser({ ...user, name: e.target.value });
    }
    // else if (
    //   e.target.name.localeCompare("pincode") === 0 &&
    //   !isNaN(e.target.value) &&
    //   e.target.value.length < 7
    // ) {
    //   setUser({ ...user, pincode: e.target.value });
    // }
    // else if (e.target.name.localeCompare("email") === 0) {
    //   setUser({ ...user, email: e.target.value });
    // }
    else if (
      e.target.name.localeCompare("mobileNumber") === 0 &&
      !isNaN(e.target.value) &&
      e.target.value.length < 11
    ) {
      setUser({ ...user, mobileNumber: e.target.value });
    }
  };

  return (
    <section ref={forwardeCallBackRef} id="callBackSection">
      <div className="callBackSectionWrapper">
        <div className="container">
          <div className="callBackWrapper">
            <div className="callBackHeader">
              <h2>
                Get a <span className="fw-7"> call back </span>
              </h2>
              <p>Feel free to get in touch with us</p>
            </div>
            <div className="formWrapper">
              <form onSubmit={handleSubmit} className="row g-3">
                <div className="col-md-6 fieldWrapper">
                  <label htmlFor="inputName" className="form-label">
                    Name
                  </label>
                  <input
                    onChange={onUserChnage}
                    value={user.name}
                    name="name"
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Enter your name"
                    required
                  />
                </div>
                {/* <div className="col-md-6 fieldWrapper">
                  <label htmlFor="inputEmail4" className="form-label">
                    Email id{" "}
                  </label>
                  <input
                    onChange={onUserChnage}
                    value={user.email}
                    name="email"
                    type="email"
                    className="form-control"
                    id="inputEmail4"
                    placeholder="Enter your email id"
                  />
                </div> */}
                <div className="col-md-6 fieldWrapper">
                  <label htmlFor="mobile" className="form-label">
                    Mobile no.
                  </label>
                  <input
                    value={user.mobileNumber}
                    onChange={onUserChnage}
                    name="mobileNumber"
                    type="text"
                    className="form-control"
                    id="mobileNumber"
                    placeholder="Enter your mobile no."
                    required
                  />
                </div>
                {/* <div className="col-md-6 fieldWrapper">
                  <label htmlFor="pinCode" className="form-label">
                    Area pin code
                  </label>
                  <input
                    onChange={onUserChnage}
                    name="pincode"
                    value={user.pincode}
                    type="text"
                    className="form-control"
                    id="pinCode"
                    placeholder="Enter your pincode"
                  />
                </div> */}
                <div className="col-12 text-center">
                  <button
                    style={{ padding: "12px 28px" }}
                    className="red-btn"
                    // onClick={handleSubmit}
                    type="submit"
                  >
                    Get a call back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallBackSection;
