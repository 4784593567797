import { useCallback, useState } from "react";
import Countdown from "react-countdown";

const CountDownComp = ({ callBack }) => {
  const [countDownTime, setCountDownTime] = useState(Date.now() + 599000);
  //599000
  const renderer = useCallback(({ hours, minutes, seconds, completed }) => {
    if (completed) {
      callBack();
      return (
        <span>
          Session expired
          <span className="text-red">
            {minutes}:{seconds}
          </span>
        </span>
      );
    } else {
      // Render a countdown
      return (
        <span>
          This session will expire in
          <span className="text-red">
            {" "}
            {minutes}:{seconds} minutes
          </span>
        </span>
      );
    }
  }, []);

  return <Countdown date={countDownTime} renderer={renderer} />;
};

export default CountDownComp;
