import React from "react";
import { useNavigate } from "react-router-dom";
import { LogEvent } from "../LogEvent";
import useMobileDetect from "../useMobileDetect";

const Header = ({ onDownloadClick, pinCode, onEditClick, onDeleteClick }) => {
  const navigate = useNavigate();
  const { isMobile } = useMobileDetect();
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a
              className="navbar-brand"
              href="/"
              onClick={() => localStorage.removeItem("buyNowSession")}
            >
              <img src={require("../assets/images/logo.png")} alt="" />
            </a>

            {isMobile() && (
              <a style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{
                    height: "25px",
                    width: "25px",
                    marginTop: pinCode ? 11 : 0,
                  }}
                  src={require("../assets/images/LocationIcon.png")}
                />
                <a
                  onClick={() => {
                    if (!pinCode) onEditClick();
                    LogEvent("Enter Pincode", "enter-pincode-btn");
                  }}
                  style={{
                    paddingTop: 5,
                    fontSize: 12,
                    // color: "rgb(51,102,204)",
                  }}
                >
                  {pinCode ? "Your pincode" : "Enter Pincode"}
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                        paddingTop: 1,
                        fontSize: 10,
                      }}
                      onClick={() => {
                        // onEditClick();
                        LogEvent("PinCode-Edit", "pincode-edit-button");
                      }}
                    >
                      {pinCode}
                    </span>
                    {pinCode && (
                      <span
                        style={{ marginLeft: 5, cursor: "pointer" }}
                        onClick={() => {
                          if (pinCode) {
                            onDeleteClick();
                          } else {
                            onEditClick();
                          }
                          LogEvent("PinCode-Delete", "pincode-delete-button");
                        }}
                      >
                        <img
                          style={{
                            height: "10px",
                            width: "10px",
                            marginBottom: 3,
                          }}
                          src={require("../assets/images/Delete.png")}
                        />
                      </span>
                    )}
                  </div>
                </a>
              </a>
            )}

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {!isMobile() && (
                  <li className="nav-item" style={{ display: "flex" }}>
                    <img
                      style={{
                        height: "25px",
                        width: "25px",
                        marginTop: pinCode ? 17 : 7,
                      }}
                      src={require("../assets/images/LocationIcon.png")}
                    />
                    <p
                      onClick={() => {
                        if (!pinCode) onEditClick();
                        LogEvent("Enter Pincode", "enter-pincode-btn");
                      }}
                      className="nav-link"
                      aria-current="page"
                      style={{
                        cursor: pinCode ? "default" : "pointer",
                        fontSize: 16,
                        // textDecoration: pinCode ? "" : "underline",
                        // color: pinCode ? "" : "rgb(51,102,204)",
                      }}
                    >
                      {pinCode ? "Your pincode" : "Enter Pincode"}
                      <br />
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <span
                          onClick={() => {
                            // onEditClick();
                            LogEvent("PinCode-Edit", "pincode-edit-button");
                          }}
                          style={{
                            cursor: "pointer",
                            fontWeight: 500,
                            fontSize: 14,
                          }}
                        >
                          {pinCode}
                        </span>
                        {pinCode && (
                          <span
                            style={{ marginLeft: 10, cursor: "pointer" }}
                            onClick={() => {
                              if (pinCode) {
                                onDeleteClick();
                              } else {
                                onEditClick();
                              }
                              LogEvent(
                                "PinCode-Delete",
                                "pincode-delete-button"
                              );
                            }}
                          >
                            <img
                              style={{
                                height: "12px",
                                width: "12px",
                                marginBottom: 5,
                              }}
                              src={require("../assets/images/Delete.png")}
                            />
                          </span>
                        )}
                      </div>
                    </p>
                  </li>
                )}

                <li className="nav-item">
                  <p
                    onClick={() => {
                      navigate("/");
                      LogEvent("Home", "home-btn");
                    }}
                    className="nav-link active"
                    aria-current="page"
                  >
                    Home
                  </p>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://aiqahealth.com/AboutUs"
                    onClick={() => LogEvent("aboutus", "aboutus-btn")}
                    target="_blank"
                  >
                    About Us
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://aiqahealth.com/blog"
                    target="_blank"
                    onClick={() => LogEvent("blog", "blog-btn")}
                  >
                    Blog
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/#callBackSection"
                    onClick={() => {
                      LogEvent("Contect-us", "contect-us-btn");
                    }}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {/* <header className="header-container">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a
              className="navbar-brand"
              href="/"
              onClick={() => localStorage.removeItem("buyNowSession")}
            >
              <img
                style={{ height: "75px" }}
                src={require("../assets/images/Aiqa_Health_LOGO2.png")}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse header-right"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                <li className="nav-item">
                  <a
                    onClick={() => {
                      LogEvent("Home", "home-btn");
                    }}
                    className="nav-link active"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://aiqahealth.com/"
                  >
                    home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => {
                      LogEvent("aboutus", "aboutus-btn");
                    }}
                    className="nav-link"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href="https://aiqahealth.com/AboutUs"
                  >
                    about us
                  </a>
                </li>

                <li className="nav-item ">
                  <button
                    className="btn-btn"
                    target="_blank"
                    onClick={() => {
                      onDownloadClick();
                      LogEvent("download", "download-btn");
                    }}
                  >
                    download
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header> */}
    </>
  );
};

export default Header;
