import React, { useEffect, useRef, useState } from "react";
import GoogleMap from "../components/googleMap/Index";
import { Controller } from "react-hook-form";
import { checkPinValidation, getPin } from "../functions";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

export const bookingSlots = [
  "6:30 am to 8:30 am",
  "8:30 am to 10:30 am",
  "10:30 am to 12:30 pm",
];

const CollectionFormDetails = ({
  formDetails,
  setFormDetails,
  setValue,
  getValues,
  clearErrors,
  setLoading,
  control,
  register,
  onChangeBookindDate,
  errors,
  checkoutBtn,
}) => {
  const geoCodeKey = process.env.REACT_APP_GEOCODE_KEY;

  const mapAutoRef = useRef(null);
  const prevPinRef = useRef();

  const [latLong, setLatLong] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [pincodeMatchToggle, setPincodeMatchToggle] = useState(false);

  const onPinChanged = async (val) => {
    if (!isNaN(val) && val.length == 6) {
      return checkPinValidation(val).then((res) => {
        prevPinRef.current = val;
        if (res.code === 200) {
          checkPin(val).then((res) => {
            setValue("state", res?.data);
          });
          return Promise.resolve(true);
        } else {
          setLoading(true);
          return Promise.reject(false);
        }
      });
    } else {
      setValue("state", "");
    }
  };

  const checkPin = async (pin) => {
    if (!pin) return;
    const res = await getPin(pin);
    return res;
  };

  useEffect(() => {
    const pinCodeValue = localStorage.getItem("pincode");

    if (pinCodeValue) {
      setValue("pincode", pinCodeValue);
      onPinChanged(pinCodeValue);

      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${geoCodeKey}&address=${pinCodeValue}&callback=${
          Function.prototype
        }`
      )
        .then((res) => res.json())
        .then((res) => {
          const bounds = res.results[0].geometry.bounds;
          setBounds({
            north: bounds.northeast.lat,
            south: bounds.southwest.lat,
            west: bounds.southwest.lng,
            east: bounds.northeast.lng,
          });
          const lat = res.results[0].geometry.location.lat;
          const lng = res.results[0].geometry.location.lng;
          setLatLong([lat, lng]);
          latLongHandler([lat, lng]);
        });
    }
  }, []);

  const latLongHandler = (e) => {
    setFormDetails({
      ...formDetails,
      latitude: e?.[0],
      longitude: e?.[1],
    });
    setLatLong(e);
  };

  const minDate = () => {
    let currentTime = moment().format("HH");
    if (parseInt(currentTime) >= 17) {
      return new Date().setDate(new Date().getDate() + 2);
    } else {
      return new Date().setDate(new Date().getDate() + 1);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <GoogleMap
            latLong={latLong}
            callBack={latLongHandler}
            setValue={setValue}
            getValue={getValues}
            setFormDetails={setFormDetails}
            formDetails={formDetails}
            clearErrors={clearErrors}
            mapAutoRef={mapAutoRef}
            // onReset={resetAddress}
            bounds={bounds}
            loading={setPincodeMatchToggle}
          />
        </div>
      </div>
      <Controller
        name="pincode"
        control={control}
        render={({ field }) => (
          <div
            style={{ marginBottom: "16px" }}
            className={`col-md-6 col-lg-6 col-xl-6 col-xxl-6`}
          >
            <label htmlFor="pincode" className="form-label">
              Pin Code <span className="text-red">*</span>
            </label>
            <InputMask
              id="pinCodeInputId"
              {...register("pincode", {
                required: "Please Enter pin code",
              })}
              mask="999999"
              className="form-control"
              maskChar=""
              placeholder="Enter your pin code"
              disabled
            />
          </div>
        )}
      />

      <div
        style={{ marginBottom: "16px" }}
        className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
      >
        <label htmlFor="requestedBookingDate" className="form-label">
          Preferred Test Date <span className="text-red">*</span>
        </label>
        <Controller
          name="requestedBookingDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              onInputError={register("requestedBookingDate", {
                required: "please enter booking date",
              })}
              selected={field.value ? new Date(field.value) : ""}
              className="form-control"
              name={field.name}
              onChange={(v) => onChangeBookindDate(v, field)}
              dateFormat="dd-MM-yyyy"
              minDate={minDate()}
              maxDate={new Date().setDate(new Date().getDate() + 7)}
              placeholderText="dd-mm-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={
                <InputMask mask="99-99-9999" placeholder="mm-dd-yyyy" />
              }
              // excludeDates={blackOutDates}
            />
          )}
        />
        {errors.requestedBookingDate && (
          <div className="text-red fs-12 m-1">
            {errors.requestedBookingDate.message}
          </div>
        )}
      </div>
      <div
        style={{ marginBottom: "16px" }}
        className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
      >
        <label htmlFor="requestedBookingSlot" className="form-label">
          Preferred Slot <span className="text-red">*</span>
        </label>
        <select
          {...register("requestedBookingSlot", {
            required: "please select booking slot",
          })}
          className="form-select"
          aria-label="Default select example"
        >
          <option selected value="">
            Select
          </option>
          {bookingSlots.map((elem, index) => {
            return (
              <option key={index} value={elem}>
                {elem}
              </option>
            );
          })}
        </select>
        {errors.requestedBookingSlot && (
          <div className="text-red fs-12 m-1">
            {errors.requestedBookingSlot.message}
          </div>
        )}
      </div>

      <div
        style={{ marginBottom: "16px" }}
        className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
      >
        <label htmlFor="inputCity" className="form-label">
          City <span className="text-red">*</span>
        </label>
        <input
          {...register("city", {
            required: "please enter your city",
            pattern: {
              value: /^[a-zA-Z0-9, ]{2,40}$/,
              message: "please enter valid city name",
            },
          })}
          type="text"
          className="form-control"
          id="inputCity"
          placeholder="Enter your city"
        />
        {errors.city && (
          <div className="text-red fs-12 m-1">{errors.city.message}</div>
        )}
      </div>

      <Controller
        name="state"
        control={control}
        render={({ field }) => (
          <div
            style={{ marginBottom: "16px" }}
            className={`col-md-6 col-lg-6 col-xl-6 col-xxl-6 ${
              field.value ? "" : "d-none"
            }`}
          >
            <label htmlFor="state" className="form-label">
              State
            </label>
            <input
              disabled
              {...register("state")}
              type="text"
              className="form-control"
              id="inputState"
              placeholder="Enter your name"
              required
            />
          </div>
        )}
      />

      <div
        style={{ marginBottom: "16px" }}
        className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
      >
        <label htmlFor="customerAddress2" className="form-label">
          Area/Street/Sector/Village
        </label>
        <input
          {...register("customerAddress2", {})}
          placeholder="Enter your area/street/sector/village "
          type="text"
          className="form-control"
          disabled={latLong && getValues("customerAddress2")}
        />
      </div>

      <div
        style={{ marginBottom: "16px" }}
        className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
      >
        <label htmlFor="customerAddress" className="form-label">
          Flat/House no./Building/Company <span className="text-red">*</span>
        </label>
        <input
          {...register("customerAddress", {
            required: "please enter flat/House no./building/company",
          })}
          placeholder="Enter your address"
          type="text"
          className="form-control"
        />
        {errors.customerAddress && (
          <div className="text-red fs-12 m-1">
            {errors.customerAddress.message}
          </div>
        )}
      </div>
      <div
        style={{ marginBottom: "16px" }}
        className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
      >
        <label htmlFor="landmark" className="form-label">
          Landmark
        </label>
        <input
          {...register("landmark")}
          placeholder="Enter your landmark"
          type="text"
          className="form-control"
        />
      </div>

      {!checkoutBtn && <input type="submit" hidden />}

      <div
        style={{ marginTop: "16px", marginBottom: "10px" }}
        className="d-flex flex-wrap justify-content-around col-12"
      >
        {!checkoutBtn && (
          <button
            className="red-btn mt-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-12"
            type="submit"
          >
            Save Details
          </button>
        )}
      </div>
      {pincodeMatchToggle && (
        <SweetAlert
          style={{ backgroundColor: "#FFDEDD" }}
          info
          customButtons={
            <div className="text-center final-pay-btns">
              <a rel="nofollow noopener noreferrer">
                <button
                  onClick={() => {
                    setPincodeMatchToggle(false);
                  }}
                  type="button"
                  className="red-btn"
                >
                  Ok
                </button>
              </a>
            </div>
          }
        >
          <>
            <span>
              <small style={{ fontSize: "16px", fontWeight: "normal" }}>
                This location is outside of your pincode area !
              </small>
            </span>
          </>
        </SweetAlert>
      )}
    </>
  );
};

export default CollectionFormDetails;
