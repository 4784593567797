import React from "react";
import Carousel from "react-bootstrap/Carousel";

const TestimonialMobile = () => {
  return (
    <div className="row align-items-center d-block d-md-none">
      <Carousel>
        <Carousel.Item interval={1000}>
          <div className="testimonialCard">
            <div className="cardTop">
              <img
                src={require("../../assets/images/ManishKhaptawala.png")}
                alt=""
              />
              <div className="cardTopText">
                <h4>Manish Khaptawala</h4>
                <p>Surat</p>
              </div>
            </div>
            <div className="cardBody">
              <p>
                {" "}
                एक हफ़्ते पहलें मैंने और मैरी Wife ने aiqa health के द्वारा अपनी
                पूरी बोदी चेकअप करने के कारण ब्लड टेस्ट करवाने के लिये बूक
                करवाया । मुजे बहुत ही आश्चर्य हो रहा हैं के आज के टाइम मैं ईटनी
                अच्छी तरीक़े से यह काम करता हैं ।
                <br />
                १) बहुत ही सरलता से बूक होता हैं ।
                <br />
                २) हमारे टाईम के मुताबिक़ ब्लड सैंपल लेने आते हैं । <br />
                ३) डॉक्टर्स भी हमारे टाईम के हिसाब से ही हमे कॉल करते हैं । |
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <div className="testimonialCard">
            <div className="cardTop">
              <img
                src={require("../../assets/images/PushprajSingh.png")}
                alt=""
              />
              <div className="cardTopText">
                <h4>Pushpraj Singh</h4>
                <p>Delhi, India</p>
              </div>
            </div>
            <div className="cardBody">
              <p>
                aiqa health comes as a blessing in our life and to me it is a
                start of a new era as it is helping us in providing need based
                solution to our clients through blood screening followed by
                doctors consultation free of cost for the complete one year.{" "}
                <br />
                Thanks & Regards
                <br /> Sudha & Pushpraj Singh
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <div className="testimonialCard">
            <div className="cardTop">
              <img src={require("../../assets/images/Jaichandra.png")} alt="" />
              <div className="cardTopText">
                <h4>Jai chandra singh</h4>
                <p>Haryana, India</p>
              </div>
            </div>
            <div className="cardBody">
              <p>
                Health is wealth, we can make sure we are living with all health
                parameters with aiqahealth, we support aiqahealth.
              </p>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default TestimonialMobile;
