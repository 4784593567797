import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import DiscountedAmmount from "./common/discountedAmount";
import {
  healthywayAdvancedParameterData,
  healthywayBasicParameterData,
  parameterData,
  basicBenefits,
  advanceBenefits,
  essentialBenefits,
} from "../utils";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "react-multi-carousel/lib/styles.css";
import TestimonialSection from "./home/TestimonialSection";

const Productplan = ({ product, setLeadForm }) => {
  const [toggle, setToggle] = useState(null);
  const [selectProduct, setSelectProduct] = useState(null);
  const targetRef = useRef();

  const isLeadCaptured = localStorage.getItem("leadCaptured");

  const scrollToTarget = () => {
    setTimeout(() => {
      targetRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, 500);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();

  const user =
    location.state && location.state.user ? location.state.user : null;

  let benefits =
    selectProduct === null || selectProduct?.productBenefitDtoList === null
      ? []
      : selectProduct?.productBenefitDtoList;
  benefits = benefits?.slice(0, Math.min(4, benefits.length));

  const buyNow = (item) => {
    navigate("/login", { state: { user: user, product: selectProduct } });
  };

  useEffect(() => {
    if (param.id == "null" || param.id == "undefined") {
      navigate("/");
    } else {
      product?.map((item) => {
        if (param.id == item.productId) {
          setSelectProduct(item);
        }
      });
    }
    scrollToTarget();
  }, [product]);

  useEffect(() => {
    // lead form //
    setTimeout(() => {
      if (
        window.location.pathname ===
          `/productplan/${selectProduct?.productId}` &&
        !isLeadCaptured
      ) {
        setLeadForm("want to know more about our services ?");
      }
    }, 120000);
  }, [selectProduct]);

  const productParameter =
    selectProduct?.productName === "Healthyway Essential"
      ? parameterData
      : selectProduct?.productName === "Healthyway Basic"
      ? healthywayBasicParameterData
      : healthywayAdvancedParameterData;

  const responsiveDesktop = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
    },
  };

  return (
    <>
      {selectProduct ? (
        <div className="productPlanPage">
          <section className="productBannerSectionWrapper">
            <div className="container">
              <div className="productBannerSection">
                <div className="row">
                  <div className="col-lg-5 col-xl-6 col-md-12">
                    <div className="productBannerSectionLeft productPlanFamilyImage">
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={require("../assets/images/planpurchaseimage.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-xl-6 col-md-12">
                    <div className="productBannerSectionRight">
                      <h2 style={{ fontWeight: 700, fontSize: "34px" }}>
                        {selectProduct?.productName}
                      </h2>
                      <p className="text-red fs-18">
                        <span
                          className="stars-container stars-90"
                          style={{ fontSize: "18px" }}
                        >
                          &#9733;&#9733;&#9733;&#9733;&#9733;
                        </span>
                        <span>(400+ people rated)</span>
                      </p>
                      <div className="lists">
                        <ul className="ps-0">
                          {selectProduct.productName === "Healthyway Basic"
                            ? basicBenefits.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="d-flex align-items-start"
                                  >
                                    <img
                                      className="me-3 mt-3"
                                      src={require("../assets/images/green-tick.png")}
                                      alt=""
                                    />
                                    <span className=" fs-18 fs-sm-15">
                                      {item}
                                    </span>
                                  </li>
                                );
                              })
                            : selectProduct.productName ===
                              "Healthyway Essential"
                            ? essentialBenefits.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="d-flex align-items-start"
                                  >
                                    <img
                                      className="me-3 mt-3"
                                      src={require("../assets/images/green-tick.png")}
                                      alt=""
                                    />
                                    <span
                                      className="fs-18 fs-sm-15"
                                      style={{ textAlign: "start" }}
                                    >
                                      {item}
                                    </span>
                                  </li>
                                );
                              })
                            : advanceBenefits.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="d-flex align-items-start"
                                  >
                                    <img
                                      className="me-3 mt-3"
                                      src={require("../assets/images/green-tick.png")}
                                      alt=""
                                    />
                                    <span className=" fs-18 fs-sm-15">
                                      {item}
                                    </span>
                                  </li>
                                );
                              })}

                          {/* {selectProduct.productName === "Healthyway Basic"
                            ? basicBenefits.map((benefit, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="d-flex align-items-start"
                                  >
                                    <img
                                      className="me-3 mt-3"
                                      src={require("../assets/images/green-tick.png")}
                                      alt=""
                                    />
                                    <span className=" fs-18 fs-sm-15">
                                      {benefit}
                                    </span>
                                  </li>
                                );
                              })
                            : ""} */}
                        </ul>
                      </div>
                      <DiscountedAmmount
                        price={selectProduct?.basePrice}
                        discountedPrice={selectProduct?.discountedAmount}
                      />
                      <div className="btnsWrapper mt-8 mb-2 ">
                        <button className="buyNowBtn mt-1" onClick={buyNow}>
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section
            ref={targetRef}
            id="pinCodeChecker"
            className="pincodeAvailibility"
          >
            <div className="container">
              <div
                className="availibilityContainer d-flex flex-wrap justify-content-center"
                style={{ padding: 20 }}
              >
                <h4 className="text-center pt-3" style={{ marginRight: 30 }}>
                  <i className="fa fa-map-marker-alt" aria-hidden="true" />{" "}
                  Check Availability at your pincode
                </h4>
                <div className="pincodeWrapper text-center pt-2 d-flex">
                  <div>
                    <InputMask
                      mask="999999"
                      maskChar=""
                      placeholder="Enter pin code"
                      onChange={pinOnChange}
                    />
                    {error && (
                      <p style={{ fontSize: 12, color: "red" }}>{error}</p>
                    )}
                  </div>
                  <span>
                    <a
                      onClick={checkPinAvailability}
                      className="fw-5 fs-14 text-red"
                    >
                      <button>Check</button>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </section> */}
          <section ref={targetRef} className="benefitsSection">
            <div className="container">
              <h1
                className="labTestHeading"
                style={{ fontWeight: 400, fontSize: "34px" }}
              >
                Lab test <span className="fw-7"> parameters</span>
              </h1>
              <div className="row cardsWrapperPara">
                <div className="col-md-3 col-lg-3 col-xl-3 col-6 class1">
                  <p>12 hours fasting time</p>
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3 col-6 class2">
                  <p>Free home sample collection</p>
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3 col-6 class3">
                  <p>24 hours report</p>
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3 col-6 ">
                  <p>Age 5-90 allowed</p>
                </div>
              </div>
              <h5 className="text-center mb-4">
                {selectProduct?.productName === "Healthyway Essential"
                  ? 84
                  : selectProduct?.productName === "Healthyway Basic"
                  ? 69
                  : 94}{" "}
                parameters included
              </h5>
              <div className="tableParent table-responsive">
                <table className="table w-100 table-borderless">
                  <thead>
                    <tr>
                      <th className="fs-18 fs-esm-16 fw-6">Profit/Parameter</th>
                      <th className="fs-18 fs-esm-16 fw-6 text-center">
                        No. of parameters
                      </th>
                      <th className="fs-18 fs-esm-16 fw-6" />
                    </tr>
                  </thead>
                  <tbody>
                    {productParameter?.map((elem, index) => {
                      return (
                        <>
                          <tr
                            className="table-secondary1"
                            onClick={() => {
                              if (elem.list) {
                                if (index === toggle) {
                                  setToggle(null);
                                } else {
                                  setToggle(index);
                                }
                              }
                            }}
                          >
                            <td
                              style={{
                                backgroundColor: elem.highlight
                                  ? "#FFFFE0"
                                  : "",
                                paddingTop: elem.list ? "" : "15px",
                              }}
                            >
                              {elem.labal}
                            </td>
                            <td
                              className="text-center"
                              style={{
                                backgroundColor: elem.highlight
                                  ? "#FFFFE0"
                                  : "",
                              }}
                            >
                              {elem.count}
                            </td>
                            <td
                              className="text-end"
                              style={{
                                cursor: "pointer",
                                backgroundColor: elem.highlight
                                  ? "#FFFFE0"
                                  : "",
                              }}
                            >
                              {elem.list && (
                                <span className="fs-28">
                                  {index === toggle ? "-" : "+"}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            {index === toggle && (
                              <td colSpan="12">
                                <ul
                                  dangerouslySetInnerHTML={{
                                    __html: elem.list,
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="btnsWrapper text-center">
                <button onClick={buyNow} className="product-btn-red">
                  Buy Now
                </button>
                {/* <button onClick={getCallBack} className='product-btn-white'><img src={require('../assets/images/call-icon.png')} alt="" />Get a call back</button> */}
              </div>
            </div>
          </section>

          <section className="doctorConsultSection">
            <div className="container">
              <h1
                className="mb-5"
                style={{ fontWeight: 400, fontSize: "34px" }}
              >
                Doctor <span className="fw-7"> consult</span>
              </h1>
              {/* <h1 className="mb-5" style={{}}>
                Doctor consult{" "}
              </h1> */}
              <div className="cardsParent">
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cardsWrapper">
                      <img
                        src={require("../assets/images/doctor-icon.png")}
                        alt=""
                      />
                      <h4>Unlimited doctor consults</h4>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cardsWrapper">
                      <img
                        src={require("../assets/images/specialist-icon.png")}
                        alt=""
                      />
                      <h4>16 specialities covered</h4>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cardsWrapper">
                      <img
                        src={require("../assets/images/twenty-four-icon.png")}
                        alt=""
                      />
                      <h4>24 x 7 availability of doctors</h4>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cardsWrapper">
                      <img
                        src={require("../assets/images/video-consultant-icon.png")}
                        alt=""
                      />
                      <h4>Video consultations</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {selectProduct?.productName !== "Healthyway Basic" && (
            <section className="doctorConsultSection">
              <div className="container">
                <h1
                  className="mb-5"
                  style={{ fontWeight: 400, fontSize: "34px" }}
                >
                  Nutrition <span className="fw-7"> consult</span>
                </h1>
                {/* <h1 className="mb-5">Nutritional consultant</h1> */}
                <div className="cardsParent">
                  <div className="row justify-content-center">
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <div className="cardsWrapper">
                        <img
                          src={require("../assets/images/doctor-icon.png")}
                          alt=""
                        />
                        <h4>
                          {selectProduct?.productName === "Healthyway Advanced"
                            ? 4
                            : 2}{" "}
                          nutritional consults
                        </h4>
                      </div>
                    </div>
                    {/* <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <div className="cardsWrapper">
                        <img
                          src={require("../assets/images/specialist-icon.png")}
                          alt=""
                        />
                        <h4>16 specialities covered</h4>
                      </div>
                    </div> */}
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <div className="cardsWrapper">
                        <img
                          src={require("../assets/images/twenty-four-icon.png")}
                          alt=""
                        />
                        <h4>Complimentary diet chart</h4>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <div className="cardsWrapper">
                        <img
                          src={require("../assets/images/video-consultant-icon.png")}
                          alt=""
                        />
                        <h4>Video consultations</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="partnerSection text-center d-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <div className="container">
              <h1
                className="mb-5"
                style={{ fontWeight: 400, fontSize: "34px" }}
              >
                Our <span className="fw-7"> partners</span>
              </h1>
              {/* <h1 className="mb-5">Our partners </h1> */}
              <div className="cardsParent mt-3">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="p-3">
                      <img
                        style={{ width: "45%" }}
                        src={require("../assets/images/patener1.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="p-3">
                      <img
                        style={{ width: "40%" }}
                        src={require("../assets/images/partner2.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="p-3">
                      <img
                        style={{ marginTop: 10 }}
                        src={require("../assets/images/partner3.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* mobile view */}
          <section className="reviewSection col-12 d-block d-md-none d-lg-none d-xl-none d-xxl-none">
            <div className="container">
              <h1 className="mb-4">Our partners </h1>
              <Splide
                options={{
                  type: "loop",
                  drag: "free",
                  focus: "center",
                  perPage: 1,
                  autoScroll: {
                    speed: 1,
                  },
                  arrows: false,
                  autoplay: true,
                  pagination: false,
                  interval: 1500,
                }}
                aria-label="My Favorite Images"
              >
                <SplideSlide>
                  <img
                    src={require("../assets/images/patener1.png")}
                    alt="Image 1"
                    style={{ width: 150 }}
                  />
                </SplideSlide>
                <SplideSlide>
                  <img
                    src={require("../assets/images/partner2.png")}
                    alt="Image 2"
                    style={{ width: 150 }}
                  />
                </SplideSlide>
                <SplideSlide>
                  <img
                    src={require("../assets/images/partner3.png")}
                    alt="Image 3"
                    style={{ width: 150 }}
                  />
                </SplideSlide>
              </Splide>
            </div>
          </section>

          {/*  */}
          {/* <section className="reviewSection col-12 d-block d-md-none d-lg-none d-xl-none d-xxl-none">
            <div className="container">
              <h1 className="mb-4">Our partners </h1>
              <div
                id="carouselExampleInterval"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="3000"
                data-bs-pause="false"
              >
                <div
                  className="carousel-inner"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div className="carousel-item active">
                    <div className="d-flex justify-content-around">
                      <div>
                        <img
                          src={require("../assets/images/patener1.png")}
                          style={{ width: 150 }}
                          alt=""
                        />
                      </div>

                      <div>
                        <img
                          src={require("../assets/images/partner2.png")}
                          style={{ width: 150 }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item ">
                    <div className="d-flex justify-content-between">
                      <div>
                        <img
                          src={require("../assets/images/partner3.png")}
                          style={{ width: 150, marginTop: 20 }}
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          src={require("../assets/images/patener1.png")}
                          style={{ width: 150 }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/*  */}
          <section className="essentialSection text-center">
            <div className="container">
              <h1
                className="mb-5"
                style={{ fontWeight: 400, fontSize: "34px" }}
              >
                Why healthyway is <span className="fw-7"> essential</span>
              </h1>
              {/* <h1 className="w-100">Why healthyway is essential?</h1> */}
              <div className="mt-3">
                <p>
                  The importance of good health in one's life cannot be
                  overstated. However, preventive habits such as eating
                  healthily, going for a walk, exercising, swimming, sports,
                  avoiding junk food, and stress relievers such as yoga and
                  music are just not enough to sustain good health.
                </p>
                <p>
                  Preventive health measures such as regular screening are a
                  great way of being informed about your overall health and to
                  detect the presence of any issues beforehand for a proactive
                  approach. A whole-body check-up is a standard test in which
                  all of the body's systems are readily evaluated. Timely
                  screening can help you in avoiding illnesses.{" "}
                </p>
              </div>
            </div>
          </section>

          <TestimonialSection />
        </div>
      ) : (
        <>
          <div class="linear-background" />
          <br />
          <br />
          <div class="linear-background" />
        </>
      )}
    </>
  );
};

export default Productplan;
