import React from "react";

const BloodTest = ({ data }) => {
  return (
    <section id="bloodTestSection">
      <div className="bloodTestSectionWrapper">
        <div className="container">
          <div className="bloodTestHeader">
            <h2>Included blood tests</h2>
          </div>
          <div className="row align-items-center justify-content-center">
            {data?.why?.map((item, index) => {
              return (
                <div key={index} className="col-md-6 col-lg-4">
                  <div className="bloodTestCard">
                    <div className="cardTop">
                      <h3>{item.paramName}</h3>
                      <hr />
                    </div>
                    <div className="cardBody">
                      <p>
                        Comprehensive check on lifestyle, health, and age
                        related issues.
                      </p>
                      <div className="tagsWrapper">
                        <p>Why this is for you</p>
                        <div className="tags">
                          {item.answerList.map((elem, index) => {
                            return <span key={index}>{elem}</span>;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BloodTest;
