import moment from "moment/moment";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import {
  checkPinValidation,
  getPin,
  getBlackOutDays,
  basicDetailsMilestone,
  collectionFormMilestone,
} from "../functions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import SweetAlert from "react-bootstrap-sweetalert";
import pinImage from "../assets/images/pinCodeNotFound.png";
import { LogEvent } from "../LogEvent";
import CollectionFormDetails from "./CollectionFormDetails";

// export const blackOutDates = ["2023/01/26", "2023/01/27"];

const PaymentFormCard = (props) => {
  const stepRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const {
    setUser,
    checkoutBtn,
    formDisable,
    setFormDisable,
    setCheckoutBtn,
    setFormDetails,
    formDetails,
    prevUser,
    setformIndex,
    setcurrIndex,
    checkoutRef,
  } = props;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "all",
    defaultValues: { name: prevUser.subscription_holder_name },
  });

  const [step, setStep] = useState(1);
  const [age, setAge] = useState(0);

  const [blackOutDate, setBlackOutDate] = useState(null);
  const [blackOutDates, setBlackOutDates] = useState([]);

  const [inter1, setInter1] = useState(null);
  const [inter2, setInter2] = useState(null);

  const [pincodeMatchToggle, setPincodeMatchToggle] = useState(false);

  useEffect(() => {
    var arr = [];
    getBlackOutDays().then((res) => {
      res?.responseObject?.map((d) => {
        if (d.blockLabTest)
          arr.push(moment(d.nonServiceableDate).format("YYYY/MM/DD"));
      });
      setBlackOutDates(arr);
    });

    return () => {
      clearInterval(inter1);
      clearInterval(inter2);
    };
  }, []);

  const step1interval = () => {
    const interval1 = setInterval(() => {
      const visitorId = localStorage.getItem("visitorId");
      const couponCode = localStorage.getItem("coupon_code");
      const basicData = getValues([
        "name",
        "mobile",
        "dob",
        "email",
        "gender",
        "govtIDType",
        "govtIDNumber",
      ]);

      let newData = {
        visitorId: visitorId,
        couponCode: couponCode,
        name: basicData[0],
        mobile: basicData[1],
        dob: basicData[2],
        email: basicData[3],
        gender: basicData[4],
        govtIDType: basicData[5],
        govtIDNumber: basicData[6],
      };
      basicDetailsMilestone(newData);
    }, 2000);

    setInter1(interval1);
  };

  const step2interval = () => {
    const interval2 = setInterval(() => {
      const visitorId = localStorage.getItem("visitorId");
      const couponCode = localStorage.getItem("coupon_code");
      const basicData = getValues([
        "pincode",
        "requestedBookingDate",
        "requestedBookingSlot",
        "city",
        "state",
        "customerAddress2",
        "customerAddresscustomerAddress",
        "landmark",
      ]);

      let newData = {
        visitorId: visitorId,
        couponCode: couponCode,
        pincode: basicData[0],
        requestedBookingDate: basicData[1],
        requestedBookingSlot: basicData[2],
        city: basicData[3],
        state: basicData[4],
        customerAddress2: basicData[5],
        customerAddresscustomerAddress: basicData[6],
        landmark: basicData[7],
      };
      collectionFormMilestone(newData);
    }, 2000);
    setInter2(interval2);
  };

  useEffect(() => {
    if (step === 1) {
      step1interval();
      clearInterval(inter2);
    }
    if (step === 2) {
      step2interval();
      clearInterval(inter1);
    }
  }, [step]);

  const validateGovtIdNumber = (idType, id) => {
    if (idType.localeCompare("aadhar") === 0) {
      const regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
      const found = id.match(regex);
      if (found === null || id.length < 4) {
        toast.dismiss();
        toast.error("Aadhar Number Seems Invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return false;
      }
      return true;
    } else if (idType.localeCompare("voter") === 0) {
      const regex = /^[a-zA-Z0-9\\/ ]{1,17}$/g;
      const found = id.match(regex);
      if (found === null || id.length < 4) {
        toast.dismiss();
        toast.error("Voter ID Seems Invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return false;
      }
      return true;
    } else if (idType.localeCompare("passport") === 0) {
      const regex = /^(?!^0+$)[a-zA-Z0-9]{6,9}$/g;
      const found = id.match(regex);
      if (found === null || id.length < 4) {
        toast.dismiss();
        toast.error("Passport Seems Invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return false;
      }
      return true;
    } else if (idType.localeCompare("driving_licence") === 0) {
      const regex = /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
      const found = id.match(regex);
      if (found === null || id.length < 4) {
        toast.dismiss();
        toast.error("Licence Number Seems Invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return false;
      }
      return true;
    } else {
      toast.dismiss();
      toast.error("Could not validate Id Number", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };

  function invalidEmail(mail) {
    // eslint-disable-next-line
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const found = mail.match(regex);
    if (found === null) {
      return true;
    }
    return false;
  }

  const invalidNameCheck = (name) => {
    if (`${name}`.match(/^[a-zA-Z ]+$/)) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (data) => {
    data.initiateMobileNumber = prevUser.mobile_number;
    data.policyStartDate = new Date().toISOString().slice(0, 10);

    const visitorId = localStorage.getItem("visitorId");
    const couponCode = localStorage.getItem("coupon_code");

    const basicData = {
      visitorId: visitorId,
      couponCode: couponCode,
      ...data,
    };

    if (step === 1) {
      LogEvent("Basic-Details", "user-details");
      if (!invalidNameCheck(data.name)) {
        toast.dismiss();
        toast.error(`${data.name} - Name is invalid`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return;
      } else if (!data.dob) {
        toast.dismiss();
        toast.error(`Please fill date of birth`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return;
      } else if (invalidEmail(data.email)) {
        toast.dismiss();
        toast.error("Email is invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return;
      } else if (!validateGovtIdNumber(data.govtIDType, data.govtIDNumber)) {
        return;
      } else if (data.gender === null || data.gender.length === 0) {
        toast.dismiss();
        toast.error("Please Specify Gender", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return;
      }
      setStep(2);
      stepRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      // step1 basic details milestone
      basicDetailsMilestone(basicData);
    } else if (step === 2) {
      LogEvent("Basic-Details", "lab-test-details");
      if (data.pincode.length !== 6) {
        toast.dismiss();
        toast.error("Pincode length must be 6", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return;
      }
      if (data.pincode.length && !data.state) {
        toast.error("No service available for this pincode", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return;
      }
      setFormDisable(true);
      setCheckoutBtn(true);
      await setUser(data);
      await setFormDetails({ ...formDetails, pincode: data.pincode });
      document.getElementById("checkoutButton").scrollIntoView();
      toast.dismiss();
      toast.success(
        "Details have been confirmed & Saved, You can proceed to checkout",
        { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000 }
      );
      setformIndex(3);
      clearInterval(inter2);
      clearInterval(inter1);
      setcurrIndex(2);
      checkoutRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      // collection form milestone
      collectionFormMilestone(basicData);
    }
  };

  const handleCalculateAge = (age) => {
    const year = moment().diff(age, "years", false);
    if (year) {
      setAge(year);
    } else {
      setAge(0);
    }
  };

  const onChangeBookindDate = (v, field) => {
    if (blackOutDates.indexOf(moment(v).format("YYYY/MM/DD")) > -1) {
      setBlackOutDate(v);
    } else {
      field.onChange(moment(v).format("YYYY-MM-DD"));
    }
  };

  const keyDownHandler = (e) => {
    if (loading) {
      e.preventDefault();
    }
  };

  return (
    <div onKeyDown={keyDownHandler} className="checkout-form">
      {blackOutDate && (
        <SweetAlert
          info
          title="Sorry ! "
          onConfirm={() => setBlackOutDate(null)}
          customButtons={
            <div className="text-center final-pay-btns">
              <a rel="nofollow noopener noreferrer">
                <button
                  onClick={() => setBlackOutDate(null)}
                  type="button"
                  className="red-btn"
                >
                  Ok
                </button>
              </a>
            </div>
          }
        >
          {` We are not operational on ${moment(blackOutDate).format(
            "LL"
          )} . Kindly choose
          another date .`}
        </SweetAlert>
      )}
      {loading && (
        <SweetAlert
          customButtons={
            <div className="text-center final-pay-btns">
              <a rel="nofollow noopener noreferrer">
                <button
                  onClick={() => setLoading(false)}
                  type="button"
                  className="red-btn"
                >
                  Ok
                </button>
              </a>
            </div>
          }
        >
          <>
            <img
              src={pinImage}
              alt="pinImage"
              style={{
                marginBottom: "20px",
                height: "150px",
                width: "200px",
              }}
            />
            <br />
            <span>
              <small style={{ fontSize: "16px", fontWeight: "normal" }}>
                Our Services are not available at this Pincode. Our team is
                working hard to bring services to your location
              </small>
            </span>
          </>
        </SweetAlert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} id="formDetails">
        <fieldset disabled={formDisable} className="row">
          <div className="text-center mb-3" ref={stepRef}>
            <button
              type="button"
              className="btn col-12 col-lg-8 col-xl-8 col-xxl-8 col-md-8 bg-red-color btn-danger"
              style={{ cursor: "default" }}
            >
              {step === 1 ? "Basic Details" : "Home Collection Details"}
            </button>
            {step === 1 ? (
              <p className="mt-2">
                Enter the details of customer for whom you are booking this
                test.
              </p>
            ) : null}

            {step === 2 && (
              <p className="col-12 mt-1 fs-14">
                <i>* Requires 12 hours fasting</i>
              </p>
            )}
          </div>
          {step === 2 && (
            <div className="text-red mb-3 col-12">
              <div
                onClick={() => setStep(1)}
                className="col-2"
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-long-arrow-alt-left" />
                <span style={{ marginLeft: 3 }}>Back</span>
              </div>
            </div>
          )}
          {step === 1 && (
            <>
              <div
                style={{ marginBottom: "16px" }}
                className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <label htmlFor="inputName" className="form-label">
                  Name <span className="text-red">*</span>
                </label>
                <input
                  {...register("name", {
                    required: "please enter your name",
                    pattern: {
                      value: /^[a-zA-Z ]+$/,
                      message: "please enter valid name",
                    },
                  })}
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Enter your name"
                  // required
                />
                {errors.name && (
                  <div className="text-red fs-12 m-1">
                    {errors.name.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 form-field">
                <Controller
                  name="mobile"
                  control={control}
                  render={({ field }) => (
                    <>
                      <label htmlFor="mobile" className="form-label">
                        Customer Mobile Number{" "}
                        <span className="text-red">*</span>
                      </label>
                      <InputMask
                        {...register("mobile", {
                          required: "Please Enter mobile number",
                          pattern: {
                            value: /^(([^0-5])[0-9]{9})$/,
                            message: "please enter valid mobile number",
                          },
                          minLength: {
                            value: 10,
                            message: "please enter valid mobile number",
                          },
                        })}
                        onChange={(e) => {
                          const value = e.target.value;
                          var numberRegex = /^\d+$/;
                          if (
                            (numberRegex.test(value) && value.length <= 10) ||
                            value.length === 0
                          ) {
                            setValue("mobile", value);
                          }
                        }}
                        onPaste={(e) => {
                          e.clipboardData.items[0].getAsString((value) => {
                            var numberRegex = /^\d+$/;
                            if (
                              (numberRegex.test(value) && value.length <= 10) ||
                              value.length === 0
                            ) {
                              setValue("mobile", value);
                            } else if (value.length > 10) {
                              setValue(
                                "mobile",
                                value.replace(/[^0-9]+/g, "").slice(-10)
                              );
                            }
                          });
                        }}
                        value={getValues("mobile")}
                        className="form-control"
                        maskChar="9999999999"
                        placeholder="Enter your mobile number"
                      />
                    </>
                  )}
                />
                {errors.mobile && (
                  <div className="text-red fs-12 m-1">
                    {errors.mobile.message}
                  </div>
                )}
              </div>
              <div
                style={{ marginBottom: "16px" }}
                className="col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-6"
              >
                <label htmlFor="inputdob" className="form-label">
                  Enter DOB
                  <span className="text-red">*</span>
                </label>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      onInputError={register("dob", {
                        required: "pleasae enter your dob",
                      })}
                      selected={field.value ? new Date(field.value) : ""}
                      name={field.name}
                      onChange={(v) => {
                        field.onChange(moment(v).format("YYYY-MM-DD"));
                        handleCalculateAge(v);
                      }}
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date(moment().subtract(90, "years"))}
                      maxDate={new Date(moment().subtract(5, "years"))}
                      placeholderText="dd-mm-yyyy"
                      className="form-control"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <InputMask mask="99-99-9999" placeholder="mm-dd-yyyy" />
                      }
                    />
                  )}
                />
                {errors.dob && (
                  <div className="text-red fs-12 m-1">{errors.dob.message}</div>
                )}
              </div>
              <div
                style={{ marginBottom: "16px" }}
                className="col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-6"
              >
                <label htmlFor="inputdob" className="form-label">
                  Age
                </label>
                <input
                  value={age ? age + " " + "years" : 0}
                  type="text"
                  className="form-control"
                  id="inputAge"
                  disabled
                  placeholder="0"
                />
              </div>
              <div
                style={{ marginBottom: "16px" }}
                className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 "
              >
                <label htmlFor="inputemail" className="form-label">
                  Enter Email <span className="text-red">*</span>
                </label>
                <input
                  {...register("email", {
                    required: "please enter your email",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "please enter valid email",
                    },
                  })}
                  type="email"
                  className="form-control"
                  id="inputemail"
                  placeholder="Enter your email"
                />
                {errors.email && (
                  <div className="text-red fs-12 m-1">
                    {errors.email.message}
                  </div>
                )}
              </div>
              <div
                style={{ marginBottom: "16px" }}
                className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <label htmlFor="inputid1" className="form-label">
                  Gender <span className="text-red">*</span>
                </label>
                <div
                  className="d-flex align-items-center"
                  style={{ height: "60%" }}
                >
                  <div className="form-check ps-2 mx-3">
                    <input
                      {...register("gender", {
                        required: "please enter your gender",
                      })}
                      className="form-check-input"
                      type="radio"
                      id="exampleRadios1"
                      value="MALE"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Male
                    </label>
                  </div>
                  <div className="form-check ps-2 mx-3">
                    <input
                      {...register("gender")}
                      className="form-check-input"
                      type="radio"
                      id="exampleRadios2"
                      value="FEMALE"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios2"
                    >
                      Female
                    </label>
                  </div>
                  <div className="form-check ps-2 mx-3">
                    <input
                      {...register("gender")}
                      className="form-check-input"
                      type="radio"
                      id="exampleRadios3"
                      value="OTHER"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios3"
                    >
                      Other
                    </label>
                  </div>
                </div>
                {errors.gender && (
                  <div className="text-red fs-12 mb-2">
                    {errors.gender.message}
                  </div>
                )}
              </div>
              {/* */}
              <div
                style={{ marginBottom: "16px" }}
                className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <label htmlFor="inputid1" className="form-label">
                  Government ID Type <span className="text-red">*</span>
                </label>
                <select
                  {...register("govtIDType", {
                    required: "please enter your id type",
                  })}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected value="">
                    Select
                  </option>
                  <option value="aadhar">Aadhar</option>
                  <option value="voter">Voter ID</option>
                  <option value="passport">Passport</option>
                  <option value="driving_licence">Driving License</option>
                </select>
                {errors.govtIDType && (
                  <div className="text-red fs-12 m-1">
                    {errors.govtIDType.message}
                  </div>
                )}
              </div>
              <div
                style={{ marginBottom: "16px" }}
                className="col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <label htmlFor="inputCode" className="form-label">
                  Government ID Number <span className="text-red">*</span>
                </label>
                <input
                  {...register("govtIDNumber", {
                    required: "please enter your id",
                  })}
                  type="text"
                  className="form-control"
                  id="inputCode"
                  placeholder="Enter government Id"
                />
                {errors.govtIDNumber && (
                  <div className="text-red fs-12 m-1">
                    {errors.govtIDNumber.message}
                  </div>
                )}
              </div>
              <input type="submit" hidden />
              <div
                style={{
                  marginTop: "16px",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
                className="col-12"
              >
                <button className={`red-btn mt-4`} type="submit">
                  Next
                </button>
              </div>
            </>
          )}
          {step === 2 && (
            <CollectionFormDetails
              setFormDetails={setFormDetails}
              formDetails={formDetails}
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              setLoading={setLoading}
              control={control}
              register={register}
              onChangeBookindDate={onChangeBookindDate}
              errors={errors}
              checkoutBtn={checkoutBtn}
            />
          )}
        </fieldset>
      </form>
      {pincodeMatchToggle && (
        <SweetAlert
          style={{ backgroundColor: "#FFDEDD" }}
          info
          customButtons={
            <div className="text-center final-pay-btns">
              <a rel="nofollow noopener noreferrer">
                <button
                  onClick={() => {
                    setPincodeMatchToggle(false);
                  }}
                  type="button"
                  className="red-btn"
                >
                  Ok
                </button>
              </a>
            </div>
          }
        >
          <>
            <span>
              <small style={{ fontSize: "16px", fontWeight: "normal" }}>
                This location is outside of your pincode area !
              </small>
            </span>
          </>
        </SweetAlert>
      )}
    </div>
  );
};

export default PaymentFormCard;
