import React, { useEffect } from "react";
import { Watch } from "react-loader-spinner";

const Loader = ({ setLoading }) => {
  const root = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  };
  return (
    <div className="loader-class">
      <div style={root}>
        <Watch
          height="80"
          width="80"
          radius="48"
          color="#fff"
          ariaLabel="loading..."
          wrapperStyle={{ justifyContent: "center" }}
          wrapperClassName=""
          visible={true}
        />
        {setLoading && (
          <p
            data-toggle="tooltip"
            data-placement="auto"
            title="Close"
            style={{
              color: "white",
              paddingLeft: 25,
              marginLeft: 10,
              height: 35,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            <h6>Your transaction is being processed!!!</h6>
            <br />
            <span>
              if you want to stop this transaction{" "}
              <a
                onClick={() => setLoading()}
                style={{
                  color: "red",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {" "}
                click here
              </a>
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Loader;
