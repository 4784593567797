import React, { useState, useEffect } from "react";
import Card from "./Card";
import OtpInput from "react-otp-input";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { getOTP, verifyOTP } from "../functions";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import { LogEvent } from "../LogEvent";

const Login = ({ onContectClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const product =
    location.state && location.state.product ? location.state.product : null;
  let timerId = 0;
  const [sessionAlert, setSessionAlert] = useState({
    status:
      location.state && location.state.session ? location.state.session : null,
    message:
      location.state && location.state.sessionMessage
        ? location.state.sessionMessage
        : null,
  });

  const pincode = localStorage.getItem("pincode");

  useEffect(() => {
    return () => {
      window.onpopstate = () => {
        localStorage.removeItem("buyNowSession");
      };
      localStorage.removeItem("buyNowSession");
    };
  }, [location]);

  const [modalProduct, setModalProduct] = useState(null);
  const [timer, setTimer] = useState(30);
  const [currIndex, setcurrIndex] = useState(0);
  const [formIndex, setformIndex] = useState(0);
  const [resend, setResend] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [invalidOtpCounter, setInvalidOtpCounter] = useState(0);
  const [otpResend, setOtpResend] = useState(true);
  const [state, setState] = useState({
    phone: "",
    otp: "",
  });
  const [user, setUser] = useState({
    subscription_holder_name: "",
    mobile_number: "",
    dob: "",
    relation_with_client: "self",
    email: "",
    govt_id_type: "",
    govt_id_number: "",
    sex: "",
  });

  const onUserChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onMobileChanged = (e) => {
    if (!isNaN(e.target.value) && e.target.value.length < 11)
      setUser({ ...user, mobile_number: e.target.value });
  };

  const handleChange = (otp) => setState({ otp });

  function plusIndex(n) {
    setcurrIndex((prev) => prev + n);
  }

  const makeResendButton = async () => {
    setTimeout(() => {
      setResend(true);
      setOtpResend(false);
      clearInterval(timerId);
    }, 30000);
  };

  const startTimer = async () => {
    setTimer(30);
    timerId = setInterval(async () => {
      setTimer((timer) => timer - 1);
    }, 1000);
  };

  const handleGetOtp = async () => {
    const isLeader = localStorage.getItem("isLeader");
    const couponCode = localStorage.getItem("coupon_code");
    const pincode = localStorage.getItem("pincode");
    const data = {
      mobileNumber: user.mobile_number,
      isLeader: isLeader,
      name: user.subscription_holder_name,
      couponCode: couponCode,
      pincode: pincode,
    };
    return await getOTP(data).then((res) => {
      if (res.code == 200) {
        localStorage.setItem("visitorId", res.responseObject?.visitorId);
        return true;
      } else {
        return false;
      }
    });
  };

  async function handleNext(e) {
    if (formIndex === 0) {
      LogEvent("Send-Otp", "send-otp-btn");
      startTimer();
      if (
        user?.subscription_holder_name?.length > 2 &&
        user?.mobile_number?.length === 10
      ) {
        if (await handleGetOtp()) setformIndex(formIndex + 1);
      } else {
        toast.dismiss();
        toast.error("Please provide valid details", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        return;
      }
      makeResendButton();
      return;
    }
    if (formIndex === 1) {
      LogEvent("Verify-Otp", "verify-otp-btn");
      if (await verifyOTP(user.mobile_number, state.otp)) {
        clearInterval(timerId);
        navigate("/payment-login", { state: { user: user, product: product } });
        localStorage.setItem("buyNowSession", true);
      } else {
        if (invalidOtpCounter < 2) setInvalidOtpCounter(invalidOtpCounter + 1);
        else {
          toast.dismiss();
          toast.error(`Wrong OTP`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
          setInvalidOtpCounter(0);
          setformIndex(0);
        }
        if (invalidOtpCounter < 2) {
          toast.dismiss();
          toast.error(
            `Wrong OTP. You have ${3 - invalidOtpCounter - 1} more attempts`,
            { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000 }
          );
        }
      }
      return;
    }
    if (formIndex >= 1) {
      plusIndex(1);
    }

    setformIndex(formIndex + 1);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (product === null || user === null || !pincode) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
    }
  }, [product]);

  const handleAlertConfirm = () => {
    setSessionAlert(false);
    window.history.replaceState(
      {
        product: window.history.state.product,
        session: false,
        sessionMessage: null,
      },
      ""
    );
  };

  return (
    <section className="login-page">
      {sessionAlert.status && (
        <SweetAlert
          error
          confirmBtnBsStyle="danger"
          title={sessionAlert.message}
          // confirmBtnText="Refresh"
          customButtons={
            <div className="text-center final-pay-btns">
              <a rel="nofollow noopener noreferrer">
                <button
                  onClick={handleAlertConfirm}
                  type="button"
                  className="red-btn"
                >
                  Ok
                </button>
              </a>
            </div>
          }
          onConfirm={() => handleAlertConfirm()}
        />
      )}
      <div className="container">
        <div className="login-page-container">
          {product && (
            <div className="row">
              <div className="col-12 d-block d-lg-none d-xl-none d-xxl-none">
                <div
                  className="plan-box plan-box-2 "
                  style={{
                    borderRadius: "10px",
                    paddingBottom: "35px",
                    minHeight: "100%",
                  }}
                >
                  <h2 className="text-white">{product?.productName}</h2>
                  <p className="text-white text-center">
                    <span className="fw-6">₹ {product?.discountedAmount} </span>
                    <span>Yearly*</span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-8 col-xl-8 col-xxl-8">
                <div className="login-form-parent">
                  <h1>
                    Welcome to <span className="text-red">aiqahealth</span>
                  </h1>
                  <p>
                    We are now India's most affordable health service provider
                  </p>
                  <div className="login-form-content">
                    <div className="form-progress-bar">
                      <ul>
                        <div
                          className="progress"
                          style={{ width: `calc(100%/2 *${currIndex})` }}
                        />
                        {["login", "otp", "info", "invoice"].map(
                          (item, index) => (
                            <li
                              key={item}
                              data-title={item}
                              className={`${
                                currIndex >= index ? "active" : ""
                              }`}
                            >
                              {/* {index + 1} */}
                            </li>
                          )
                        )}
                      </ul>
                    </div>

                    {/* Pages */}
                    <div className="login-form-content-details">
                      {/* ********LoginSetup form********* */}
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="row"
                        action=""
                      >
                        {formIndex === 0 && (
                          <>
                            <div className="col-md-6 form-field">
                              <label htmlFor="inputName" className="form-label">
                                Name <span className="text-red">*</span>
                              </label>
                              <input
                                {...register("subscription_holder_name", {
                                  required: true,
                                })}
                                type="text"
                                className="form-control"
                                id="inputName"
                                onChange={onUserChange}
                                placeholder="Enter your name"
                                required
                              />
                              {errors.subscription_holder_name?.type ===
                                "required" && "Name is required"}
                            </div>
                            <div className="col-md-6 form-field">
                              <label htmlFor="number" className="form-label">
                                Contact Number{" "}
                                <span className="text-red">*</span>
                              </label>
                              <input
                                {...register("mobile_number", {
                                  required: true,
                                })}
                                type="tel"
                                className="form-control"
                                id="number"
                                onChange={onMobileChanged}
                                onKeyDown={(e) => {
                                  if (
                                    e.key == "Enter" &&
                                    user.mobile_number.length > 9
                                  )
                                    handleNext();
                                }}
                                name="mobile_number"
                                value={user.mobile_number}
                                placeholder="Enter your contact number"
                                required
                              />
                            </div>
                            <div className="col-12 text-center">
                              <button
                                disabled={
                                  user?.subscription_holder_name?.length <= 0 ||
                                  user?.mobile_number?.length < 10
                                    ? true
                                    : ""
                                }
                                style={{ margin: "50px 0 30px 0" }}
                                onClick={handleNext}
                                type="button"
                                className="btn-btn"
                              >
                                Send OTP
                              </button>
                            </div>
                            <p>
                              If you are not sure, please{" "}
                              <a
                                className="text-red text-decoration-underline"
                                // target="_blank"
                                onClick={() => {
                                  LogEvent("Contect-us", "contect-us-btn");
                                }}
                                style={{ cursor: "pointer" }}
                                href="/#callBackSection"
                              >
                                Contact Us
                              </a>{" "}
                            </p>
                          </>
                        )}

                        {/* ********OTP form********* */}
                        {formIndex === 1 && (
                          <div
                            onKeyDown={(e) => {
                              if (e.key == "Enter" && state.otp > 3)
                                handleNext();
                            }}
                          >
                            <h4 className="text-center">Enter OTP</h4>
                            <div className="d-inline">
                              <p style={{ margin: "20px 0 0px 0" }}>
                                {`We have sent OTP to +91-${
                                  user.mobile_number
                                } `}
                                <a
                                  onClick={() => {
                                    clearInterval(timerId);
                                    setformIndex(0);
                                    setResend(false);
                                    setOtpResend(true);
                                    setState({
                                      ...state,
                                      otp: "",
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-red text-decoration-underline"
                                >
                                  Edit
                                </a>
                              </p>
                            </div>
                            <div
                              style={{ margin: "32px 0 30px 0" }}
                              className="text-center d-flex align-items-center justify-content-center"
                            >
                              <OtpInput
                                value={state.otp}
                                className="otp-input"
                                onChange={handleChange}
                                numInputs={4}
                                separator={<span />}
                                isInputNum={true}
                                shouldAutoFocus={true}
                              />
                            </div>
                            {otpResend && <p>Enter OTP within {timer}s</p>}
                            {resend && (
                              <p>
                                Did not receive?{" "}
                                <a
                                  onClick={() => {
                                    handleGetOtp();
                                    setResend(false);
                                    makeResendButton();
                                    setOtpResend(true);
                                    startTimer();
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-red text-decoration-underline"
                                >
                                  Resend{" "}
                                </a>{" "}
                              </p>
                            )}
                            <div className="col-12 text-center">
                              <button
                                disabled={state?.otp?.length < 4 ? true : ""}
                                style={{ margin: "30px 0 30px 0" }}
                                type="button"
                                onClick={handleNext}
                                className="btn btn-btn"
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-none d-lg-block d-xl-block d-xxl-block">
                <Card
                  product={product}
                  color={1}
                  boxType={3}
                  setModalProduct={setModalProduct}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade quick-view-modal"
        id="plan-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          {modalProduct !== null && (
            <div className="modal-content">
              <div className="modal-header border-bottom-0 mb-2">
                <div className="mx-4 my-3 d-flex">
                  <span className="fs-24 fw-6">
                    {modalProduct.productName} <br />
                    <span className="fs-24 fs-8">
                      ₹{modalProduct.basePrice}/-
                    </span>
                  </span>
                </div>
                <button
                  style={{ position: "absolute", top: "15px", right: "0px" }}
                  type="button"
                  className="btn-close mt-0 mx-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                {modalProduct.productBenefitDtoList !== null &&
                  modalProduct?.productBenefitDtoList?.length > 0 && (
                    <ul className="">
                      {modalProduct.productBenefitDtoList.map(
                        (benefit, index) => {
                          return (
                            <li
                              key={index}
                              className="d-flex align-items-start mt-4"
                            >
                              <img
                                className="me-3"
                                src={require("../assets/images/green-tick.png")}
                                alt=""
                              />
                              <span>{benefit.descriptionText}</span>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                {modalProduct.productBenefitDtoList !== null &&
                  modalProduct?.productBenefitDtoList?.length === 0 && (
                    <div className="">
                      <h5 className="text-center my-5">
                        No Benefits To Display At The Moment!!!
                      </h5>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Login;
