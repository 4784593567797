export const getDays = () => {
  const arr = [];
  for (let i = 1; i <= 31; i++) {
    if (i < 10) {
      arr.push(`0${i}`);
    } else {
      arr.push(i);
    }
  }
  return arr;
};

export const getMonths = () => {
  return [
    { value: "01", title: "January" },
    { value: "02", title: "February" },
    { value: "03", title: "March" },
    { value: "04", title: "April" },
    { value: "05", title: "May" },
    { value: "06", title: "June" },
    { value: "07", title: "July" },
    { value: "08", title: "August" },
    { value: "09", title: "September" },
    { value: "10", title: "October" },
    { value: "11", title: "November" },
    { value: "12", title: "December" },
  ];
};

export const getYears = () => {
  const arr = [];
  for (let i = 2020; i >= 1950; i--) {
    arr.push(i);
  }
  return arr;
};

export const isValidEmail = (str) => {
  //eslint-disable-next-line
  if (/^[a-z0-9!#$%&'+\/=?^_`{|}~.-]+@\w+([\.-]?\w+)?(\.\w{2,10})+$/.test(str))
    return true;
  return false;
};

export const faqData = [
  {
    title: "How can I buy the plan ?",
    body: "You can buy the plan from healthyway.aiqahealth.com",
  },
  {
    title: "How to pay for the plan?",
    body:
      "We have multiple payment options ( UPI, Net Banking, Credit Card and Debit Card) for making the payment.",
  },
  {
    title: "What do I get in this plan ?",
    body:
      "You get 66 parameters full body check up and unlimited doctor consultation",
  },
  {
    title: "What are the 66 parameters included ?",
    body:
      "Cardiac Screening (5), Diabetes (2), Complete Hemogram (24), Iron (1), Kidney Screening (7), Lipid (10), Liver (12), Thyroid(3), Vitamin(2)",
  },
  {
    title: "How can I avail doc consultation benefits ?",
    body:
      "You can avail telemedicine benefits by using aiqahealth app available on both Google Play store and iOS App store. You can also call on 6262 306 306 to avail doctor consultation.",
  },

  {
    title: "How can I reschedule my lab test ?",
    body: "Kindly connect with our customer care executive at 6262 306 306",
  },
  {
    title: "How can I cancel my existing lab test ?",
    body: "Kindly connect with our customer care executive at 6262 306 306",
  },

  // title: "How to prepare for my lab test?",
  // bodyType: "ul",
  // body: [
  //   "You must fast overnight for 12 hours before the appointment. Only water can be consumed.",
  //   " Avoid smoking or consuming alcohol for a minimum of 24 hours before the Health Checkup.",
  //   " Women should not undergo lab tests at the time of menstruation.",
  // ],

  {
    title:
      "Who will come for my sample pickup ? How do I connect with a Phlebotomist ?",
    body:
      "Phlebotomist will be assigned to you 2-3 hours before test time. Details of the same would be shared to the customer via text message and email.",
  },
  {
    title: "How do I make sure my sample pick up is safe and hygienic ?",
    body:
      "Our foremost priority is to ensure accurate, high quality reports.. All our phlebotomists are qualified in medical lab technology (DMLT) and are highly skilled at their jobs. All machines and equipment in our partner labs use the most advanced technology. They are checked and calibrated on a daily basis to ensure correct readings.",
  },
  {
    title:
      "The Phlebotomist did not come for a sample collection, what should I do ?",
    body:
      "In case Phlebotomist did not come for sample collection, Kindly connect with our customer executive at 6262 306 306",
  },
  {
    title: "How long does it usually take to get my lab reports ?",
    body:
      "We try to provide your reports at the earliest possible. This usually takes around 24-48 hours.",
  },
  {
    title: "How will I receive my reports ?",
    body:
      "You will receive your reports on your registered email id and mobile number. The same can also be downloaded from aiqahealth application",
  },
  {
    title: "How can I book a doctor consultation?",
    body:
      "You can book doctor consultation by logging in to aiqahealth application using your registered mobile number. Doctor Consultation facility can also be availed by calling on 6262 306 306",
  },
  {
    title: "Can I choose doctor of specific language",
    body:
      "Yes we have multiple language options ( English, Hindi, Punjabi, and Tamil and Kannada) that you can select while trying to book doctor consultation.",
  },
  {
    title: "Can I choose my consultation date and time",
    body:
      "Yes you can choose your consultation time and date according to your availability.",
  },
  {
    title: "When will the doctor call me",
    body: "Doctor will call you at your selected time and date.",
  },
  {
    title: "Doctor hasn’t called me even after 30 min ?",
    body:
      "We take utmost care that each of our customers get consultation on selected time and date. In case you have not received a call, kindly connect to our customer executive on 6262 306 306",
  },
  {
    title: "How can I download/view my prescription ?",
    body: "You can download/view your prescription from aiqahealth application",
  },
];

export const parameterData = [
  {
    labal: "Cardiac screening",
    count: 5,
    highlight: true,
    list:
      "<li>Apo B / Apo A1 Ratio (APO B/A1)</li><li>Apolipoprotein - A1 (APO-A1)</li><li>Apolipoprotein - B (APO-B)</li><li>High sensitivity C-Reactive protein (HS-CRP)</li><li>Lipoprotein (A) [LP(A)]</li>",
  },

  {
    labal: "Complete hemogram",
    count: 24,
    list:
      "<li>Basophils - absolute count</li><li>Eosinophils - absolute count</li><li>Lymphocytes - absolute count</li><li>Monocytes - absolute count</li><li>Neutrophils - absolute count</li><li>Basophils</li><li>Eosinophils</li><li>Hemoglobin</li><li>Immature granulocytes(IG)</li><li>Immature granulocyte percentage(IG%)</li><li>Total leucocytes count (Wbc)</li><li>Lymphocyte percentage</li><li>Mean corpuscular hemoglobin(MCH)</li><li>Mean corp.hemo.conc(MCHC)</li><li>Mean corpuscular volume(MCV)</li><li>Monocytes</li><li>Neutrophils</li><li>Nucleated red blood cells</li><li>Nucleated red blood cells %</li><li>Hematocrit(PCV)</li><li>Platelet count</li><li>Total RBC</li><li>Red cell distribution width (RDW-CV)</li><li>Red cell distribution width - Sd(RDW-SD)</li>",
  },
  {
    labal: "Kidney screening",
    count: 7,
    list:
      "<li>Bun / Sr.creatinine ratio</li><li>Blood urea nitrogen (BUN)</li><li>Calcium</li><li>Creatinine - Serum</li><li>Urea / Sr.creatinine ratio</li><li>Urea (Calculated)</li><li>Uric acid</li>",
  },
  {
    labal: "Lipid profile",
    count: 10,
    list:
      "<li>Total cholesterol</li><li>HDL cholesterol - Direct</li><li>HDL / LDL ratio</li><li>LDL Cholesterol - Direct</li><li>LDL / HDL Ratio</li><li>Non-HDL cholesterol</li><li>TC/ HDL cholesterol ratio</li><li>Trig / HDL ratio</li><li>Triglycerides</li><li>VLDL cholesterol</li>",
  },
  {
    labal: "Liver screening",
    count: 12,
    list:
      "<li>Serum alb/globulin ratio</li><li>Alkaline phosphatase</li><li>Bilirubin -direct</li><li>Bilirubin (Indirect)</li><li>Bilirubin - Total</li><li>Gamma glutamyl transferase (GGT)</li><li>SGOT / SGPT ratio</li><li>Protein - Total</li><li>Albumin - Serum</li><li>Serum globulin</li><li>Aspartate aminotransferase (SGOT)</li><li>Alanine transaminase (SGPT)</li>",
  },
  {
    labal: "Thyroid",
    count: 1,
    list: "<li>T3-T4-TSH</li>",
  },
  {
    labal: "HBA1C",
    count: 1,
    list: "<li>HBA1C</li>",
  },
  {
    labal: "Sugar",
    count: 1,
    list: "<li>Fasting Blood Sugar</li>",
  },
  {
    labal: "Iron",
    count: 1,
    list: "<li>Iron</li>",
  },
  {
    labal: "Uric acid",
    count: 1,
    list: "<li>Uric acid</li>",
  },
  {
    labal: "Cholesterol",
    count: 1,
    list: "<li>TOTAL CHOLESTEROL</li>",
  },
  {
    labal: "Vitamin",
    count: 2,
    highlight: true,
    list: "<li>Vitamin B-12</li><li>25 (OH) Vitamin D (Total)</li>",
  },
  {
    labal: "Urine Routine & Microscopy",
    count: 18,
    list:
      "<li>pH Urine </li><li>Specific gravity </li><li>Urobilinogen </li><li>Colour </li><li>Transparency</li> <li>Albumin </li><li>Sugar</li> <li>Blood</li> <li>Red Blood Cells</li> <li>Pus cells (Leukocytes)</li> <li>Epithelial cells</li> <li>Crystals</li> <li>Cast</li> <li>Bacteria</li> <li>Yeast Cells</li> <li>Nitrate</li> <li>Others - Urine</li> <li>Volume - Urine</li>  ",
  },
];

export const healthywayBasicParameterData = [
  {
    labal: "COMPLETE HEMOGRAM",
    count: 25,
    list:
      "<li>Absolute Basophils Count Blood</li><li>Absolute Eosinophil Count Blood</li><li>Absolute Lymphocyte Count Blood</li><li>Absolute Monocyte Count Blood</li><li>Absolute Neutrophil Count Blood</li><li>ESR Automated</li><li>Hemoglobin Hb</li><li>MCH</li><li>MCHC</li><li>MCV</li><li>MPV Mean Platelet Volume</li><li>PCV Hematocrit</li><li>WBC -Total Count Leucocytes</li><li>RDW (Red Cell Distribution Width)</li><li>Neutrophils</li><li>Eosinophils</li><li>Lymphocytes</li><li>Monocytes</li><li>Basophils</li><li>RDW – CV</li><li>MENTZER INDEX MCV/RCC</li><li>Red Blood Cells – Blood</li><li>RDWI</li><li>GK Index</li>",
  },
  {
    labal: "5. KIDNEY FUNCTION TEST",
    count: 10,
    list:
      "<li>BUN Urea Nitrogen Serum</li><li>Calcium Total</li><li>Calcium Total Serum</li><li>Chlorides Serum</li><li>Creatinine Serum</li><li>Phosphorus – Inorganic Serum</li> <li>Sodium Serum</li><li>Urea Serum</li><li>BUN /Creatinine Ratio</li><li>Urea / Creatinine Ratio</li>",
  },
  {
    labal: "LIVER FUNCTION TEST",
    count: 12,
    list:
      "<li>Albumin Serum</li><li>Alkaline Phosphatase Serum</li><li>Bilirubin Direct Serum</li><li>Bilirubin Total Serum</li><li>GGTP (Gamma GT)</li><li>Protein Serum</li><li>SGOT /AST</li><li>SGPT /ALT</li><li>Bilirubin -Indirect Serum</li><li>Globulin</li><li>A/G Ratio</li><li>SGOT /SGPT Ratio</li>",
  },
  {
    labal: "THYROID",
    count: 3,
    list:
      "<li>T3 total Tri Iodothyronine</li><li>T4 Total Thyroxine</li><li>TSH Ultra – sensitive</li>",
  },
  {
    labal: "Blood Sugar",
    count: 1,
    list: "<li>Blood Sugar</li>",
  },
  {
    labal: "Urine Routine & Microscopy",
    count: 18,
    list:
      "<li>pH Urine</li><li>Specific gravity</li><li>Urobilinogen</li><li>Colour</li><li>Transparency</li><li>Albumin</li><li>Sugar</li><li>Blood</li><li>Red Blood Cells</li><li>Pus cells (Leukocytes)</li><li>Epithelial cells</li><li>Crystals</li><li>Cast</li><li>Bacteria</li><li>Yeast Cells</li><li>Nitrate</li><li>Others - Urine</li><li>Volume - Urine</li>",
  },
];

export const healthywayAdvancedParameterData = [
  {
    labal: "LIPID",
    count: 9,
    list:
      "<li>Cholesterol -total serum</li><li>HDL Cholesterol Direct</li><li>LDL Cholesterol - Direct</li><li>Triglycerides Serum</li><li>Non -HDL Cholesterol Direct Serum</li><li>VLDL</li><li>LDL/HDL RATIO</li><li>CHOL /HDL RATIO</li><li>HDL /LDL Cholesterol Ratio</li>",
  },
  {
    labal: "LIVER FUNCTION TEST",
    count: 12,
    list:
      "<li>Albumin Serum</li><li>Alkaline Phosphatase Serum</li><li>Bilirubin Direct Serum</li><li>Bilirubin Total Serum</li><li>GGTP (Gamma GT)</li><li>Protein Serum</li><li>SGOT /AST</li><li>SGPT /ALT</li><li>Bilirubin -Indirect Serum</li><li>Globulin</li><li>A/G Ratio</li><li>SGOT /SGPT Ratio</li>",
  },
  {
    labal: "COMPLETE HEMOGRAM",
    count: 25,
    list:
      "<li>Absolute Basophils Count Blood</li><li>Absolute Eosinophil Count Blood</li><li>Absolute Lymphocyte Count Blood</li><li>Absolute Monocyte Count Blood</li><li>Absolute Neutrophil Count Blood</li><li>ESR Automated</li><li>Hemoglobin Hb</li><li>MCH</li><li>MCHC</li><li>MCV</li><li>MPV Mean Platelet Volume</li><li>PCV Hematocrit</li><li>WBC -Total Count Leucocytes</li><li>RDW (Red Cell Distribution Width)</li><li>Neutrophils</li><li>Eosinophils</li><li>Lymphocytes</li><li>Monocytes</li><li>Basophils</li><li>RDW – CV</li><li>MENTZER INDEX MCV/RCC</li><li>Red Blood Cells – Blood</li><li>RDWI</li><li>GK Index</li>",
  },
  {
    labal: "THYROID",
    count: 3,
    list:
      "<li>T3 total Tri Iodothyronine</li><li>T4 Total Thyroxine</li><li>TSH Ultra – sensitive</li>",
  },
  {
    labal: "KIDNEY Screening",
    count: 10,
    list:
      "<li>BUN Urea Nitrogen Serum</li><li>Calcium Total</li><li>Calcium Total Serum</li><li>Chlorides Serum</li><li>Creatinine Serum</li><li>Phosphorus – Inorganic Serum</li><li>Sodium Serum</li><li>Urea Serum</li><li>BUN /Creatinine Ratio</li><li>Urea / Creatinine Ratio</li> ",
  },
  {
    labal: "Diabetes",
    count: 2,
    list: "<li>Glycated Hemoglobin </li><li>(HbA1c)</li>",
  },
  {
    labal: "IRON",
    count: 4,
    list:
      "<li>Iron Serum</li><li>TIBS Serum</li><li>UIBS</li><li>Transferrin Saturation</li>",
  },
  {
    labal: "RA Test Rheumatoid Arthritis Factor Quantitative",
    highlight: true,
    count: 1,
    special: true,
    list: "<li>RA Test Rheumatoid Arthritis Factor Quantitative</li>",
  },
  {
    labal: "Amylase Enzymatic",
    count: 1,
    highlight: true,
    special: true,
    list: "<li>Amylase Enzymatic</li>",
  },
  {
    labal: "Serum CRP (C Reactive Protein) Quantitative",
    special: true,
    count: 1,
    highlight: true,
    list: "<li>Serum CRP (C Reactive Protein) Quantitative</li>",
  },
  {
    labal: "Vitamin B12 Cyanocobalamin",
    special: true,
    count: 1,
    highlight: true,
    list: "<li>Vitamin B12 Cyanocobalamin</li>",
  },
  {
    labal: "Vitamin D Total-25 Hydroxy",
    highlight: true,
    count: 1,
    special: true,
    list: "<li>Vitamin D Total-25 Hydroxy</li>",
  },
  {
    labal: "IgE Total antibody",
    count: 1,
    highlight: true,
    list: "<li>IgE Total antibody</li>",
    special: true,
  },
  {
    labal: "Cardiac Screening",
    count: 1,
    highlight: true,
    list: "<li>Cardiac Screening</li>",
    special: true,
  },
  {
    labal: "Apolipoproteins A1",
    count: 1,
    highlight: true,
    list: "<li>Apolipoproteins A1</li>",
    special: true,
  },
  {
    labal: "Apolipoproteins B",
    count: 1,
    highlight: true,
    list: "<li>Apolipoproteins B</li>",
    special: true,
  },
  {
    labal: "Urine Routine & Microscopy",
    count: 18,
    list:
      "<li>pH Urine</li><li>Specific gravity</li><li>Urobilinogen</li><li>Colour</li><li>Transparency</li><li>Albumin</li><li>Sugar</li><li>Blood</li><li>Red Blood Cells</li><li>Pus cells (Leukocytes)</li><li>Epithelial cells</li><li>Crystals</li><li>Cast</li><li>Bacteria</li><li>Yeast Cells</li><li>Nitrate</li><li>Others - Urine</li><li>Volume - Urine</li>",
  },
];

export const quizQuestionStep1 = {
  questionValue: "Goal",
  question: "What is your health goal",
  title: "Regular blood test helps to detect unwanted risks in time.",
  answer: [
    {
      image: require("./assets/images/step1-icon1.png"),
      answer: "Long term health",
    },
    {
      image: require("./assets/images/step1-icon2.png"),
      answer: "Energy",
    },
    {
      image: require("./assets/images/step1-icon3.png"),
      answer: "Sleep",
      //  ansSupport: "(anxiety or depression)",
    },
    {
      image: require("./assets/images/step1-icon4.png"),
      answer: "Mood",
      ansSupport: "(anxiety or depression)",
    },
    {
      image: require("./assets/images/step1-icon5.png"),
      answer: "Fitness",
    },
    {
      image: require("./assets/images/step1-icon6.png"),
      answer: "Weight",
    },
    {
      image: require("./assets/images/step1-icon7.png"),
      answer: "Immunity",
    },
    {
      answer: "None",
    },
  ],
};

export const quizQuestionStep2 = {
  questionValue: "Diet",
  question: "What is your go-to-diet",
  title: "We suggest tests to check how the food you eat supports your body.",
  answer: [
    {
      image: require("./assets/images/step2-icon1.png"),
      answer: "Vegetarian/Vegan",
    },
    {
      image: require("./assets/images/step2-icon2.png"),
      answer: "Non vegetarian",
    },
    {
      image: require("./assets/images/step2-icon3.png"),
      answer: "Eggetarian",
    },
  ],
};

export const quizQuestionStep3 = {
  questionValue: "HOW WOULD YOU RATE YOUR DIET ON A SCALE OF 1-10",
  question: "How would you rate your diet",
  title: "This helps our coaches give better advice post your test.",
  answer: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
};

export const quizQuestionStep4 = {
  questionValue: "PRE EXISTING SYMPTOMS",
  question: "Are you managing any ailment",
  title: "We recommend tests to monitor your ailments and associated risks.",
  answer: [
    {
      image: require("./assets/images/step4-icon1.png"),
      answer: "high cholesterol",
    },
    {
      image: require("./assets/images/step4-icon2.png"),
      answer: "Heart disease",
    },
    {
      image: require("./assets/images/step4-icon3.png"),
      answer: "High blood pressure",
    },
    {
      image: require("./assets/images/step4-icon4.png"),
      answer: "Thyroid diseases",
    },
    {
      image: require("./assets/images/step4-icon5.png"),
      answer: "Kidney disease",
    },
    {
      image: require("./assets/images/step4-icon6.png"),
      answer: "Liver disease",
    },
    {
      image: require("./assets/images/step4-icon7.png"),
      answer: "Type 1 and Type 2 Diabetes",
    },
    {
      answer: "None",
    },
  ],
};

export const quizQuestionStep5 = {
  questionValue: "FAMILY PRE EXISTING SYMPTOMS",
  question: "Any family history from the following ailments",
  title: "Family history should be monitored for future risks.",
  answer: [
    {
      image: require("./assets/images/step4-icon1.png"),
      answer: "Genetically high cholesterol",
    },
    {
      image: require("./assets/images/step4-icon2.png"),
      answer: "Heart disease",
    },
    {
      image: require("./assets/images/step4-icon7.png"),
      answer: "Type 1 and Type 2 Diabetes",
    },
    {
      answer: "None",
    },
  ],
};

export const basicBenefits = [
  "2 Doctor Consultation",
  "Complete Hemogram + Liver Function Test + Thyroid + Urine Routine/Microscopy + Sugar",
];

export const essentialBenefits = [
  "Unlimited Doctor Consultation",
  "2 Nutritionist Consultation",
  "Basic + (Cardiac screening + Vitamin +  Kidney screening + Lipid profile + 18 Other)",
];

export const advanceBenefits = [
  "Unlimited Doctor Consultation ",
  "4 Nutritionist Consultation",
  "Essential + (Serum CRP + IgE Total antibody + Amylase Enzymatic + 10 Other)",
];
