import React, { useEffect, useState } from "react";
import StepFooter from "../components/buy/StepFooter";
import { getDays, getMonths, getYears, isValidEmail } from "../utils";
import {
  quizQuestionStep1,
  quizQuestionStep2,
  quizQuestionStep3,
  quizQuestionStep4,
  quizQuestionStep5,
} from "../utils";

const Buy = () => {
  const [step, setStep] = useState(1);

  const [bmi, setBmi] = useState("");
  const [user, setUser] = useState({
    name: { value: "", err: false },
    email: { value: "", err: false },
    mobile: { value: "", err: false },
    gender: "",
    height: "",
    weight: "",
    dob: {
      dd: "",
      mm: "",
      yy: "",
    },
    step1: [],
    step2: [],
    step3: 0,
    step4: [],
    step5: [],
  });

  useEffect(() => {
    if (user.height === "" || user.weight === "") return;
    calculateBmi();
  }, [user]);

  const calculateBmi = () => {
    const bmi = String(user.weight / Math.pow(user.height / 100, 2)).slice(
      0,
      5
    );
    setBmi(bmi);
  };

  return (
    <>
      {step === 1 && (
        <div className="stepWrapper">
          <div className="stepWrapperBox">
            <div className="stepHeader">
              <p className="stepText">Step 1/5</p>
              <h1>{quizQuestionStep1.question} </h1>
              <p>{quizQuestionStep1.title}</p>
            </div>
            <div className="stepBody">
              <div className="row">
                {quizQuestionStep1.answer.map((item, index) => {
                  return (
                    <div key={index} className="col-4 col-md-3">
                      <div
                        onClick={() => {
                          if (item.answer === "None") {
                            setUser({
                              ...user,
                              step1: ["None"],
                            });
                          } else if (
                            user.step1.includes(
                              item.ansSupport
                                ? `${item.answer}${item.ansSupport}`
                                : item.answer
                            )
                          ) {
                            setUser({
                              ...user,
                              step1: user.step1.filter((i) => {
                                if (item.ansSupport) {
                                  return (
                                    i != `${item.answer}${item.ansSupport}`
                                  );
                                } else {
                                  return i !== item.answer;
                                }
                              }),
                            });
                          } else {
                            if (user.step1.includes("None")) {
                              const index = user.step1.indexOf("None");
                              setUser({
                                ...user,
                                step1: user.step1.splice(index, 1),
                              });
                            }
                            setUser({
                              ...user,
                              step1: user.step1.concat(
                                item.ansSupport
                                  ? `${item.answer}${item.ansSupport}`
                                  : item.answer
                              ),
                            });
                          }
                        }}
                        className={
                          user.step1.includes(
                            item.ansSupport
                              ? `${item.answer}${item.ansSupport}`
                              : item.answer
                          )
                            ? "stepBodyCard active"
                            : "stepBodyCard"
                        }
                      >
                        <div className="image">
                          {item.image && <img src={item.image} alt="" />}
                        </div>
                        <p>
                          {item.answer} <br />
                          {item.ansSupport && (
                            <span className="fs-11">{item.ansSupport}</span>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <StepFooter
              user={user}
              setUser={setUser}
              step={step}
              setStep={setStep}
              bmi={bmi}
            />
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="stepWrapper">
          <div className="stepWrapperBox">
            <div className="stepHeader">
              <p className="stepText">Step 2/5</p>
              <h1>{quizQuestionStep2.question} </h1>
              <p>{quizQuestionStep2.title}</p>
            </div>
            <div className="stepBody">
              <div className="row">
                {quizQuestionStep2.answer.map((item, index) => {
                  return (
                    <div key={index} className="col-4 col-md-3">
                      <div
                        onClick={() => {
                          if (user.step2.includes(item.answer)) {
                            setUser({
                              ...user,
                              step2: user.step2.filter((i) => {
                                return i !== item.answer;
                              }),
                            });
                          } else {
                            setUser({
                              ...user,
                              step2: [item.answer],
                            });
                          }
                        }}
                        className={
                          user.step2.includes(item.answer)
                            ? "stepBodyCard active"
                            : "stepBodyCard"
                        }
                      >
                        <div className="image">
                          <img src={item.image} alt="" />
                        </div>
                        <p>{item.answer}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <StepFooter
              user={user}
              setUser={setUser}
              step={step}
              setStep={setStep}
              bmi={bmi}
            />
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="stepWrapper">
          <div className="stepWrapperBox">
            <div className="stepHeader">
              <p className="stepText">Step 3/5</p>
              <h1>{quizQuestionStep3.question} </h1>
              <p>{quizQuestionStep3.title}</p>
            </div>
            <div className="stepBody">
              <div className="ratingWrapper">
                <div className="ratingScale">
                  {quizQuestionStep3.answer.map((item) => {
                    return (
                      <label>
                        <input
                          checked={user.step3 === item ? true : false}
                          type="radio"
                          name="rating"
                          onChange={() => setUser({ ...user, step3: item })}
                        />
                        <span className={`rating${item}`}>{item}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <StepFooter
              user={user}
              setUser={setUser}
              step={step}
              setStep={setStep}
              bmi={bmi}
            />
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="stepWrapper">
          <div className="stepWrapperBox">
            <div className="stepHeader">
              <p className="stepText">Step 4/5</p>
              <h1>{quizQuestionStep4.question} </h1>
              <p>{quizQuestionStep4.title}</p>
            </div>
            <div className="stepBody">
              <div className="row">
                {quizQuestionStep4.answer.map((item, index) => {
                  return (
                    <div key={index} className="col-4 col-md-3">
                      <div
                        onClick={() => {
                          if (item.answer === "None") {
                            setUser({
                              ...user,
                              step4: ["None"],
                            });
                          } else if (user.step4.includes(item.answer)) {
                            setUser({
                              ...user,
                              step4: user.step4.filter((i) => {
                                return i !== item.answer;
                              }),
                            });
                          } else {
                            if (user.step4.includes("None")) {
                              const index = user.step4.indexOf("None");
                              setUser({
                                ...user,
                                step1: user.step4.splice(index, 1),
                              });
                            }
                            setUser({
                              ...user,
                              step4: user.step4.concat(item.answer),
                            });
                          }
                        }}
                        className={
                          user.step4.includes(item.answer)
                            ? "stepBodyCard active"
                            : "stepBodyCard"
                        }
                      >
                        <div className="image">
                          <img src={item.image} alt="" />
                        </div>
                        <p>{item.answer}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <StepFooter
              user={user}
              setUser={setUser}
              step={step}
              setStep={setStep}
              bmi={bmi}
            />
          </div>
        </div>
      )}
      {step === 5 && (
        <div className="stepWrapper">
          <div className="stepWrapperBox">
            <div className="stepHeader">
              <p className="stepText">Step 5/5</p>
              <h1>{quizQuestionStep5.question} </h1>
              <p>{quizQuestionStep5.title}</p>
            </div>
            <div className="stepBody stepBody5">
              <div className="row">
                {quizQuestionStep5.answer.map((item, index) => {
                  return (
                    <div key={index} className="col-4 col-md-3">
                      <div
                        onClick={() => {
                          if (item.answer === "None") {
                            setUser({
                              ...user,
                              step5: ["None"],
                            });
                          } else if (user.step5.includes(item.answer)) {
                            setUser({
                              ...user,
                              step5: user.step5.filter((i) => {
                                return i !== item.answer;
                              }),
                            });
                          } else {
                            if (user.step5.includes("None")) {
                              const index = user.step5.indexOf("None");
                              setUser({
                                ...user,
                                step5: user.step5.splice(index, 1),
                              });
                            }
                            setUser({
                              ...user,
                              step5: user.step5.concat(item.answer),
                            });
                          }
                        }}
                        className={
                          user.step5.includes(item.answer)
                            ? "stepBodyCard active"
                            : "stepBodyCard"
                        }
                      >
                        <div className="image">
                          <img src={item.image} alt="" />
                        </div>
                        <p>{item.answer}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <StepFooter
              user={user}
              setUser={setUser}
              step={step}
              setStep={setStep}
              bmi={bmi}
            />
          </div>
        </div>
      )}
      {step === 6 && (
        <div className="stepWrapper">
          <div className="stepWrapperBox">
            <div className="stepHeader">
              <p className="stepText mb-3">Step 5/5</p>
            </div>
            <div className="stepBody">
              <div className="formWrapper">
                <form className="row" action="">
                  <div className="col-12 fieldWrapper">
                    <label htmlFor="age" className="form-label">
                      Your Age{" "}
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    </label>
                    <div className="row">
                      <div className="col">
                        <select
                          className="form-select"
                          value={user.dob.dd}
                          onChange={(e) =>
                            setUser({
                              ...user,
                              dob: { ...user.dob, dd: e.target.value },
                            })
                          }
                        >
                          <option selected={true} disabled value="">
                            DD
                          </option>
                          {getDays().map((option, index) => {
                            return (
                              <option value={option} key={index}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col">
                        <select
                          value={user.dob.mm}
                          className="form-select"
                          onChange={(e) =>
                            setUser({
                              ...user,
                              dob: { ...user.dob, mm: e.target.value },
                            })
                          }
                        >
                          <option selected={true} disabled value="">
                            MM
                          </option>
                          {getMonths().map((option, index) => {
                            return (
                              <option value={option.value} key={index}>
                                {option.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col">
                        <select
                          value={user.dob.yy}
                          className="form-select"
                          onChange={(e) =>
                            setUser({
                              ...user,
                              dob: { ...user.dob, yy: e.target.value },
                            })
                          }
                        >
                          <option selected={true} disabled value="">
                            YY
                          </option>
                          {getYears().map((option, index) => {
                            return (
                              <option value={option} key={index}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 fieldWrapper">
                    <label htmlFor="gender" className="form-label">
                      Gender{" "}
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    </label>
                    <div className="genderRadioWrapper">
                      <label>
                        <input
                          checked={user.gender === "male" ? true : false}
                          onChange={() => setUser({ ...user, gender: "male" })}
                          type="radio"
                          name="gender"
                        />
                        <span className="genderRadio">Male</span>
                      </label>
                      <label>
                        <input
                          checked={user.gender === "female" ? true : false}
                          onChange={() =>
                            setUser({ ...user, gender: "female" })
                          }
                          type="radio"
                          name="gender"
                        />
                        <span className="genderRadio">Female</span>
                      </label>
                    </div>
                  </div>
                  <div className="row fieldWrapper pe-0">
                    <div className="col-6 col-md-4">
                      <label htmlFor="inputHeight" className="form-label">
                        Height (cm){" "}
                        <span style={{ color: "red", fontSize: 16 }}>*</span>
                      </label>
                      <input
                        type="text"
                        value={user.height}
                        id="inputHeight"
                        className="form-control height"
                        placeholder="Your Height"
                        onChange={(e) => {
                          const val = e.target.value;
                          if (isNaN(val) || Number(val) > 210) return;
                          setUser({ ...user, height: val });
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-4 pe-0">
                      <label htmlFor="inputWeight" className="form-label ">
                        Weight (kg){" "}
                        <span style={{ color: "red", fontSize: 16 }}>*</span>
                      </label>
                      <input
                        type="text"
                        value={user.weight}
                        id="inputWeight"
                        className="form-control weight"
                        placeholder="Your Weight"
                        onChange={(e) => {
                          const val = e.target.value;
                          if (isNaN(val) || Number(val) > 120) return;
                          setUser({ ...user, weight: val });
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-4" />
                  </div>
                  <div className="row fieldWrapper">
                    <div className="col-md-4">
                      <label htmlFor="inputBmi" className="form-label">
                        BMI
                      </label>
                      <input
                        type="text"
                        id="inputBmi"
                        className="form-control bmi"
                        placeholder="Your BMI"
                        readOnly
                        value={bmi}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <StepFooter
              user={user}
              setUser={setUser}
              step={step}
              setStep={setStep}
              bmi={bmi}
            />
          </div>
        </div>
      )}
      {step === 7 && (
        <div className="stepWrapper step7Wrapper">
          <div className="stepWrapperBox p-0">
            <div className="stepHeader">
              <img src={require("../assets/images/step7-bg.png")} alt="" />
            </div>
            <div className="stepBody">
              <p>Step 5/5</p>
              <h2>Last step, what's your email?</h2>
              <div className="step7FormWrapper">
                <form className="row" action="">
                  <div className="col-md-6 inputField">
                    <label htmlFor="inputName" className="form-label">
                      Name <span style={{ color: "red", fontSize: 16 }}>*</span>
                    </label>
                    <input
                      value={user.name.value}
                      type="text"
                      className="form-control"
                      id="inputName"
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val.length === 0)
                          setUser({
                            ...user,
                            name: { value: val, err: true },
                          });
                        else
                          setUser({
                            ...user,
                            name: { value: val, err: false },
                          });
                      }}
                    />
                    {user.name.err && (
                      <small className="text-danger">
                        Please enter a name.
                      </small>
                    )}
                  </div>
                  <div className="col-md-6 inputField">
                    <label htmlFor="inputEmail" className="form-label">
                      Email{" "}
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    </label>
                    <input
                      value={user.email.value}
                      type="email"
                      className="form-control"
                      id="inputEmail"
                      onChange={(e) => {
                        const val = e.target.value;
                        if (isValidEmail(val))
                          setUser({
                            ...user,
                            email: { value: val, err: false },
                          });
                        else
                          setUser({
                            ...user,
                            email: { value: val, err: true },
                          });
                      }}
                    />
                    {user.email.err && (
                      <small className="text-danger">
                        Please enter a valid email.
                      </small>
                    )}
                  </div>
                  <div className="col-md-6 inputField">
                    <label htmlFor="inputMobile" className="form-label">
                      Mobile Number{" "}
                      <span style={{ color: "red", fontSize: 16 }}>*</span>
                    </label>
                    <input
                      value={user.mobile.value}
                      type="tel"
                      className="form-control"
                      id="inputMobile"
                      onChange={(e) => {
                        const val = e.target.value;
                        if (isNaN(val) || val.length > 10) return;
                        if (val.length === 10)
                          setUser({
                            ...user,
                            mobile: { value: val, err: false },
                          });
                        else
                          setUser({
                            ...user,
                            mobile: { value: val, err: true },
                          });
                      }}
                    />
                    {user.mobile.err && (
                      <small className="text-danger">
                        Please enter a 10 digit mobile number.
                      </small>
                    )}
                  </div>
                </form>
              </div>
              <div className="text d-none d-md-block">
                <p>
                  *Your credentials help us to keep you brewing with the best
                  discounts and seasonal promotions.
                </p>
              </div>
            </div>
            <StepFooter
              user={user}
              setUser={setUser}
              step={step}
              setStep={setStep}
              bmi={bmi}
            />
            <div className="textMobile d-block d-md-none">
              <p>
                *Your credentials help us to keep you brewing with the best
                discounts and seasonal promotions.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Buy;
