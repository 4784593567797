import React from "react";
import { useNavigate } from "react-router-dom";
import {
  quizQuestionStep1,
  quizQuestionStep2,
  quizQuestionStep3,
  quizQuestionStep4,
  quizQuestionStep5,
} from "../../utils";
import { quizService } from "../../functions";
import moment from "moment/moment";
import { toast } from "react-toastify";

const StepFooter = (props) => {
  const navigate = useNavigate();
  const { user, setUser, step, setStep, bmi } = props;

  const handleBack = () => {
    if (step === 1) {
      setUser({
        name: { value: "", err: false },
        email: { value: "", err: false },
        mobile: { value: "", err: false },
        gender: "",
        height: "",
        weight: "",
        dob: {
          dd: "",
          mm: "",
          yy: "",
        },
        step1: [],
        step2: [],
        step3: 0,
        step4: [],
        step5: [],
      });
      navigate("/");
      return;
    }
    setStep(step - 1);
  };

  const handleToest = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleNext = () => {
    if (step === 1 && user.step1.length === 0) {
      handleToest("Please select atleast one");
      return;
    } else if (step === 2 && user.step2.length === 0) {
      handleToest("Please select atleast one");
      return;
    } else if (step === 3 && user.step3 === 0) {
      handleToest("Please rate your diet");
      return;
    } else if (step === 4 && user.step4.length === 0) {
      handleToest("Please select atleast one");
      return;
    } else if (step === 5 && user.step5.length === 0) {
      handleToest("Please select atleast one");
      return;
    } else if (
      step === 6 &&
      (user.dob.dd === "" ||
        user.dob.mm === "" ||
        user.dob.yy === "" ||
        user.gender === "" ||
        user.height === "" ||
        user.weight === "")
    ) {
      handleToest("Please fill your all details");
      return;
    } else if (step === 7) {
      if (
        user.name.err ||
        user.email.err ||
        user.mobile.err ||
        user.name.value.length === 0 ||
        user.email.value.length === 0 ||
        user.mobile.value.length === 0
      ) {
        handleToest("Please fill your all details");
        return;
      }

      const years = moment().diff(
        `${user.dob.yy}-${user.dob.mm}-${user.dob.dd}`,
        "years",
        false
      );

      const data = {
        userName: user.name.value,
        dob: `${user.dob.yy}-${user.dob.mm}-${user.dob.dd}`,
        email: user.email.value,
        phone: user.mobile.value,
        questionAnswer: [
          {
            question: quizQuestionStep1.questionValue,
            answers: user.step1,
          },
          {
            question: quizQuestionStep2.questionValue,
            answers: user.step2,
          },
          {
            question: quizQuestionStep3.questionValue,
            answers: [
              user.step3 < "6"
                ? "1-5"
                : user.step3 < "5" && user.step3 > "9"
                ? "6-8"
                : "9-10",
            ],
          },
          {
            question: quizQuestionStep4.questionValue,
            answers: user.step4,
          },
          {
            question: quizQuestionStep5.questionValue,
            answers: user.step5,
          },
          {
            question: "Age",
            answers:
              years < 35
                ? ["<35"]
                : years > 55
                ? ["55 and above"]
                : ["35 to 55"],
          },
          {
            question: "Gender",
            answers: [user.gender],
          },
          {
            question: "BMI",
            answers: [
              bmi < "18.5"
                ? "<18.5"
                : bmi > "18.4" && bmi < "24.6"
                ? "18.5-24.5"
                : bmi > "24" && bmi < "31"
                ? "25-30"
                : ">30",
            ],
          },
        ],
      };
      // navigate("/after-buy", {
      //   state: {
      //     code: 200,
      //     message: "Success",
      //     status: "OK",
      //     data: {
      //       productId: 150,
      //       why: [
      //         {
      //           paramName: "LIVER",
      //           answerList: ["Liver", "Kindeny"],
      //         },
      //         {
      //           paramName: "LIVER",
      //           answerList: ["Liver", "Kindeny"],
      //         },
      //       ],
      //     },
      //     count: null,
      //   },
      // });
      quizService(data).then((res) => {
        navigate("/after-buy", {
          state: { data: res.response },
        });
      });
      return;
    }
    setStep(step + 1);
  };

  return (
    <div className="stepFooter">
      <div className="btnWrapper">
        <div className="backBtn">
          <button onClick={handleBack}>Back</button>
        </div>
        <div className="nextBtn">
          <button onClick={handleNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default StepFooter;
