import React, { useState, useEffect, useRef } from "react";
import PaymentFormCard from "./PaymentFormCard";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getPaymentConfirmation,
  checkout,
  validateCoupon,
  checkoutFormMilestone,
  payMilestone,
} from "../functions";
import { toast } from "react-toastify";
import Card from "./Card";
import Loader from "./Loader";
import CountDownComp from "./CountDownComp";
import { LogEvent } from "../LogEvent";
import SweetAlert from "react-bootstrap-sweetalert";
import confettiIcon from "../assets/images/confetti.png";
import useMobileDetect from "../useMobileDetect";

const PaymentLogin = () => {
  const policyStartDate = new Date().toISOString().slice(0, 10);
  const hostInvoice = process.env.REACT_APP_SERVER_URL_FOR_INVOICE;
  const location = useLocation();
  const navigate = useNavigate();
  const prevUser =
    location.state && location.state.user ? location.state.user : null;
  const product =
    location.state && location.state.product ? location.state.product : null;
  const [currIndex, setcurrIndex] = useState(1);
  const [formIndex, setformIndex] = useState(2);
  const [formDisable, setFormDisable] = useState(false);
  const [couponCode, setCouponCodeCode] = useState(null);
  const [inputCoupon, setInputCouopn] = useState(null);
  const [checkoutResponse, setCheckoutResponse] = useState({});
  const [checkoutBtn, setCheckoutBtn] = useState(false);
  const [paymentLinks, setPaymentLinks] = useState(null);
  const [payButtonEnable, setPayButtonEnable] = useState(false);
  const [finalPaymentLink, setFinalPaymentLink] = useState(0);
  const [modalProduct, setModalProduct] = useState(null);
  const [referApplicable, setReferralApplicable] = useState(true);
  const [refApplied, setRefApplied] = useState(false);
  const [cardEditable, setCardEditable] = useState(true);
  const [finalMessage, setFinalMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [intervalTime, setIntervalTime] = useState(null);
  const [windowREF, setWindowREF] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const [checkLoading, setCheckLoading] = useState(false);
  const [user, setUser] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [coupon, setCoupon] = useState(false);

  const pincode = localStorage.getItem("pincode");

  const visitorId = localStorage.getItem("visitorId");

  const onChange = (e) => {
    if (e.target.value.length < 25) setInputCouopn(e.target.value);
  };
  let windowRef = useRef();

  const setPaymentChecker = async (payLink, applicationId) => {
    LogEvent("Pay", "pay-btn");
    // if (!finalPaymentLink) {
    //   toast.error("please select atleast one payment method");
    //   return;
    // } else {
    setLoading(true);
    windowRef.current = window.open(
      payLink?.razorPayUrl ? payLink?.razorPayUrl : paymentLinks?.razorPayUrl
    );
    //   finalPaymentLink === 1
    //     ? paymentLinks.razorPayUrl
    //     : finalPaymentLink === 2
    //     ? paymentLinks.paytmUrl
    //     : ""
    // );
    setWindowREF(windowRef.current);
    let interval = setInterval(async () => {
      if (windowRef.current.closed === true) {
        clearInterval(interval);
        setLoading(false);
        setIntervalTime(null);
      }

      let result = null;

      result = await getPaymentConfirmation(
        applicationId
          ? applicationId
          : checkoutResponse?.memberResponse?.applicationId
      );
      if (result && result.success) {
        // pay milestone
        payMilestone(visitorId);
        setcurrIndex(3);
        setformIndex(4);
        if (windowRef.current && windowRef.current.closed === false) {
          windowRef.current.close();
          windowRef.current = null;
          setWindowREF(null);
        }

        setFinalMessage(
          "Congratulations ! Please click below to download invoice"
        );
        clearInterval(interval);
        setLoading(false);
        setIntervalTime(null);
        window.history.replaceState({}, "");
      }
      if (result?.json?.data?.data?.application_not_found) {
        windowRef.current.close();
        windowRef.current = null;
        setWindowREF(null);
        clearInterval(interval);
        setLoading(false);
        setIntervalTime(null);
        // toast.error(
        //   "An existing session has been detected using the same login credentials!"
        // );
        navigate("/login", {
          state: {
            product: product,
            user: null,
            session: true,
            sessionMessage:
              "An existing session has been detected using the same number!",
          },
        });
      }
    }, 2000);
    setIntervalTime(interval);
  };

  const checkoutRef = useRef();

  const applyReferalCode = async () => {
    if (!inputCoupon) {
      toast.info("Please enter coupon code");
      return;
    }
    const referralResponse = await validateCoupon(
      product.productId,
      user.mobile,
      inputCoupon
    );
    if (referralResponse.success === true) {
      setDiscountedAmount(referralResponse.response);
      toast.success("Coupon applied successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
      if (referralResponse.response.discountedAmount == 0) {
        setFormDetails({
          ...formDetails,
          paymentMode: "NEFT",
          couponCode: inputCoupon,
          discountType: referralResponse?.response?.discountType,
        });
      } else {
        setFormDetails({
          ...formDetails,
          couponCode: inputCoupon,
          discountType: referralResponse?.response?.discountType,
        });
      }
      setRefApplied(true);
    } else if (!referralResponse.success) {
      setRefApplied(false);
      setFormDetails({
        ...formDetails,
        couponCode: couponCode,
      });
      toast.error(referralResponse?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    if (formIndex === 3) {
      setCoupon(true);
    }
  }, [formIndex]);

  useEffect(() => {
    if (prevUser === null || !product || !pincode) navigate("/");
    else {
      window.scrollTo(0, 0);
      setUser({
        name: "",
        email: "",
        mobile: prevUser.mobile_number,
        gender: "",
        dob: "",
        policyStartDate: policyStartDate,
        govtIDNumber: "",
        govtIDType: "",
      });
      setFormDetails({
        pincode: "",
        couponCode: localStorage.getItem("coupon_code"),
        productId: product.productId,
        productType: product.productType || "RETAIL",
      });
      setCouponCodeCode(localStorage.getItem("coupon_code"));
    }
  }, [prevUser, product]);

  // const paymentRadioChanged = (e) => {
  //   if (e.target.value.localeCompare("R") === 0) setFinalPaymentLink(1);
  //   else if (e.target.value.localeCompare("P") === 0) setFinalPaymentLink(2);
  // };

  async function pay() {
    LogEvent("check-out", "check-out-btn");
    const data = {
      productId: formDetails.productId,
      productType: formDetails.productType,
      pincode: formDetails.pincode,
      couponCode: formDetails.couponCode,
      paymentMode: formDetails.paymentMode,
      discountType: formDetails.discountType,
      requestedBookingDate: user.requestedBookingDate,
      requestedBookingSlot: user.requestedBookingSlot,
      customerAddress: user.customerAddress,
      state: user.state,
      city: user.city,
      customerAddress2: user.customerAddress2,
      landmark: user.landmark,
      user: user,
      initiateMobileNumber: user.initiateMobileNumber,
      isLeader: localStorage.getItem("isLeader"),
      latitude: formDetails.latitude,
      longitude: formDetails.longitude,
      geoFormattedAddress: formDetails.geoFormattedAddress,
      members: [
        {
          height: null,
          weight: null,
          relation: "SELF",
          name: user.name,
          dob: user.dob,
          gender: user.gender,
          answers: [{}],
        },
      ],
    };

    setCheckLoading(true);
    const res = await checkout(data);
    if (
      res.success &&
      res.response.paymentResponse &&
      res.response.paymentResponse.paytmUrl
    ) {
      setCheckoutBtn(false);
      setReferralApplicable(false);
      setCheckoutResponse(res.response);
      setPaymentLinks(res.response.paymentResponse);
      setPayButtonEnable(true);
      setCheckLoading(false);
      setPaymentChecker(
        res?.response?.paymentResponse,
        res?.response?.memberResponse?.applicationId
      );

      checkoutFormMilestone({
        visitorId: visitorId,
        applicationId: res?.response?.memberResponse?.applicationId,
      });
    } else if (res.success && res.response?.policyResponse?.invoiceUrl) {
      setcurrIndex(3);
      setformIndex(4);
      setCheckoutBtn(false);
      setReferralApplicable(false);
      setCheckoutResponse(res.response);
      setCheckLoading(false);
      if (windowRef.current && windowRef.current.closed === false) {
        windowRef.current.close();
        windowRef.current = null;
        setWindowREF(null);
      }
      payMilestone(visitorId);
      setFinalMessage(
        "Congratulations ! Please click below to download invoice"
      );
      checkoutFormMilestone({
        visitorId: visitorId,
        applicationId: res?.response?.memberResponse?.applicationId,
      });

      setLoading(false);
      setIntervalTime(null);
      window.history.replaceState({}, "");
    } else {
      setFormDisable(false);
      setReferralApplicable(true);
      setCheckLoading(false);
    }
  }

  const handleLoading = () => {
    setLoading(false);
    clearInterval(intervalTime);
    windowRef?.current?.close();
    setWindowREF(null);
  };

  const removeAppliedCoupon = () => {
    setFormDetails({
      ...formDetails,
      couponCode: couponCode,
      paymentMode: null,
    });
    setRefApplied(false);
    setDiscountedAmount(null);
    setInputCouopn(null);
  };

  const handleCountDown = () => {
    handleLoading();
    window.history.replaceState({}, "");
    navigate("/login", {
      state: {
        product: product,
        user: null,
        session: true,
        sessionMessage: "Your payment session is expired",
      },
    });
  };

  useEffect(() => {
    return () => {
      window.onpopstate = () => {
        localStorage.removeItem("buyNowSession");
      };
      localStorage.removeItem("buyNowSession");
    };
  }, [location]);

  const { isMobile } = useMobileDetect();

  return (
    <section className="login-page">
      {checkLoading && <Loader />}
      {loading && <Loader setLoading={handleLoading} />}
      {product && prevUser && (
        <div className="container">
          {/* <div className="login-page-container"> */}
          {formIndex < 5 && (
            <div className="login-page-container">
              <div className="row">
                {/* <div className="col-12 d-block d-lg-none d-xl-none d-xxl-none">
                            <div className="plan-box plan-box-2 " style={{ borderRadius: "10px", paddingBottom: "35px", minHeight: "100%" }}>
                                <h2 className="text-white">
                                    {product.productName}
                                </h2>
                                <p className="text-white text-center">
                                    <span className="fw-6">₹{product.basePrice} </span><span> Yearly*</span>
                                </p>
                            </div>
                        </div> */}
                {/* <div className="col-12 mt-5 d-block d-lg-none d-xl-none d-xxl-none payment-form-card-mobile">
                            <PaymentFormCard user={user} formDisable={formDisable} setFormDisable={setFormDisable} setCheckoutBtn={setCheckoutBtn} checkoutBtn={checkoutBtn} setUser={setUser} formDetails={formDetails} setFormDetails={setFormDetails} prevUser={prevUser} setPayButtonEnable={setPayButtonEnable} cardEditable={cardEditable} />
                        </div> */}
                <div className="col-12 col-lg-8 col-xl-8 col-xxl-8">
                  <div className="login-form-parent">
                    <h1 id="checkoutButton">
                      {" "}
                      Welcome to <span className="text-red">aiqahealth</span>
                    </h1>
                    <p>
                      We are now India's most affordable health service provider
                    </p>
                    <div className="login-form-content" ref={checkoutRef}>
                      <div className="form-progress-bar">
                        <ul>
                          <div
                            className="progress"
                            style={{
                              width: `calc(130%/4 *${currIndex})`,
                            }}
                          />
                          {["login", "otp", "info", "invoice"].map(
                            (item, index) => (
                              <li
                                key={item}
                                data-title={item}
                                className={`${
                                  currIndex >= index ? "active" : ""
                                }`}
                              >
                                {/* {index + 1} */}
                              </li>
                            )
                          )}
                        </ul>
                      </div>

                      {/* Pages */}
                      <div className="login-form-content-details">
                        {formIndex === 2 && (
                          <PaymentFormCard
                            user={user}
                            formDisable={formDisable}
                            setFormDisable={setFormDisable}
                            setCheckoutBtn={setCheckoutBtn}
                            checkoutBtn={checkoutBtn}
                            setUser={setUser}
                            formDetails={formDetails}
                            setFormDetails={setFormDetails}
                            prevUser={prevUser}
                            cardEditable={cardEditable}
                            setformIndex={setformIndex}
                            setcurrIndex={setcurrIndex}
                            checkoutRef={checkoutRef}
                          />
                        )}

                        {formIndex === 3 && (
                          <div>
                            <h4 className="text-center">Make Payment</h4>
                            <div className="paymentTableWrapper">
                              {/* <TsParticles /> */}

                              <table className="table w-100 table-borderless">
                                <p
                                  style={{
                                    width: "100%",
                                    fontWeight: 600,
                                    fontSize: 22,
                                    marginTop: 3,
                                  }}
                                >
                                  {product.productName}
                                </p>
                                {/* <thead align="start">
                                  <tr>
                                    <th valign="top">Plan</th>
                                    <th
                                      valign="top"
                                      className="text-end"
                                      style={{ fontWeight: "500" }}
                                    >
                                      <strong>{product.productName}</strong>
                                    </th>
                                  </tr>
                                </thead> */}
                                <tbody>
                                  <tr>
                                    <td>Price</td>
                                    <td
                                      className="text-end"
                                      style={{ fontWeight: "700" }}
                                    >
                                      ₹{product.basePrice}/-
                                    </td>
                                  </tr>
                                  <tr className="d-table-row">
                                    <td>Coupon Code</td>
                                    <td className="text-end text-red">
                                      <span className="fs-16">
                                        <b>{couponCode}</b>
                                      </span>
                                    </td>
                                  </tr>
                                  {product?.basePrice &&
                                    product?.discountedAmount && (
                                      <tr className="d-table-row">
                                        <td>Discount</td>
                                        <td className="text-end">
                                          <b>
                                            - ₹
                                            {product?.basePrice -
                                              product?.discountedAmount}
                                            /-
                                          </b>
                                        </td>
                                      </tr>
                                    )}

                                  {/* {product?.basePrice && discountedAmount && (
                                    <tr className="d-table-row">
                                      <td>Amount After Discount</td>
                                      <td className="text-end">
                                        <b>
                                          ₹{product?.discountedAmount}
                                          /-
                                        </b>
                                      </td>
                                    </tr>
                                  )} */}
                                  {refApplied && (
                                    <tr className="d-table-row">
                                      <td>Promo Applied</td>
                                      <td className="text-end text-red">
                                        <span className="fs-16">
                                          <b>{inputCoupon}</b>
                                          {refApplied && !payButtonEnable && (
                                            <span
                                              style={{ marginLeft: "5px" }}
                                              onClick={removeAppliedCoupon}
                                            >
                                              <i
                                                className="fa fa-trash red-color"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </span>
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                  {product?.basePrice && discountedAmount && (
                                    <tr className="d-table-row">
                                      <td>Promo Discount</td>
                                      <td className="text-end">
                                        <b>
                                          - ₹
                                          {product?.discountedAmount -
                                            discountedAmount?.discountedAmount}
                                          /-
                                        </b>
                                      </td>
                                    </tr>
                                  )}
                                  <tr className="d-table-row">
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        fontSize: 17,
                                      }}
                                    >
                                      Net Payable Amount
                                    </td>
                                    <td className="text-end text-red">
                                      <b style={{ fontSize: 17 }}>
                                        ₹
                                        {discountedAmount
                                          ? discountedAmount.discountedAmount
                                          : product?.discountedAmount}
                                        /-
                                      </b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>
                                      <span
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                          fontStyle: "italic",
                                        }}
                                      >
                                        Includes free sample collection at home
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              {!refApplied && !payButtonEnable && (
                                <div className="refferalWrapper">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="inputRefferal"
                                        onChange={onChange}
                                        value={inputCoupon}
                                        placeholder="Have a promo code?"
                                        onKeyDown={(e) => {
                                          if (
                                            e.key == "Enter" &&
                                            inputCoupon.length > 0
                                          )
                                            applyReferalCode();
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <button
                                        style={{
                                          padding: "8px 0",
                                          width: "100%",
                                        }}
                                        onClick={applyReferalCode}
                                        type="button"
                                        className="btn-btn"
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="paymentWrapper">
                                {paymentLinks && (
                                  <div className="row">
                                    <div
                                      style={{ margin: "20px 0" }}
                                      className="col-md-12"
                                    >
                                      {/* <div className="d-flex justify-content-center align-items-center">
                                        <div
                                          className="form-check ps-0"
                                          style={{ marginRight: "10px" }}
                                        >
                                          <input
                                            onChange={paymentRadioChanged}
                                            className="form-check-input radio-btn"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlinePaymentCheckbox1"
                                            value="R"
                                          />
                                          <label
                                            className="form-check-label radio-btn-label rounded-0"
                                            htmlFor="inlinePaymentCheckbox1"
                                          >
                                            <img
                                              style={{ width: "70%" }}
                                              src={require("../assets/images/razorpay1.png")}
                                              alt=""
                                            />
                                          </label>
                                        </div>
                                        <div
                                          className="form-check ps-2"
                                          style={{
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <input
                                            onChange={paymentRadioChanged}
                                            className="form-check-input radio-btn"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlinePaymentCheckbox2"
                                            value="P"
                                          />
                                          <label
                                            className="form-check-label radio-btn-label rounded-0 paytm-logo"
                                            htmlFor="inlinePaymentCheckbox2"
                                          >
                                            <img
                                              src={require("../assets/images/paytm1.png")}
                                              style={{
                                                cursor: "pointer",
                                                width: "50%",
                                              }}
                                              alt=""
                                            />
                                          </label>
                                        </div>
                                      </div> */}
                                      <div style={{ textAlign: "center" }}>
                                        <CountDownComp
                                          callBack={handleCountDown}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="mt-5">
                                <div className="text-center mt-5 mb-5 final-pay-btns">
                                  {/* <button type='button' className='white-btn'>Back</button>*/}
                                  {payButtonEnable && (
                                    <a
                                      target="_blank"
                                      rel="nofollow noopener noreferrer"
                                    >
                                      <button
                                        onClick={setPaymentChecker}
                                        type="button"
                                        className="red-btn"
                                        id="paybtn"
                                      >
                                        Pay
                                      </button>
                                    </a>
                                  )}
                                  {checkoutBtn && (
                                    <button
                                      onClick={pay}
                                      className="red-btn mt-3"
                                    >
                                      Checkout
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {formIndex >= 4 && (
                          <div className="row">
                            <div className="col-12 m-auto col-lg-9 col-xl-9 col-xxl-9">
                              <div className="login-form-content-details">
                                <div className="paymentSuccessfulWrapper">
                                  <div className="paymentSuccessfulImage">
                                    <img
                                      src={require("../assets/images/payment-successful-icon.png")}
                                      alt=""
                                    />
                                  </div>
                                  <div className="paymentSuccessfulDetails">
                                    <h4 className="m-3 mb-4 p-3">
                                      {finalMessage}
                                    </h4>
                                    {/* <p>Your Order ID is <span>#232342</span></p> */}
                                    <a
                                      href={`${hostInvoice}/${
                                        checkoutResponse?.memberResponse
                                          ?.applicationId
                                      }`}
                                      target="_blank"
                                      rel="nofollow noopener noreferrer"
                                      className="red-btn m-3"
                                      onClick={() =>
                                        LogEvent(
                                          "Download-Invoice",
                                          "download-invoice"
                                        )
                                      }
                                    >
                                      Download Invoice
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4 d-none d-lg-block d-xl-block d-xxl-block">
                                <Card makePaymentBtn={makePaymentBtn} color={1} boxType={3} />
                            </div> */}

                <div className="col-md-4 d-none d-lg-block d-xl-block d-xxl-block">
                  <Card
                    product={product}
                    color={1}
                    boxType={3}
                    setModalProduct={setModalProduct}
                  />
                </div>

                {/* <div className="col-md-4 d-none d-lg-block d-xl-block d-xxl-block">
                            <PaymentFormCard user={user} formDisable={formDisable} setFormDisable={setFormDisable} setCheckoutBtn={setCheckoutBtn} checkoutBtn={checkoutBtn} setUser={setUser} formDetails={formDetails} setFormDetails={setFormDetails} prevUser={prevUser} setPayButtonEnable={setPayButtonEnable} cardEditable={cardEditable} />
                        </div> */}
              </div>
            </div>
          )}
        </div>
      )}
      {/* <!-- Modal --> */}
      {/* <div className="modal fade refferalCodeModal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <button ref={ref} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div style={{ padding: "0px 40px 0px 40px" }} className="modal-body">
                            <div className="refferalWrapper">
                                <div className="row">
                                    <div className="col-md-8">
                                        <input type="text" className="form-control mb-3" id="inputRefferal" onChange={onChange} placeholder="Enter referral code" />
                                    </div>
                                    <div className="col-md-4">
                                        <button style={{ padding: "8px 0", width: "100%" }} onClick={applyReferalCode} type="button" className="red-btn">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* Modal */}
      <div
        className="modal fade quick-view-modal"
        id="plan-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          {modalProduct !== null && (
            <div className="modal-content">
              <div className="modal-header border-bottom-0 mb-2">
                <div className="mx-4 my-3 d-flex">
                  <span className="fs-24 fw-6">
                    {modalProduct.productName} <br />
                    <span className="fs-24 fs-8">
                      ₹{modalProduct.basePrice}/-
                    </span>
                  </span>
                </div>
                <button
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "0px",
                  }}
                  type="button"
                  className="btn-close mt-0 mx-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                {modalProduct.productBenefitDtoList !== null &&
                  modalProduct.productBenefitDtoList.length > 0 && (
                    <ul className="">
                      {modalProduct.productBenefitDtoList.map(
                        (benefit, index) => {
                          return (
                            <li
                              key={index}
                              className="d-flex align-items-start mt-4"
                            >
                              <img
                                className="me-3"
                                src={require("../assets/images/green-tick.png")}
                                alt=""
                              />
                              <span>{benefit.descriptionText}</span>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                {modalProduct.productBenefitDtoList !== null &&
                  modalProduct.productBenefitDtoList.length === 0 && (
                    <div className="">
                      <h5 className="text-center my-5">
                        No Benefits To Display At The Moment!!!
                      </h5>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
      {coupon && (
        <SweetAlert
          success
          onConfirm={() => setCoupon(false)}
          confirmBtnStyle={{ backgroundColor: "green" }}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p
                style={{
                  fontSize: isMobile() ? "16px" : "22px",
                  fontWeight: "bold",
                }}
              >
                <img
                  style={{ height: isMobile() ? "30px" : "50px" }}
                  src={confettiIcon}
                />
                Yay 65% coupon auto applied{" "}
                <img
                  style={{ height: isMobile() ? "30px" : "50px" }}
                  src={confettiIcon}
                />
              </p>
            </div>
            <p>
              You saved{" "}
              {
                <b>
                  ₹{product?.basePrice - product?.discountedAmount}
                  /-
                </b>
              }
            </p>
          </div>
        </SweetAlert>
      )}
    </section>
  );
};
export default PaymentLogin;
