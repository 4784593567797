import React from "react";
import { useNavigate } from "react-router-dom";
import { LogEvent } from "../LogEvent";

const FAQs = ({ dataLength }) => {
  const navigate = useNavigate();
  const data = [
    {
      title: "Plan Purchase",
      body: [
        {
          toggle: true,
          title: "How can I buy the plan ?",
          body: "You can buy the plan from healthyway.aiqahealth.com",
        },
        {
          toggle: true,
          title: "How to pay for the plan?",
          body:
            "We have multiple payment options ( UPI, Net Banking, Credit Card and Debit Card) for making the payment.",
        },
      ],
    },
    {
      title: "Plan Benefits",
      body: [
        {
          toggle: true,
          title: "Benefits of basic",
          body:
            "2 Doctor Consults, and COMPLETE HEMOGRAM, LIVER FUNCTION,  THYROID PROFILE -TOTAL (T3 T4 & TSH ULTRA -SENSITIVE), Blood Sugar, Urine Routine & Microscopy test.",
        },
        {
          toggle: true,
          title: "Benefits of essential",
          body:
            "Unlimited Doctor Consults, 2 Nutritionist Consult, and Liver screening, Cholesterol, Iron, Kidney screening, Thyroid, Cardiac screening, Complete Hemogram, Uric acid, Lipid profile, Vitamin B12 & D, HBA1C, Sugar, Urine Routine & Microscopy test. ",
        },
        {
          toggle: true,
          title: "Benefits of advanced",
          body:
            "Unlimited Doctor Consults, 1 Nutritionist Consult/ Qtr, and LIPID PROFILE, LIVER FUNCTION TEST, COMPLETE HEMOGRAM, THYROID PROFILE -TOTAL (T3 T4 & TSH ULTRA -SENSITIVE), KIDNEY FUNCTION, HbA1c, IRON STUDIES, RA Test Rheumatoid Arthritis Factor Quantitative, Amylase Enzymatic, Serum CRP (C Reactive Protein) Quantitative, Vitamin B12 Cyanocobalamin, Vitamin D Total-25 Hydroxy, IgE Total antibody, Urine Routine & Microscopy test.",
        },
      ],
    },
    {
      title: "Plan Booking",
      body: [
        {
          toggle: true,
          title: "How can I reschedule my lab test ?",
          body:
            "Kindly connect with our customer care executive at 6262 306 306",
        },
        {
          toggle: true,
          title: "How can I cancel my existing lab test ?",
          body:
            "Kindly connect with our customer care executive at 6262 306 306",
        },
      ],
    },
    {
      title: "Sample Pick up",
      body: [
        {
          toggle: true,
          title: "How to prepare for my lab test?",
          bodyType: "ul",
          body: [
            "You must fast overnight for 12 hours before the appointment. Only water can be consumed.",
            " Avoid smoking or consuming alcohol for a minimum of 24 hours before the Health Checkup.",
            " Women should not undergo lab tests at the time of menstruation.",
          ],
        },

        {
          toggle: true,
          title:
            "Who will come for my sample pickup ? How do I connect with a Phlebotomist ?",
          body:
            "Phlebotomist will be assigned to you 2-3 hours before test time. Details of the same would be shared to the customer via text message and email.",
        },
        {
          toggle: true,
          title: "How do I make sure my sample pick up is safe and hygienic ?",
          body:
            "Our foremost priority is to ensure accurate, high quality reports.. All our phlebotomists are qualified in medical lab technology (DMLT) and are highly skilled at their jobs. All machines and equipment in our partner labs use the most advanced technology. They are checked and calibrated on a daily basis to ensure correct readings.",
        },
        {
          toggle: true,
          title:
            "The Phlebotomist did not come for a sample collection, what should I do ?",
          body:
            "In case Phlebotomist did not come for sample collection, Kindly connect with our customer executive at 6262 306 306",
        },
      ],
    },
    {
      title: "Reports",
      body: [
        {
          toggle: true,
          title: "How long does it usually take to get my lab reports ?",
          body:
            "We try to provide your reports at the earliest possible. This usually takes around 24-48 hours.",
        },
        {
          toggle: true,
          title: "How will I receive my reports ?",
          body:
            "You will receive your reports on your registered email id and mobile number. The same can also be downloaded from aiqahealth application",
        },
      ],
    },
    {
      title: "Doctor Consultation",
      body: [
        {
          toggle: true,
          title: "How can I book a doctor consultation?",
          body:
            "You can book doctor consultation by logging in to aiqahealth application using your registered mobile number. Doctor Consultation facility can also be availed by calling on 6262 306 306",
        },
        {
          toggle: true,
          title: "Can I choose doctor of specific language",
          body:
            "Yes we have multiple language options ( English, Hindi, Punjabi, and Tamil and Kannada) that you can select while trying to book doctor consultation.",
        },
        {
          toggle: true,
          title: "Can I choose my consultation date and time",
          body:
            "Yes you can choose your consultation time and date according to your availability.",
        },
        {
          toggle: true,
          title: "When will the doctor call me",
          body: "Doctor will call you at your selected time and date.",
        },
        {
          toggle: true,
          title: "Doctor hasn’t called me even after 30 min ?",
          body:
            "We take utmost care that each of our customers get consultation on selected time and date. In case you have not received a call, kindly connect to our customer executive on 6262 306 306",
        },
        {
          toggle: true,
          title: "How can I download/view my prescription ?",
          body:
            "You can download/view your prescription from aiqahealth application",
        },
      ],
    },
  ];

  return (
    <section className="callBackSection">
      <div className="container text-center">
        <h1 className="mb-5">FAQs</h1>
        <div className="accordion faqs-item" id="accordionExample">
          {data.map((item, index) => {
            return dataLength <= index ? (
              ""
            ) : (
              <div
                key={index}
                id="accordionExampleOne"
                className="accordion-item acco-item-one mb-3"
              >
                <h2 className="accordion-header" id={index}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                  >
                    <span style={{ color: "#e53c35", fontWeight: 500 }}>
                      {item.title}
                    </span>
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={index}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body ">
                    {item.body.map((elem, Index) => {
                      return (
                        <div
                          key={Index}
                          className="accordion"
                          id="accordionExampleTwo"
                          onClick={() =>
                            LogEvent(elem.title, `${elem.title}-btn`)
                          }
                        >
                          <div className="accordion-item mb-2 w-100">
                            <h2 className="accordion-header" id={Index}>
                              <button
                                className="accordion-button collapsed "
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${Index}a${index}a`}
                                aria-expanded="false"
                                aria-controls={`collapse${Index}a${index}a`}
                              >
                                <span
                                  style={{
                                    color: "#e53c35",
                                  }}
                                >
                                  {elem.title}
                                </span>
                              </button>
                            </h2>
                            {elem.toggle && (
                              <div
                                id={`collapse${Index}a${index}a`}
                                className="accordion-collapse collapse"
                                aria-labelledby={Index}
                                data-bs-parent="#accordionExampletwo"
                              >
                                <div className="accordion-body text-start">
                                  <p>
                                    {elem.bodyType ? (
                                      <ul className="list-group">
                                        {elem.body.map((item, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className="list-group-item"
                                            >
                                              {item}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    ) : (
                                      elem.body
                                    )}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FAQs;
