import React, { useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { LogEvent } from "../../LogEvent";
import { advanceBenefits, basicBenefits, essentialBenefits } from "../../utils";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const PlanSection = ({
  onBuyNow,
  products,
  pin,
  setPinStatus,
  setProductCode,
  setButtonType,
  ...props
}) => {
  return (
    <section id="planSection">
      <div className="planSectionWrapper">
        <div className="container">
          <div className="planHeader">
            <h2>
              Live the healthy way{" "}
              <span className="fw-7">with our curated plans</span>{" "}
            </h2>
            <p>
              Our health coach 🩺 call you after every test to explain the report
              and give customized recommendation.
              {/* <br />
              Call them back 📞 as many times as you wish for a year. */}
            </p>
          </div>
          <div className="planCardWrapper">
            <div className="row" style={{ justifyContent: "center" }}>
              {products?.map((item, index) => {
                return (
                  <div key={index} className="col-md-4 plan-section-responsive">
                    <div className="planCard">
                      <div className="planCardHeader ">
                        <p>{item.productName}</p>
                        {item.productName === "Healthyway Essential" && (
                          <span>Best Seller</span>
                        )}
                      </div>
                      <hr />
                      <div className="planCardBody">
                        <div className="iconBoxWrapper justify-content-between">
                          <div className="iconBox">
                            <div className="parameterIcon">
                              {item.productName === "Healthyway Essential"
                                ? 84
                                : item.productName === "Healthyway Basic"
                                ? 69
                                : 94}
                            </div>
                            {/* <img
                              src={
                                index === 0
                                  ? require("../../assets/images/healthway-icon-1.png")
                                  : require("../../assets/images/100.png")
                              }
                              alt=""
                            /> */}
                            <p>
                              {item.productName === "Healthyway Essential"
                                ? 84
                                : item.productName === "Healthyway Basic"
                                ? 69
                                : 94}{" "}
                              Parameters
                            </p>
                          </div>

                          <div className="iconBox">
                            <div className="parameterIcon">
                              <img
                                src={require("../../assets/images/D-N.png")}
                                alt=""
                              />
                            </div>
                            {item.productName === "Healthyway Basic" ? (
                              <p>Doctor consultation</p>
                            ) : (
                              <p>Doctor/Nutritionist consultation</p>
                            )}
                          </div>

                          {/* <div className="iconBox">
                            <img
                              src={require("../../assets/images/D-N.png")}
                              alt=""
                            />
                            <p>Doctor consultation</p>
                          </div> */}
                        </div>
                        <ul className="listWrapper">
                          {item.productName === "Healthyway Basic"
                            ? basicBenefits.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <img
                                      src={require("../../assets/images/green-check.png")}
                                      alt=""
                                    />
                                    {item}
                                  </li>
                                );
                              })
                            : item.productName === "Healthyway Essential"
                            ? essentialBenefits.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <img
                                      src={require("../../assets/images/green-check.png")}
                                      alt=""
                                    />
                                    {item}
                                  </li>
                                );
                              })
                            : advanceBenefits.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <img
                                      src={require("../../assets/images/green-check.png")}
                                      alt=""
                                    />
                                    {item}
                                  </li>
                                );
                              })}
                          {/* <li>
                            <img
                              src={require("../../assets/images/green-check.png")}
                              alt=""
                            />
                            Comprehensive check on lifestyle,
                            health, and age related issues.
                          </li> */}
                          {/* <li>
                            <img
                              src={require("../../assets/images/green-check.png")}
                              alt=""
                            />
                            Doctor + nutrition coaching
                          </li>
                          <li>
                            <img
                              src={require("../../assets/images/green-check.png")}
                              alt=""
                            />
                            Essential + xyz, abc
                          </li> */}
                        </ul>
                        <div className="priceWrapper">
                          <div className="percent">
                            <p>65% OFF</p>
                          </div>
                          <div className="price">
                            <p>
                              <s className="me-2 fs-18">
                                ₹ {item?.basePrice ? item?.basePrice : 9999}
                                /-
                              </s>
                              <span className="fw-6 text-red">
                                ₹ {item?.discountedAmount}/-
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="planCardFooter">
                        {pin ? (
                          <a
                            href={`/productplan/${item.productId}`}
                            onClick={() => {
                              LogEvent("Know-More", "know-more-btn");
                            }}
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            Know more
                          </a>
                        ) : (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPinStatus(true);
                              setProductCode(item.productId);
                              setButtonType("know-more");
                            }}
                          >
                            know more
                          </a>
                        )}
                        <button
                          className="red-btn"
                          onClick={() => onBuyNow(item)}
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* {products?.length > 0 && (
                <Carousel
                  responsive={responsive}
                  autoPlay={isMobile() ? true : false}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={props.deviceType}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  infinite={isMobile() ? true : false}
                  showDots={isMobile() ? true : false}
                  // renderDotsOutside={true}
                >
              <div>hhhhhhhh</div>
                </Carousel>
              )} */}

              {/* <div className="col-md-6">
                <div className="planCard">
                  <div className="planCardHeader">
                    <p>Healthyway essential</p>
                    <span>Best Seller</span>
                  </div>
                  <hr />
                  <div className="planCardBody">
                    <div className="iconBoxWrapper">
                      <div className="iconBox">
                        <img
                          src={require("../../assets/images/healthway-icon-1.png")}
                          alt=""
                        />
                        <p>66 Parameters</p>
                      </div>
                      <div className="iconBox">
                        <img
                          src={require("../../assets/images/healthway-icon-2.png")}
                          alt=""
                        />
                        <p>Nutritional consultant</p>
                      </div>
                    </div>
                    <ul className="listWrapper">
                      <li>
                        <img
                          src={require("../../assets/images/green-check.png")}
                          alt=""
                        />{" "}
                        Essential check for all to stay in good health
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/green-check.png")}
                          alt=""
                        />
                        Basic parameters : LFT,KFT
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/green-check.png")}
                          alt=""
                        />
                        Special parameters (Cardiac, Vitamin)
                      </li>
                    </ul>
                    <div className="priceWrapper">
                      <div className="percent">
                        <p>60% OFF</p>
                      </div>
                      <div className="price">
                        <p>
                          <s className="me-2">₹ 7249/-</s>
                          <span className="fw-6 text-red">₹ 2499/-</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="planCardFooter">
                    <a href="#0">Know more</a>
                    <button className="red-btn">Buy Now</button>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-6">
                <div className="planCard">
                  <div className="planCardHeader">
                    <p>Healthyway Advanced</p>
                   
                  </div>
                  <hr />
                  <div className="planCardBody">
                    <div className="iconBoxWrapper justify-content-between">
                      <div className="iconBox">
                        <img
                          src={require("../../assets/images/healthway-icon-1.png")}
                          alt=""
                        />
                        <p>100 Parameters</p>
                      </div>
                      <div className="iconBox">
                        <img
                          src={require("../../assets/images/healthway-icon-2.png")}
                          alt=""
                        />
                        <p>Nutritional consultant</p>
                      </div>
                      <div className="iconBox">
                        <img
                          src={require("../../assets/images/healthway-icon-3.png")}
                          alt=""
                        />
                        <p>Doctor consultant</p>
                      </div>
                    </div>
                    <ul className="listWrapper">
                      <li>
                        <img
                          src={require("../../assets/images/green-check.png")}
                          alt=""
                        />
                        Comprehensive check on lifestyle, health, and age
                        related issues.
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/green-check.png")}
                          alt=""
                        />
                        Doctor + nutrition coaching
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/green-check.png")}
                          alt=""
                        />
                        Essential + xyz, abc
                      </li>
                    </ul>
                    <div className="priceWrapper">
                      <div className="percent">
                        <p>60% OFF</p>
                      </div>
                      <div className="price">
                        <p>
                          <s className="me-2">₹ 3999/-</s>
                          <span className="fw-6 text-red">₹ 2999/-</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="planCardFooter">
                    <a href="#0">Know more</a>
                    <button className="red-btn">Buy Now</button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>

    //  <section className="productBannerSectionWrapper">
    //   <div className="container">
    //     <div className="productBannerSection mb-5">
    //       <div
    //         id="carouselExampleControls"
    //         className="carousel slide"
    //         data-bs-ride="carousel"
    //       >
    //         <div className="carousel-inner">
    //           <div className="carousel-item active">
    //             <div className="row">
    //               <div className="col-lg-5 col-xl-6 col-md-12">
    //                 <div style={{}} className="productBannerSectionLeft">
    //                   <img
    //                     src={require("../assets/images/planpurchaseimage.png")}
    //                     alt=""
    //                   />
    //                 </div>
    //               </div>
    //               <div className="col-lg-7 col-xl-6 col-md-12">
    //                 <div className="productBannerSectionRight">
    //                   <h2>{product?.productName} </h2>
    //                   <div className="col-12 ">
    //                     <del
    //                       style={{ marginRight: 5 }}
    //                       className="fs-22 fw-5"
    //                     >{`₹${
    //                       product?.basePrice ? product?.basePrice : 9999
    //                     } `}</del>
    //                     <span
    //                       style={{
    //                         color: "red",
    //                         fontSize: 22,
    //                         marginLeft: 5,
    //                       }}
    //                     >
    //                       65% off
    //                     </span>
    //                     {product?.discountedAmount && (
    //                       <p
    //                         className="product-price fw-8"
    //                         // style={{ fontSize: "40px" }}
    //                       >{`₹${product?.discountedAmount}`}</p>
    //                     )}
    //                   </div>
    //                   <div className="lists">
    //                     <ul className="ps-0">
    //                       <li className="d-flex align-items-center">
    //                         <img
    //                           className="me-3"
    //                           src={require("../assets/images/green-tick.png")}
    //                           alt=""
    //                         />
    //                         <span>
    //                           66 parameters comprehensive health check-up
    //                         </span>
    //                       </li>
    //                       <li className="d-flex align-items-center">
    //                         <img
    //                           className="me-3"
    //                           src={require("../assets/images/green-tick.png")}
    //                           alt=""
    //                         />
    //                         <span>
    //                           Unlimited doctor consults for 1 year{" "}
    //                         </span>
    //                       </li>
    //                       <li className="d-flex align-items-center">
    //                         <img
    //                           className="me-3"
    //                           src={require("../assets/images/green-tick.png")}
    //                           alt=""
    //                         />
    //                         <span>
    //                           Discounts on medicines and diagnostic tests
    //                         </span>
    //                       </li>
    //                       <li className="d-flex align-items-center">
    //                         <img
    //                           className="me-3"
    //                           src={require("../assets/images/green-tick.png")}
    //                           alt=""
    //                         />
    //                         <span>Lifestyle & health tracking</span>
    //                       </li>
    //                     </ul>
    //                   </div>
    //                   <div className="btnsWrapper mt-4">
    //                     <button onClick={knowMore} className="knowMoreBtn">
    //                       Know More
    //                     </button>
    //                     <button onClick={buyNow} className="buyNowBtn">
    //                       Buy Now
    //                     </button>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default PlanSection;
