import React from "react";
import { useNavigate } from "react-router-dom";
import { LogEvent } from "../LogEvent";

const Footer = ({ onDownloadClick, forwardeRef }) => {
  const navigate = useNavigate();
  return (
    // <footer id="footerSection">
    //   <div className="footerSectionWrapper">
    //     <div className="row m-0">
    //       <div className="col-md-5 d-block d-md-none">
    //         <div className="footerRightWrapper">
    //           <div className="top">
    //             <h3>
    //               Get the latest <br />{" "}
    //               <span className="fw-7">aiqahealth updates</span>
    //             </h3>
    //           </div>
    //           <div className="middle">
    //             <input
    //               type="email"
    //               placeholder="Enter Your Email"
    //               name=""
    //               id=""
    //             />
    //             <button>
    //               <img
    //                 src={require("../assets//images/arrow-circle.png")}
    //                 alt=""
    //               />
    //             </button>
    //           </div>
    //           <div className="bottom">
    //             <p>
    //               Keep me updated on Aiqahealth&apos;s products and insights to
    //               accelerate my growth
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-7 bg-red-color">
    //         <div className="footerLeftWrapper ">
    //           <div className="row">
    //             <div className="col-md-5">
    //               <div className="leftContent">
    //                 <img
    //                   className="logo"
    //                   src={require("../assets//images/logo.png")}
    //                   alt=""
    //                 />
    //                 <p>
    //                   Lorem Ipsum is simply dummy text of the printing and
    //                   typesetting
    //                 </p>
    //                 <a href="#0">
    //                   <img
    //                     src={require("../assets/images/linked-in-icon.png")}
    //                     alt=""
    //                   />
    //                 </a>
    //               </div>
    //             </div>
    //             <div className="col-md-7">
    //               <div className="rightContent">
    //                 <ul>
    //                   <li className="fw-7">Company</li>
    //                   <li>
    //                     <a
    //                       href="https://aiqahealth.com/AboutUs"
    //                       onClick={() =>
    //                         LogEvent("aboutus", "footer-aboutus-btn")
    //                       }
    //                       target="_blank"
    //                     >
    //                       About
    //                     </a>
    //                   </li>
    //                   <li>
    //                     <a
    //                       href="https://aiqahealth.com/blog"
    //                       target="_blank"
    //                       onClick={() => LogEvent("blog", "footer-blog-btn")}
    //                     >
    //                       Blog
    //                     </a>
    //                   </li>
    //                   <li>
    //                     <a
    //                       onClick={() => LogEvent("faq", "footer-faq-btn")}
    //                       href="/faq"
    //                     >
    //                       FAQs
    //                     </a>
    //                   </li>
    //                 </ul>
    //                 <ul>
    //                   <li className="fw-7">Legal</li>
    //                   <li>
    //                     <a
    //                       target="_blank"
    //                       href="https://aiqahealth.com/privacy-policy"
    //                       onClick={() =>
    //                         LogEvent(
    //                           "privacy-policy",
    //                           "footer-privacy-policy-btn"
    //                         )
    //                       }
    //                     >
    //                       Privacy Policy
    //                     </a>
    //                   </li>
    //                   <li>
    //                     <a
    //                       target="_blank"
    //                       href="https://aiqahealth.com/terms"
    //                       onClick={() => LogEvent("terms", "footer-terms-btn")}
    //                     >
    //                       Terms & Conditions
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="copyright">
    //           <p>Aiqahealth Private Limited</p>
    //         </div>
    //       </div>
    //       <div className="col-md-5 d-none d-md-block">
    //         <div className="footerRightWrapper">
    //           <div className="top">
    //             <h3>
    //               Get the latest <br />{" "}
    //               <span className="fw-7">aiqahealth updates</span>
    //             </h3>
    //           </div>
    //           <div className="middle">
    //             <input
    //               type="email"
    //               placeholder="Enter Your Email"
    //               name=""
    //               id=""
    //             />
    //             <button>
    //               <img
    //                 src={require("../assets//images/arrow-circle.png")}
    //                 alt=""
    //               />
    //             </button>
    //           </div>
    //           <div className="bottom">
    //             <p>
    //               Keep me updated on Aiqahealth&apos;s products and insights to
    //               accelerate my growth
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>
    <div
      ref={forwardeRef}
      style={{
        position: "relative",
        left: "0",
        bottom: "0",
        right: "0",
        marginTop: "40px",
      }}
    >
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
              <div className="footer-col-one text-start">
                <a
                  className="navbar-brand"
                  href="/"
                  onClick={() => localStorage.removeItem("buyNowSession")}
                >
                  <img
                    onClick={() => navigate("/")}
                    src={require("../assets/images/Aiqa_Health_LOGO2.png")}
                    style={{ cursor: "pointer", height: "65px" }}
                    alt=""
                  />
                </a>
                <ul className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                  <li>
                    <i className="fas fa-solid fa-phone text-white fa-lg fa-rotate-90" />
                    <span className="mx-2">+91 91055 56666</span>
                  </li>
                  <li>
                    <i className="fas fa-solid fa-envelope text-white fa-lg" />
                    <span className="mx-2">care@aiqahealth.com</span>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt text-white fa-lg" />
                    <span className="mx-2">
                      Hospido private limited, 5th floor, tower b, sas tower,
                      sector 38, gurugram, haryana 122001
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className="footer-col-two ps-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5">
                <h2>useful links</h2>
                <ul>
                  <li
                    onClick={() => {
                      navigate("/");
                      LogEvent("Home", "home-btn");
                    }}
                  >
                    <a href="">home</a>
                  </li>
                  {/* <li>
                    <a
                      target="_blank"
                      onClick={onDownloadClick}
                      style={{ color: "white", cursor: "pointer" }}
                    >
                      downloads
                    </a>
                  </li> */}
                  <li>
                    <a target="_blank" href="https://aiqahealth.com/AboutUs">
                      about us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className="footer-col-three ps-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5">
                <h2>important links</h2>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://aiqahealth.com/how-to-reach"
                    >
                      how to reach
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://aiqahealth.com/privacy-policy"
                    >
                      privacy policy
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://aiqahealth.com/terms">
                      terms and conditions
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://aiqahealth.com/customer-grievances-redressal-policy"
                    >
                      customer grievances policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-block d-md-none d-lg-none d-xl-none d-xxl-none">
              <div className="footer-col-one pe-5">
                {/* <img src={require('../assets/images/logo.png')} alt="" /> */}
                <ul>
                  <li>
                    <i className="fas fa-solid fa-phone text-white fa-lg fa-rotate-90" />
                    <span className="mx-2">+91 91055 56666</span>
                  </li>
                  <li>
                    <i className="fas fa-solid fa-envelope text-white fa-lg" />
                    <span className="mx-2">care@aiqahealth.com</span>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt text-white fa-lg" />
                    <span className="mx-2">
                      Hospido private limited, 5th floor, tower b, sas tower,
                      sector 38, gurugram, haryana 122001
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className="footer-col-four pe-0 pe-md-5 pe-lg-5 pe-xl-5 pe-xxl-5">
                <h2>follow us on</h2>
                <ul className="d-flex">
                  <li>
                    <a href="https://fb.watch/aAJb6ruaIo/" target="_blank">
                      <i className="fab fa-facebook text-white fa-lg" />
                    </a>
                  </li>
                  <li className="pe-0">
                    <a
                      href="https://www.linkedin.com/posts/doctoroncallindia_commoncold-cough-covidguidelines-activity-6888345117518913536-Js9h"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin text-white fa-lg" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="container-fluid" style={{ background: "#F9F9F9" }}>
        <div className=" text-center">
          <p
            style={{ lineHeight: "36px", padding: "14px 0" }}
            className="mb-0 fs-16 fw-5"
          >
            made with love in India
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
