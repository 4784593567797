import React from "react";
import { advanceBenefits, basicBenefits, essentialBenefits } from "../../utils";

const HealthyWay = ({ product, buyNow }) => {
  return (
    <section id="healthSection">
      <div className="healthSectionWrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="leftContent">
                <img
                  src={require("../../assets/images/healthway-page-bg.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="rightContent">
                <div className="healthCard">
                  <div className="healthCardHeader">
                    <p>{product?.productName}</p>
                  </div>
                  <hr />
                  <div className="healthCardBody">
                    <div className="iconBoxWrapper ">
                      <div className="iconBox">
                        {/* <img
                          src={require("../../assets/images/healthway-icon-1.png")}
                          alt=""
                        /> */}
                        <div className="parameterIcon">
                          {product?.productName === "Healthyway Essential"
                            ? 84
                            : product?.productName === "Healthyway Basic"
                            ? 69
                            : 94}
                        </div>
                        <p>
                          {product?.productName === "Healthyway Essential"
                            ? 84
                            : product?.productName === "Healthyway Basic"
                            ? 69
                            : 94}{" "}
                          Parameters
                        </p>
                      </div>
                      {product?.productName === "Healthyway Advanced" && (
                        <div className="iconBox">
                          <img
                            src={require("../../assets/images/healthway-icon-2.png")}
                            alt=""
                          />
                          <p>
                            Nutritional <br /> consultant
                          </p>
                        </div>
                      )}
                      <div className="iconBox">
                        <img
                          src={require("../../assets/images/healthway-icon-2.png")}
                          alt=""
                        />
                        <p>Doctor consultant</p>
                      </div>
                    </div>
                    <ul className="listWrapper">
                      {product?.productName === "Healthyway Basic"
                        ? basicBenefits.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="d-flex align-items-start"
                              >
                                <img
                                  className="me-3 mt-3"
                                  src={require("../../assets/images/green-tick.png")}
                                  alt=""
                                />
                                <span className=" fs-18 fs-sm-15">{item}</span>
                              </li>
                            );
                          })
                        : product?.productName === "Healthyway Essential"
                        ? essentialBenefits.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="d-flex align-items-start"
                              >
                                <img
                                  className="me-3 mt-3"
                                  src={require("../../assets/images/green-tick.png")}
                                  alt=""
                                />
                                <span className=" fs-18 fs-sm-15">{item}</span>
                              </li>
                            );
                          })
                        : advanceBenefits.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="d-flex align-items-start"
                              >
                                <img
                                  className="me-3 mt-3"
                                  src={require("../../assets/images/green-tick.png")}
                                  alt=""
                                />
                                <span className=" fs-18 fs-sm-15">{item}</span>
                              </li>
                            );
                          })}
                      {/* <li>
                        <img
                          src={require("../../assets/images/red-check.png")}
                          alt=""
                        />
                        <span>
                          {" "}
                          Comprehensive check on lifestyle, health, and age
                          related issues.
                        </span>
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/red-check.png")}
                          alt=""
                        />
                        <span> Doctor + nutrition coaching</span>
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/red-check.png")}
                          alt=""
                        />
                        <span> Essential + xyz, abc</span>
                      </li> */}
                    </ul>
                    <div className="btnWrapper">
                      <div className="priceWrapper">
                        <div className="percent">
                          <p>65% OFF</p>
                        </div>
                        <div className="price">
                          <p>
                            <s className="me-2">₹ {product?.basePrice}/-</s>{" "}
                            <br />
                            <span className="fw-6 text-red">
                              ₹ {product?.discountedAmount}/-
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="btnContent">
                        <button
                          onClick={() => buyNow(product)}
                          className="red-btn"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HealthyWay;
