import React from "react";
import { useNavigate } from "react-router-dom";
import { LogEvent } from "../../LogEvent";
const QuizSection = () => {
  const navigate = useNavigate();

  return (
    <section id="quizSection">
      <div className="quizSectionWrapper">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="leftContent">
                <img
                  src={require("../../assets/images/right-test-img.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rightContent">
                <h3>
                  Find the right{" "}
                  <span className="fw-7">
                    {" "}
                    test <br /> for you{" "}
                  </span>
                </h3>
                <p>
                  Answer some basic questions and let us get you the best health
                  test customized for you.
                </p>
                <button
                  onClick={() => {
                    LogEvent("Take Quiz", "take-quiz-btn");
                    navigate("/purchase");
                  }}
                  className="red-btn"
                >
                  Take quiz
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuizSection;
