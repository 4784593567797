import Topbar from "./components/Topbar";
import Header from "./components/Header";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Footer from "./components/Footer";
import Plan from "./components/Plan";
import Login from "./components/Login";
import Home from "./components/Home";
import Productplan from "./components/Productplan";
import PaymentFormCard from "./components/PaymentFormCard";
import "./assets/css/main.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentLogin from "./components/PaymentLogin";
import { useEffect, useRef, useState } from "react";
import FAQs from "./components/FAQs";
import { getProductList } from "./functions";
import SweetAlert from "react-bootstrap-sweetalert";
import PageNotFound from "./components/common/pageNotFound/PageNotFound";
import AfterBuy from "./pages/AfterBuy";
import Faq from "./pages/Faq";
import Buy from "./pages/Buy";
import ProductByPinCode from "./components/ProductByPinCode";
import { LogEvent } from "./LogEvent";
import pinImage from "./assets/images/pinCodeNotFound.png";
import LeadCaptureProductNotAvailable from "./pages/LeadCaptureProductNotAvailable";

const Wrapper = ({
  onDownloadClick,
  product,
  pinCode,
  onEditClick,
  onDeleteClick,
}) => {
  return (
    <>
      <Topbar />
      <Header
        onDownloadClick={onDownloadClick}
        product={product}
        pinCode={pinCode}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
      <Outlet />
      <Footer onDownloadClick={onDownloadClick} />
    </>
  );
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParam] = useSearchParams();
  const ref = useRef(null);
  const contactRef = useRef(null);
  const intervalRef = useRef(null);
  const targetRef = useRef();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [clearSessionTime, setClearSessionTime] = useState(false);
  const [redirectTimeout, setRedirectTimeOut] = useState(10);
  const [pinStatus, setPinStatus] = useState(false);
  const [pinCode, setPinCode] = useState(null);
  const [error, setError] = useState("");
  const [productCode, setProductCode] = useState(null);
  const [buttonType, setButtonType] = useState(null);
  const [leadForm, setLeadForm] = useState(false);

  const isLeadCaptured = localStorage.getItem("leadCaptured");

  useEffect(() => {
    if (searchParams.get("isLeader")) {
      localStorage.setItem("isLeader", searchParams.get("isLeader"));
    } else {
      localStorage.setItem("isLeader", false);
    }
    if (
      window.location.pathname === "/" &&
      localStorage.getItem("buyNowSession") == "true" &&
      localStorage.getItem("expireTime") &&
      Date.now() > localStorage.getItem("expireTime")
    ) {
      if (searchParams.get("expire_time") && searchParams.get("coupon_code")) {
        localStorage.setItem("expireTime", searchParams.get("expire_time"));
        localStorage.setItem("coupon_code", searchParams.get("coupon_code"));
        localStorage.removeItem("buyNowSession");
        localStorage.removeItem("leadCaptured");
      } else {
        redirectTimeOut();
      }
    } else if (
      searchParams.get("expire_time") &&
      searchParams.get("coupon_code")
    ) {
      localStorage.setItem("expireTime", searchParams.get("expire_time"));
      localStorage.setItem("coupon_code", searchParams.get("coupon_code"));
      localStorage.removeItem("buyNowSession");
      localStorage.removeItem("leadCaptured");
      setSearchParam([]);
    }
    myTimeOut();

    // lead cpature //
    if (window.location.pathname === "/" && !isLeadCaptured) {
      setTimeout(() => {
        if (window.location.pathname === "/" && !isLeadCaptured) {
          setLeadForm(
            window.location.pathname === "/" ? "get a call back" : false
          );
        }
      }, 120000);
    }
  }, []);

  const handleClearInterval = () => {
    const buyNowLocalStorageValue = localStorage.getItem("buyNowSession");
    if (!clearSessionTime && !buyNowLocalStorageValue) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      redirectTimeOut();
      localStorage.removeItem("expireTime");
      localStorage.removeItem("coupon_code");
      localStorage.removeItem("buyNowSession");
      localStorage.removeItem("leadCaptured");
    }
  };

  const redirectTimeOut = () => {
    setSessionTimeOut(true);
    let count = 10;
    setInterval(() => {
      setRedirectTimeOut(count);
      count--;
      if (count === 0) {
        window.location.replace(
          "https://www.amway.in/value-added-services/aiqa-health"
        );
      }
    }, 1000);
  };

  const myTimeOut = () => {
    const expTime = localStorage.getItem("expireTime");
    if (expTime != "null" && expTime != "") {
      intervalRef.current = setInterval(() => {
        if (Date.now() > expTime) {
          handleClearInterval();
        }
      }, 800);
    } else {
      redirectTimeOut();
    }
  };

  const handleDownload = () => {
    if (location.pathname === "/") {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else {
      navigate("/");
      setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 1000);
    }
  };

  const handleContectUs = () => {
    if (location.pathname === "/") {
      contactRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else {
      navigate("/#callBackSection");
    }
  };

  const getProducts = async () => {
    const response = await getProductList(pinCode);

    if (response.success) {
      let id = null;
      let selectedItem = null;
      let length = response?.products.length;

      let data = [];
      response?.products?.map((item) => {
        if (productCode && productCode == item.productId) {
          id = item.productId;
          selectedItem = item;
        }
        if (item.productName === "Healthyway Basic") {
          data[0] = item;
        } else if (item.productName === "Healthyway Essential") {
          data[1] = item;
        } else if (item.productName === "Healthyway Advanced") {
          data[2] = item;
        }
      });
      setProduct(data);
      if (length === 0) {
        navigate("/");
        setError("sorry no product available in your pincode area");
      } else if (!id && productCode) {
        setError("sorry this product not available in your pincode ");
      } else if (id && pinCode && buttonType == "know-more") {
        LogEvent(
          "Buy-Now-After-Fill-Pincode",
          "buy-now-btn-after-fill-pincode"
        );
        window.open(`/productplan/${productCode}`, "_self");
      } else if (id && pinCode) {
        LogEvent(
          "Buy-Now-After-Fill-Pincode",
          "buy-now-btn-after-fill-pincode"
        );

        navigate("/login", {
          state: {
            product: selectedItem,
          },
        });
      }
    } else if (location.pathname != "/faqs") {
      setLeadForm(
        `Our services are not available at this pincode. Our team will get in touch with you.`
      );
      setProduct(null);
      if (location.pathname != "/") {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        window.location.href = `/${window.location.hash}`;
      }, 1000);
    }
    if (localStorage.getItem("pincode") != null) {
      setPinCode(localStorage.getItem("pincode"));
    } else {
      if (location.pathname == "/") setPinStatus(true);
    }
    const getData = setTimeout(() => {
      getProducts();
    }, 500);
    return () => clearTimeout(getData);
  }, [pinCode]);

  const handleSessionTimeOutInBuyNow = () => {
    setClearSessionTime(true);
    localStorage.setItem("buyNowSession", true);
  };

  const scrollToTarget = () => {
    setTimeout(() => {
      targetRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }, 5500);
  };

  const handlePinEnter = (value) => {
    localStorage.setItem("pincode", value);
    setPinCode(value);
    setPinStatus(false);
  };

  const onEditPinHandler = () => {
    setPinStatus(true);
  };

  const onDeleteClick = () => {
    localStorage.removeItem("pincode");
    setPinCode(null);
    setProductCode(null);
    navigate("/");
  };

  const handleConfirm = () => {
    setLoading(false);
  };

  return (
    <div>
      <Routes>
        <Route
          element={
            <Wrapper
              onDownloadClick={handleDownload}
              product={product}
              pinCode={pinCode}
              onEditClick={onEditPinHandler}
              onDeleteClick={onDeleteClick}
            />
          }
        >
          <Route
            exact
            path="/"
            element={
              <Home
                forwardeRef={ref}
                forwardeContectRef={contactRef}
                product={product}
                handleClearInterval={handleSessionTimeOutInBuyNow}
                knowMoreScroll={scrollToTarget}
                pin={pinCode}
                setPinStatus={setPinStatus}
                setProductCode={setProductCode}
                setButtonType={setButtonType}
              />
            }
          />
          {/* <Route exact path="/plan" element={<Plan />} ></Route> */}
          <Route
            exact
            path="/login"
            element={<Login onContectClick={handleContectUs} />}
          />
          <Route
            exact
            path="/productplan/:id"
            element={
              <Productplan
                targetRef={targetRef}
                product={product}
                setLeadForm={setLeadForm}
              />
            }
          />
          {/* <Route exact path="/paymentformcard" element={<PaymentFormCard />} ></Route> */}
          <Route exact path="/payment-login" element={<PaymentLogin />} />
          <Route exact path="/faqs" element={<FAQs />} />
          <Route element={<Footer onDownloadClick={handleDownload} />} />
          <Route
            exact
            path="/after-buy"
            element={
              <AfterBuy
                product={product}
                handleClearInterval={handleSessionTimeOutInBuyNow}
                loading={loading}
                setLoading={setLoading}
                pin={pinCode}
                setPinStatus={setPinStatus}
                setProductCode={setProductCode}
              />
            }
          />
        </Route>
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/purchase" element={<Buy />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={true}
        limit={3}
      />

      {sessionTimeOut && (
        <SweetAlert
          info
          title="Your page got expired!"
          onConfirm={() => {}}
          customButtons={() => {}}
          btnSize="sm"
        >
          redirecting you to amway in {redirectTimeout} sec
          <p className="mt-3">
            <button
              onClick={() =>
                window.open(
                  "https://www.amway.in/value-added-services/aiqa-health",
                  "_self"
                )
              }
              style={{
                backgroundColor: "#dc332e",
                color: "white",
                borderRadius: 20,
                border: "none",
                padding: 10,
              }}
            >
              Redirect Now
            </button>
          </p>
        </SweetAlert>
      )}

      {pinStatus && !sessionTimeOut && (
        <ProductByPinCode
          onConfirm={handlePinEnter}
          onCancel={() => setPinStatus(false)}
        />
      )}

      {error && !pinStatus && pinCode && (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            position: "fixed",
            inset: "0px",
            zIndex: 5400,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "0.625em",
            overflow: "hidden auto",
          }}
        >
          <SweetAlert
            error
            onConfirm={() => {
              setError("");
            }}
            style={{ backgroundColor: "#FFDEDD" }}
            customButtons={
              <div className="text-center final-pay-btns">
                <a rel="nofollow noopener noreferrer">
                  <button
                    onClick={() => setError("")}
                    type="button"
                    className="red-btn"
                  >
                    Check available product
                  </button>
                </a>
              </div>
            }
          >
            <span>{error}</span>
            <br />
          </SweetAlert>
        </div>
      )}

      {loading && (
        <SweetAlert
          customButtons={
            <div className="text-center final-pay-btns">
              <a rel="nofollow noopener noreferrer">
                <button
                  onClick={handleConfirm}
                  type="button"
                  className="red-btn"
                >
                  Ok
                </button>
              </a>
            </div>
          }
        >
          <>
            <img
              src={pinImage}
              style={{
                marginBottom: "20px",
                height: "150px",
                width: "200px",
              }}
            />

            <br />
            <span>
              <small style={{ fontSize: "16px", fontWeight: "normal" }}>
                {loading}
              </small>
            </span>
          </>
        </SweetAlert>
      )}
      {leadForm && !isLeadCaptured && (
        <LeadCaptureProductNotAvailable
          title={leadForm}
          setLeadForm={setLeadForm}
        />
      )}

      {leadForm && isLeadCaptured && (
        <SweetAlert
          style={{
            backgroundColor: "#FFDEDD",
          }}
          customButtons={
            <div className="text-center final-pay-btns">
              <a rel="nofollow noopener noreferrer">
                <button
                  onClick={() => setLeadForm(false)}
                  type="button"
                  className="red-btn"
                >
                  Ok
                </button>
              </a>
            </div>
          }
        >
          <>
            <img
              src={pinImage}
              style={{
                marginBottom: "20px",
                height: "150px",
                width: "200px",
              }}
            />

            <br />
            <span>
              <small style={{ fontSize: "16px", fontWeight: "normal" }}>
                {leadForm}
              </small>
            </span>
          </>
        </SweetAlert>
      )}
    </div>
  );
}

export default App;
/*
toast.dismiss();
toast.success(json.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
toast.error(json.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
*/
