import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LogEvent } from "../LogEvent";
import CallBackSection from "../components/home/CallBackSection";
import FaqSection from "../components/home/FaqSection";
import HealthyWaySection from "../components/home/HealthyWaySection";
import HeroSection from "../components/home/HeroSection";
import NewsSection from "../components/home/NewsSection";
import PinCodeSection from "../components/home/PinCodeSection";
import PlanSection from "../components/home/PlanSection";
import QualitySection from "../components/home/QualitySection";
import QuizSection from "../components/home/QuizSection";
import TestimonialSection from "../components/home/TestimonialSection";
import WhyChooseSection from "../components/home/WhyChooseSection";
import WorkSection from "../components/home/WorkSection";
import { Route } from "react-router-dom";
import FAQs from "./FAQs";

const Home = ({
  forwardeRef,
  forwardeContectRef,
  product,
  handleClearInterval,
  knowMoreScroll,
  pin,
  setPinStatus,
  setProductCode,
  setButtonType,
}) => {
  const faqRef = useRef();
  const targetRef = useRef();
  const location = useLocation();
  const callBackRef = useRef();

  const navigate = useNavigate();

  const buyNow = (item) => {
    setProductCode(item.productId);
    if (!pin) {
      setPinStatus(true);
    } else {
      LogEvent("Buy-Now", "buy-now-btn");
      handleClearInterval();
      navigate("/login", { state: { product: item } });
    }
  };

  // const knowMore = (item) => {
  //   LogEvent("Know-More", "know-more-btn");
  //   navigate("/productplan");
  //   knowMoreScroll();
  // };

  useEffect(() => {
    if (location.hash) {
      scrollToTarget();
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  const scrollToTarget = () => {
    setTimeout(() => {
      targetRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, 500);
  };

  const onCallBackHandle = () => {
    callBackRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  return (
    <>
      <div ref={faqRef} className="homePage">
        <HeroSection onCallBackHandle={onCallBackHandle} />

        {/* <!-- PLAN SECTION STARTS --> */}
        {product?.length ? (
          <PlanSection
            onBuyNow={buyNow}
            // onKnowMore={knowMore}
            products={product}
            pin={pin}
            setPinStatus={setPinStatus}
            setProductCode={setProductCode}
            setButtonType={setButtonType}
          />
        ) : (
          <section
            id="planSection"
            style={{ marginTop: "100px", marginBottom: "100px" }}
          >
            <div className="planHeader">
              <h2>
                Our services are not available at this pincode
                {/* <span className="fw-7">with our curated plans</span>{" "} */}
              </h2>
              <p>
                Our team is working hard to bring services to your location.
                <br />
                you can reach out to us on +91 91055 56666.
                {/* call 📞 on
                aiqahealth care number */}
                <br />
                <span
                  style={{
                    color: "#db2228",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                  onClick={() => setPinStatus(true)}
                >
                  change your pincode
                </span>
              </p>
            </div>
          </section>
        )}

        {/* <!-- PINCODE SECTION STARTS --> */}
        {/* <PinCodeSection /> */}

        {/* <!-- QUIZ SECTION STARTS --> */}
        <QuizSection />
        <br />
        <br />
        <br />
        <br />
        {/* <!-- QUALITY SECTION STARTS --> */}
        <QualitySection />

        {/* <!-- WORK SECTION STARTS --> */}
        <WorkSection />

        {/* <!-- HEALTHWAY SECTION STARTS --> */}
        <HealthyWaySection />

        {/* <!-- WHYCHOOSE SECTION STARTS --> */}
        <WhyChooseSection />

        {/* <!-- NEWS SECTION STARTS --> */}
        <NewsSection />

        {/* <!-- TESTIMONIAL SECTION STARTS --> */}
        <TestimonialSection />

        {/* <!-- FAQ SECTION STARTS --> */}
        {/* <FaqSection /> */}
        {/* <FAQs dataLength={4} /> */}
        <section className="text-center faqs" style={{ marginBottom: "30px" }}>
          <FAQs dataLength={5} />
          <a className="btn-btn" href="/faqs">{`view more`}</a>
        </section>

        {/* <!-- CALLBACK SECTION STARTS --> */}
        <CallBackSection forwardeCallBackRef={callBackRef} />

        {/* <section
          ref={targetRef}
          id="pinCodeChecker"
          className="pincodeAvailibility mb-5"
        >
          <div className="container">
            <div
              className="availibilityContainer d-flex flex-wrap justify-content-center"
              style={{ padding: 20 }}
            >
              <h4 className="text-center pt-3" style={{ marginRight: 30 }}>
                <i className="fa fa-map-marker-alt" aria-hidden="true" />{" "}
                Check Availability at your pincode
              </h4>
              <div className="pincodeWrapper text-center pt-2 d-flex">
                <div>
                  <InputMask
                    mask="999999"
                    maskChar=""
                    placeholder="Enter pin code"
                    onChange={pinOnChange}
                  />
                  {error && (
                    <p style={{ fontSize: 12, color: "red" }}>{error}</p>
                  )}
                </div>
                <span>
                  <a
                    onClick={checkPinAvailability}
                    className="fw-5 fs-14 text-red"
                  >
                    <button>Check</button>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="doctorConsultSection">
          <div className="container">
            <h1 className="mb-4">Why choose healthyway </h1>
            <div className="cardsParent">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="cardsWrapper">
                    <img
                      src={require("../assets/images/specialist-icon.png")}
                      alt=""
                    />
                    <h4>Fast and accurate tests reports </h4>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="cardsWrapper">
                    <img
                      src={require("../assets/images/sample-icon.png")}
                      alt=""
                    />
                    <h4>Home sample pickup</h4>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="cardsWrapper">
                    <img
                      src={require("../assets/images/video-consultant-icon.png")}
                      alt=""
                    />
                    <h4>Consult with doctor after test</h4>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="cardsWrapper">
                    <img
                      src={require("../assets/images/twenty-four-icon.png")}
                      alt=""
                    />
                    <h4>24 x 7 availability of doctors</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="workStepsSection">
          <div className="container">
            <div className="workStepsWrapper">
              <h1 className="mb-4">How healthyway works?</h1>
              <div className="cardsParent">
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cardsWrapper">
                      <div className="works1">
                        <img
                          src={require("../assets/images/works1.png")}
                          alt=""
                        />
                      </div>
                      <h4>Book lab test </h4>
                      <p>
                        Book your preferred date and time using the test
                        voucher{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cardsWrapper">
                      <div className="works2">
                        <img
                          src={require("../assets/images/works2.png")}
                          alt=""
                        />
                      </div>
                      <h4>Give sample at home</h4>
                      <p>
                        Lab partner agent will come and collect your sample
                        from home{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cardsWrapper">
                      <div className="works3">
                        <img
                          src={require("../assets/images/works3.png")}
                          alt=""
                        />
                      </div>
                      <h4>Check reports on app </h4>
                      <p>Get your reports on the aiqahealth app </p>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="cardsWrapper">
                      <div className="works4">
                        <img
                          src={require("../assets/images/works4.png")}
                          alt=""
                        />
                      </div>
                      <h4>Consult with doctor (free)</h4>
                      <p>
                        Speak to aiqahealth doctor post the test and anytime
                        later
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="highQualitySection text-center">
          <div className="container">
            <h1 className="mb-5">Delivering highest quality care</h1>
            <div className="cardsParent mt-3">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="cardWrapper p-3 text-center">
                    <h4>4 Lac</h4>
                    <p>Households covered</p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="cardWrapper p-3 text-center">
                    <h4>2.5 Cr</h4>
                    <p>Doctor consultations</p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="cardWrapper p-3 text-center">
                    <h4>500+</h4>
                    <p>Doctors on platform </p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="cardWrapper p-3 text-center">
                    <h4>4.7/5</h4>
                    <p>Customer rating</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="customerBannerSection">
          <div className="container">
            <div className=" col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 col-xxl-6">
              <div className="leftContent">
                <div className="iconWrapper">
                  <span className="pe-2">
                    <img
                      className="w-25 h-75"
                      src={require("../assets/images/Aiqa_Health_LOGO2.png")}
                      alt=""
                    />
                  </span>
                </div>
                <h3>
                  aiqahealth brings amazing health benefits to its customers
                </h3>
                <p>
                  aiqahealth has made preventive care more personalized
                  through the application of diagnostic tests and digital
                  healthcare ecosystem. We aim to make healthcare
                  understandable, accessible and affordable to all customers.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 col-xxl-6" />
          </div>
        </section>
        <section className="reviewSection col-12 d-block d-md-none d-lg-none d-xl-none d-xxl-none">
          <div className="container">
            <h1 className="mb-4">aiqahealth in news </h1>

            <div
              id="carouselExampleInterval"
              className="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="3000"
              data-bs-pause="false"
            >
              <div
                className="carousel-inner"
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                <div className="carousel-item active">
                  <div className="d-flex justify-content-between">
                    <div>
                      <img
                        src={require("../assets/images/news1.png")}
                        style={{ width: 150 }}
                        alt=""
                      />
                    </div>

                    <div>
                      <img
                        src={require("../assets/images/news2.png")}
                        style={{ width: 150 }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="carousel-item ">
                  <div className="d-flex justify-content-between">
                    <div>
                      <img
                        src={require("../assets/images/news3.png")}
                        style={{ width: 150 }}
                        alt=""
                      />
                    </div>

                    <div>
                      <img
                        src={require("../assets/images/news4.png")}
                        style={{ width: 150 }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="partnerSection text-center d-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="container">
            <h1 className="mb-4">aiqahealth in news </h1>
            <div className="cardsParent mt-3 ">
              <div className="d-flex flex-row flex-nowrap overflow-auto">
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="p-3">
                    <img src={require("../assets/images/news1.png")} alt="" />
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="p-3">
                    <img src={require("../assets/images/news2.png")} alt="" />
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="p-3">
                    <img src={require("../assets/images/news3.png")} alt="" />
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="p-3">
                    <img src={require("../assets/images/news4.png")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section ref={forwardeRef} className="downloadSection">
          <div className="container">
            <div className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
              <div className="row ">
                <div className="col-md-5">
                  <div className="leftContent">
                    <img
                      src={require("../assets/images/mobile-in-hand.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="rightContent text-white">
                    <h3 className="">Download our app</h3>
                    <p className="">
                      get the aiqahealth app for your mobile device
                    </p>
                    <div className="iconWrapper d-flex align-content-center">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.aiqahealth"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="me-4"
                          src={require("../assets/images/google-play.png")}
                          alt=""
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/in/app/aiqahealth/id1588240478"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={require("../assets/images/app-store.png")}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row d-block d-md-none d-lg-none d-xl-none d-xxl-none">
              <div className="col-12 ">
                <div className="rightContent text-white">
                  <h3 className="">Download our app</h3>
                  <p className="">
                    get the aiqahealth app for your mobile device
                  </p>
                  <div className="iconWrapper d-flex align-content-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.aiqahealth"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="me-4"
                        src={require("../assets/images/google-play.png")}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/aiqahealth/id1588240478"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={require("../assets/images/app-store.png")}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="leftContent">
                  <img
                    src={require("../assets/images/mobile-in-hand.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="essentialProSection">
          <div>
            <img
              className="w-100"
              src={require("../assets/images/healthway-essential-pro.png")}
            />
          </div>
        </section>

        <section className="reviewSection">
          <div className="container">
            <h1>
              What our customers <br /> are saying
            </h1>
            <div className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
              <div
                id="carouselExampleInterval"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="3000"
                data-bs-pause="false"
              >
                <div className="carousel-indicators ">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  />
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="reviewTestimonial">
                          <ul className="ps-0">
                            <li>
                              <img
                                src={require("../assets/images/testimonial1.png")}
                                alt=""
                              />
                              <div className="reviewTestimonialDetail">
                                <h1>Saurabh Singh</h1>
                                <p>
                                  I liked the overall service. They also
                                  provide detailed doctor consultation report
                                  post the reports.
                                </p>
                                <div className="text-red fs-26">
                                  &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="reviewTestimonial">
                          <ul className="ps-0">
                            <li>
                              <img
                                src={require("../assets/images/testimonial2.png")}
                                alt=""
                              />
                              <div className="reviewTestimonialDetail">
                                <h1>Yash Gupta</h1>
                                <p>
                                  At first i thought that how good tele
                                  consultation services can be, but then i
                                  tried it at aiqahealth and i can’t believe
                                  that it was so good. I didn’t have to wait
                                  or stand in queues neither do i have to
                                  travel to get to a doctor. I was there at
                                  the comfort of my house.
                                </p>
                                <div className="text-red fs-26">
                                  &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="reviewTestimonial">
                          <ul className="ps-0">
                            <li>
                              <img
                                src={require("../assets/images/Group381.png")}
                                alt=""
                              />
                              <div className="reviewTestimonialDetail">
                                <h1>Soumen Das</h1>
                                <p>
                                  In those days when I was suffering from
                                  cold, tele consultation was the only option
                                  I had, I took tele consultation of aiqa
                                  health. Doctor response was good and behaved
                                  like a family member. Prescribed some
                                  medicine, got relief in two days.
                                </p>
                                <div className="text-red fs-26">
                                  &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="reviewTestimonial">
                          <ul className="ps-0">
                            <li>
                              <img
                                src={require("../assets/images/Group290.png")}
                                alt=""
                              />
                              <div className="reviewTestimonialDetail">
                                <h1>Bhupender Singh</h1>
                                <p>
                                  Very good experience. In current Covid
                                  situation we need a solution of all health
                                  issue of my family without going outside of
                                  home and aiqahealth gives us all health
                                  related issues solution in a single call.
                                </p>
                                <div className="text-red fs-26">
                                  &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="reviewTestimonial">
                          <ul className="ps-0">
                            <li>
                              <img
                                src={require("../assets/images/Group.png")}
                                alt=""
                              />
                              <div className="reviewTestimonialDetail">
                                <h1>Riya</h1>
                                <p>
                                  User friendly, quick, safe and very helpful.
                                  The doctors are great and very well behaved.
                                  It's my on the go doctor's clinic.
                                </p>
                                <div className="text-red fs-26">
                                  &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="reviewTestimonial">
                          <ul className="ps-0">
                            <li>
                              <img
                                src={require("../assets/images/Group-382.png")}
                                alt=""
                              />
                              <div className="reviewTestimonialDetail">
                                <h1>Sandeep Singh</h1>
                                <p>
                                  The home test service of aiqahealth is nice,
                                  and the collection agent were came on time
                                  to collect the samples , and I must say I
                                  had an amazing experience. I got my report
                                  very fast on the same day. Thank you so much
                                  for a wonderful experience.
                                </p>
                                <div className="text-red fs-26">
                                  &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>

            <div className="row mobile-carousel d-block d-md-none d-lg-none d-xl-none d-xxl-none">
              <div
                id="carouselExampleDark"
                className="carousel carousel-dark slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="4"
                    aria-label="Slide 5"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="5"
                    aria-label="Slide 6"
                  />
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="col-lg-6">
                      <div className="reviewTestimonial">
                        <ul className="ps-0">
                          <li>
                            <img
                              src={require("../assets/images/testimonial1.png")}
                              alt=""
                            />
                            <div className="reviewTestimonialDetail">
                              <h1>Saurabh Singh</h1>
                              <p>
                                I liked the overall service. They also provide
                                detailed doctor consultation report post the
                                reports.
                              </p>
                              <div className="text-red fs-26">
                                &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-lg-6">
                      <div className="reviewTestimonial">
                        <ul className="ps-0">
                          <li>
                            <img
                              src={require("../assets/images/testimonial2.png")}
                              alt=""
                            />
                            <div className="reviewTestimonialDetail">
                              <h1>Yash Gupta</h1>
                              <p>
                                At first i thought that how good tele
                                consultation services can be, but then i tried
                                it at aiqahealth and i can’t believe that it
                                was so good. I didn’t have to wait or stand in
                                queues neither do i have to travel to get to a
                                doctor. I was there at the comfort of my
                                house.
                              </p>
                              <div className="text-red fs-26">
                                &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-lg-6">
                      <div className="reviewTestimonial">
                        <ul className="ps-0">
                          <li>
                            <img
                              src={require("../assets/images/Group381.png")}
                              alt=""
                            />
                            <div className="reviewTestimonialDetail">
                              <h1>Soumen Das</h1>
                              <p>
                                In those days when I was suffering from cold,
                                tele consultation was the only option I had, I
                                took tele consultation of aiqa health. Doctor
                                response was good and behaved like a family
                                member. Prescribed some medicine, got relief
                                in two days.
                              </p>
                              <div className="text-red fs-26">
                                &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-lg-6">
                      <div className="reviewTestimonial">
                        <ul className="ps-0">
                          <li>
                            <img
                              src={require("../assets/images/Group290.png")}
                              alt=""
                            />
                            <div className="reviewTestimonialDetail">
                              <h1>Bhupender Singh</h1>
                              <p>
                                Very good experience. In current Covid
                                situation we need a solution of all health
                                issue of my family without going outside of
                                home and aiqahealth gives us all health
                                related issues solution in a single call.
                              </p>
                              <div className="text-red fs-26">
                                &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-lg-6">
                      <div className="reviewTestimonial">
                        <ul className="ps-0">
                          <li>
                            <img
                              src={require("../assets/images/Group.png")}
                              alt=""
                            />
                            <div className="reviewTestimonialDetail">
                              <h1>Riya</h1>
                              <p>
                                User friendly, quick, safe and very helpful.
                                The doctors are great and very well behaved.
                                It's my on the go Doctor's Clinic.
                              </p>
                              <div className="text-red fs-26">
                                &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="col-lg-6">
                      <div className="reviewTestimonial">
                        <ul className="ps-0">
                          <li>
                            <img
                              src={require("../assets/images/Group-382.png")}
                              alt=""
                            />
                            <div className="reviewTestimonialDetail">
                              <h1>Sandeep Singh</h1>
                              <p>
                                The home test service of aiqahealth is nice,
                                and the collection agent were came on time to
                                collect the samples , and I must say I had an
                                amazing experience. I got my report very fast
                                on the same day. Thank you so much for a
                                wonderful experience.
                              </p>
                              <div className="text-red fs-26">
                                &#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;&nbsp;&#9733;
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-center faqs mb-14">
          <FAQs dataLength={5} />
          <a className="btn-btn" href="/faqs">{`view more`}</a>
        </section>

        <section ref={forwardeContectRef} className="callBackSection">
          <div className="container">
            <h1 className="mt-5">Get a call back</h1>
            <div className="callBackRight ">
              <form
                className="row g-3 justify-content-md-center "
                id="callBackForm"
              >
                <div className="col-md-4 ">
                  <label htmlFor="inputName" className="form-label">
                    Name <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={onUserChnage}
                    name="name"
                    value={user.name}
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div className="col-md-4 ">
                  <label htmlFor="pinCode" className="form-label">
                    Area pin code
                  </label>
                  <input
                    onChange={onUserChnage}
                    name="pincode"
                    value={user.pincode}
                    type="text"
                    className="form-control"
                    id="pinCode"
                    placeholder="Enter your pincode"
                  />
                </div>
                <div className="col-12" />
                <div className="col-md-4 ">
                  <label htmlFor="inputEmail" className="form-label">
                    Email <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={onUserChnage}
                    name="email"
                    value={user.email}
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    placeholder="name@example.com"
                    required
                  />
                </div>
                <div className="col-md-4 ">
                  <label htmlFor="mobile" className="form-label">
                    Mobile number <span className="text-red">*</span>
                  </label>
                  <input
                    onChange={onUserChnage}
                    name="mobileNumber"
                    value={user.mobileNumber}
                    type="text"
                    className="form-control"
                    id="mobile"
                    placeholder="Enter your mobile number"
                    required
                  />
                </div>
                <div className="col-12" />
                <div className="col-2">
                  <button
                    disabled={
                      user.name.length === 0 ||
                      invalidEmail(user.email) ||
                      user.mobileNumber.length < 10
                        ? true
                        : ""
                    }
                    onClick={handleSubmit}
                    type="button"
                    className="btn red-btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
};

export default Home;
